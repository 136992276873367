import React, { useState } from 'react';
import styled from 'styled-components';
import {
  InputLabel, Label, Modal, Skeleton, shadow10, P, SkeletonBox,
} from '../atoms';
import { TwitchEmote } from '../../common/Actions';
import { BoxPointer } from '../icons';

// STYLED ------------------------------------------------------------

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.gray[900]};
  width: 100%;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  overflow-y: auto;
`;

const ScrollView = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(44px, 1fr)); /* Adjust width as needed */
  display: grid;
  gap: 4px;
  padding: 4px;
`;

const SectionTitle = styled(Label)`
  height: 48px;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => `${theme.gray[800]}50`};
`;

const Cell = styled.button<{ selected?: boolean, disabled?: boolean }>`
  border-radius: 12px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${({ theme, selected }) => (selected ? theme.gray[700] : 'transparent')};
  transition: all 150ms;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray[700]};
  }
`;

const SkeletonCell = styled.div`
  border-radius: 16px;
  height: 44px;
  width: 44px;
  background-color: ${({ theme }) => theme.gray[800]};
  ${Skeleton}
`;

const Shadow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${({ theme }) => theme.gray[900]} 50%);
`;

const EmoteImg = styled.img<{ focused?: boolean }>`
  width: 24px;
  height: 24px;
  transition: all 150ms;
  transform: scale(${({ focused }) => (focused ? 1.30 : 1)});
`;

const EmoteName = styled(InputLabel)`
  background-color: ${({ theme }) => theme.ui.default[500]};
  color: ${({ theme }) => theme.gray[100]};
  padding: 4px;
  border-radius: 4px;
  position: absolute;
  margin-bottom: 76px;
  overflow: visible;
  ${shadow10}
  pointer-events: none;
`;

const BoxPointerTop = styled(BoxPointer)`
  color: ${({ theme }) => theme.ui.default[500]};
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  z-index: 1000;
`;

// TYPE ------------------------------------------------------------

type Props = {
  modalTitle?: string;
  onClose?: () => void;
  onSelect?: (emote: { id: string, name: string, isAnimated: boolean }) => void;
  visible?: boolean;
  selectedEmotes?: { id: string, name: string, isAnimated: boolean }[];
  list: TwitchEmote[][];
  loading?: boolean;
  error?: string;
};

type CellProps = {
  emote: TwitchEmote;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
};

// COMPONENTS ------------------------------------------------------------

function Emote({
  emote,
  onClick,
  selected,
  disabled,
}: CellProps) {
  const [showLabel, setShowLabel] = useState(false);
  let timeout: NodeJS.Timeout;

  const handleMouseEnter = () => {
    timeout = setTimeout(() => {
      setShowLabel(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeout);
    setShowLabel(false);
  };

  return (
    <Cell
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      selected={selected}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <EmoteImg
        src={`https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/${emote.isAnimated ? 'animated' : 'static'}/light/1.0`}
        alt={emote.name}
        focused={showLabel}
      />
      {showLabel && (
      <EmoteName>
        <BoxPointerTop width={32} />
        {emote.name}
      </EmoteName>
      )}
    </Cell>
  );
}

function EmotePicker({
  modalTitle = 'Choose an emote',
  onClose,
  onSelect,
  visible,
  list,
  selectedEmotes,
  loading = false,
  error,
}: Props) {
  return (
    <Modal.Container onClose={onClose} visible={visible}>
      <Modal.Header title={modalTitle} onClose={onClose} />
      <Content>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading || list.length <= 0 ? (
          <Container style={{ position: 'relative' }}>
            <SectionTitle><SkeletonBox style={{ height: '100%', width: '200px' }} /></SectionTitle>
            <ScrollView style={{ height: 'auto' }}>
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
              <SkeletonCell />
            </ScrollView>
            <Shadow />
          </Container>
        ) : !error ? (
          <Container>
            {list[0].length > 0 && (
              <>
                <SectionTitle>Channel emotes</SectionTitle>
                <ScrollView>
                  {list[0].map((emote) => (
                    <Emote
                      key={emote.id}
                      emote={emote}
                      onClick={() => onSelect?.(emote)}
                      selected={selectedEmotes?.map((e) => e.id).includes(emote.id)}
                      disabled={selectedEmotes?.map((e) => e.id).includes(emote.id)}
                    />
                  ))}
                </ScrollView>
              </>
            )}
            <SectionTitle>Global emotes</SectionTitle>
            <ScrollView>
              {list[1].map((emote) => (
                <Emote
                  key={emote.id}
                  emote={emote}
                  onClick={() => onSelect?.(emote)}
                  selected={selectedEmotes?.map((e) => e.id).includes(emote.id)}
                  disabled={selectedEmotes?.map((e) => e.id).includes(emote.id)}
                />
              ))}
            </ScrollView>
          </Container>
        ) : <P>{error}</P>}
      </Content>
    </Modal.Container>
  );
}

export default EmotePicker;
