/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  color: white;
`;

const scroll = (h : number, y : number) => keyframes`
  0% {
    transform: translateY(${y - h}px)
  }
  10.5% {
    transform: translateY(${y - h * 2}px)
  }
  25% {
    transform: translateY(${y - h * 2}px)
  }
  33.5% {
    transform: translateY(${y - h * 3}px)
  }
  50% {
    transform: translateY(${y - h * 3}px)
  }
  60.5% {
    transform: translateY(${y - h * 4}px)
  }
  75% {
    transform: translateY(${y - h * 4}px)
  }
  83.5% {
    transform: translateY(${y - h * 5}px)
  }
  100% {
    transform: translateY(${y - h * 5}px)
  }
`;

const LogoMoving = styled.svg<{ h : number, y : number }>`
  width: 100%;
  animation-name: ${({ h, y }) => scroll(h, y)};
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
`;

const FloatingContent = styled.svg<{ h : number, y : number }>`
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: difference;
  top: ${({ y, h }) => y + h * 0.065}px;
`;

// COMPONENTS ------------------------------------------------------------

function LogoSlider({ windowSize } : { windowSize : { width: number, height: number } }) {
  const refContainer = useRef<HTMLDivElement>(null);
  const refLogo = useRef<SVGSVGElement>(null);
  const [height, setHeight] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    if (windowSize.width > 0) {
      const logoWidth = refLogo.current ? refLogo.current.getBoundingClientRect().width : 0;
      const offset = (logoWidth * 2) / 30;
      const h = (logoWidth * 35) / 30;
      const vh = refContainer.current ? refContainer.current.getBoundingClientRect().height : 0;
      setHeight(h);
      setY((vh - h + offset) / 2);
    }
  }, [windowSize.width, windowSize.height]);

  return (
    <Container ref={refContainer}>
      <LogoMoving ref={refLogo} h={height} y={y} viewBox="0 0 30 278" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1C0 0.447715 0.447715 0 1 0H29C29.5523 0 30 0.447715 30 1V11C30 11.5523 29.5523 12 29 12H22.5C21.9477 12 21.5 12.4477 21.5 13V32C21.5 32.5523 21.0523 33 20.5 33H9.5C8.94772 33 8.5 32.5523 8.5 32V13C8.5 12.4477 8.05228 12 7.5 12H1C0.447715 12 0 11.5523 0 11V1Z" fill="currentcolor" />
        <path d="M23.3003 67.6139C23.1108 67.8575 22.8195 68 22.5109 68H7.48908C7.18049 68 6.88919 67.8575 6.69973 67.6139L0.210648 59.2708C0.0741222 59.0953 0 58.8793 0 58.6569V36C0 35.4477 0.447715 35 1 35H12C12.5523 35 13 35.4477 13 36V58C13 58.5523 13.4477 59 14 59H16C16.5523 59 17 58.5523 17 58V48C17 47.4477 16.5523 47 16 47V47C15.4477 47 15 46.5523 15 46V36C15 35.4477 15.4477 35 16 35H29C29.5523 35 30 35.4477 30 36V58.6569C30 58.8793 29.9259 59.0953 29.7894 59.2708L23.3003 67.6139Z" fill="currentcolor" />
        <path d="M0 71C0 70.4477 0.447715 70 1 70H29C29.5523 70 30 70.4477 30 71V81C30 81.5523 29.5523 82 29 82H22.5C21.9477 82 21.5 82.4477 21.5 83V90C21.5 90.5523 21.9477 91 22.5 91H29C29.5523 91 30 91.4477 30 92V102C30 102.552 29.5523 103 29 103H1C0.447716 103 0 102.552 0 102V92C0 91.4477 0.447715 91 1 91H7.5C8.05228 91 8.5 90.5523 8.5 90V83C8.5 82.4477 8.05228 82 7.5 82H1C0.447715 82 0 81.5523 0 81V71Z" fill="currentcolor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 114C0 109.029 4.02944 105 9 105H21C25.9706 105 30 109.029 30 114V129C30 133.971 25.9706 138 21 138H9C4.02944 138 0 133.971 0 129V114ZM16 115C16 114.448 15.5523 114 15 114V114C14.4477 114 14 114.448 14 115V128C14 128.552 14.4477 129 15 129V129C15.5523 129 16 128.552 16 128V115Z" fill="currentcolor" />
        <path d="M0 141C0 140.448 0.447715 140 1 140H29C29.5523 140 30 140.448 30 141V151C30 151.552 29.5523 152 29 152H22.5C21.9477 152 21.5 152.448 21.5 153V172C21.5 172.552 21.0523 173 20.5 173H9.5C8.94772 173 8.5 172.552 8.5 172V153C8.5 152.448 8.05228 152 7.5 152H1C0.447715 152 0 151.552 0 151V141Z" fill="currentcolor" />
        <path d="M23.3003 207.614C23.1108 207.858 22.8195 208 22.5109 208H7.48908C7.18049 208 6.88919 207.858 6.69973 207.614L0.210648 199.271C0.0741222 199.095 0 198.879 0 198.657V176C0 175.448 0.447715 175 1 175H12C12.5523 175 13 175.448 13 176V198C13 198.552 13.4477 199 14 199H16C16.5523 199 17 198.552 17 198V188C17 187.448 16.5523 187 16 187V187C15.4477 187 15 186.552 15 186V176C15 175.448 15.4477 175 16 175H29C29.5523 175 30 175.448 30 176V198.657C30 198.879 29.9259 199.095 29.7894 199.271L23.3003 207.614Z" fill="currentcolor" />
        <path d="M0 211C0 210.448 0.447715 210 1 210H29C29.5523 210 30 210.448 30 211V221C30 221.552 29.5523 222 29 222H22.5C21.9477 222 21.5 222.448 21.5 223V230C21.5 230.552 21.9477 231 22.5 231H29C29.5523 231 30 231.448 30 232V242C30 242.552 29.5523 243 29 243H1C0.447716 243 0 242.552 0 242V232C0 231.448 0.447715 231 1 231H7.5C8.05228 231 8.5 230.552 8.5 230V223C8.5 222.448 8.05228 222 7.5 222H1C0.447715 222 0 221.552 0 221V211Z" fill="currentcolor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 254C0 249.029 4.02944 245 9 245H21C25.9706 245 30 249.029 30 254V269C30 273.971 25.9706 278 21 278H9C4.02944 278 0 273.971 0 269V254ZM16 255C16 254.448 15.5523 254 15 254V254C14.4477 254 14 254.448 14 255V268C14 268.552 14.4477 269 15 269V269C15.5523 269 16 268.552 16 268V255Z" fill="currentcolor" />
      </LogoMoving>
      <FloatingContent h={height} y={y} viewBox="0 0 720 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M412.345 25.7391L421.266 1.26069H423.873L432.76 25.7391H430.543L427.919 18.4599H417.237L414.545 25.7391H412.345ZM417.846 16.3777H427.259L422.586 3.41059L417.846 16.3777Z" fill="currentcolor" />
        <path d="M437.929 1.26069C437.974 1.26069 438.149 1.26633 438.454 1.27762C438.759 1.27762 439.142 1.27762 439.605 1.27762C440.079 1.27762 440.587 1.28326 441.129 1.29454C441.67 1.29454 442.195 1.29454 442.703 1.29454C445.716 1.31711 448.16 1.77982 450.033 2.68267C451.906 3.57423 453.278 4.90593 454.147 6.67776C455.016 8.4496 455.45 10.6672 455.45 13.3306C455.45 16.1294 454.976 18.4486 454.028 20.2882C453.08 22.1164 451.636 23.482 449.694 24.3848C447.753 25.2877 445.287 25.7391 442.297 25.7391H437.929V1.26069ZM440.164 3.22438V23.7754H442.246C444.616 23.7754 446.613 23.4425 448.239 22.7766C449.875 22.1108 451.111 21.0217 451.946 19.5095C452.792 17.9859 453.216 15.9602 453.216 13.4322C453.216 10.8139 452.792 8.7656 451.946 7.28719C451.099 5.80877 449.869 4.76486 448.256 4.15544C446.653 3.53473 444.706 3.22438 442.415 3.22438H440.164Z" fill="currentcolor" />
        <path d="M477.801 1.26069L468.998 25.7391H466.256L457.639 1.26069H459.806L467.644 23.403L475.651 1.26069H477.801Z" fill="currentcolor" />
        <path d="M477.115 25.7391L486.037 1.26069H488.644L497.531 25.7391H495.313L492.69 18.4599H482.008L479.316 25.7391H477.115ZM482.617 16.3777H492.029L487.357 3.41059L482.617 16.3777Z" fill="currentcolor" />
        <path d="M518.579 1.26069H520.678V25.7391H518.816L504.816 5.05264V25.7391H502.7V1.26069H504.613L518.579 21.8625V1.26069Z" fill="currentcolor" />
        <path d="M536.143 0.922119C537.813 0.922119 539.286 1.27761 540.561 1.98861C541.837 2.68831 542.858 3.61373 543.625 4.76486C544.393 5.91599 544.85 7.1574 544.997 8.4891H542.694C542.503 7.4734 542.124 6.54234 541.56 5.69592C541.007 4.8495 540.279 4.17237 539.376 3.66452C538.474 3.15666 537.39 2.90274 536.126 2.90274C534.445 2.90274 533.034 3.28645 531.894 4.05387C530.766 4.82129 529.913 5.98934 529.338 7.55804C528.774 9.12674 528.491 11.113 528.491 13.5168C528.491 17.1169 529.129 19.7803 530.404 21.507C531.691 23.2337 533.598 24.0971 536.126 24.0971C537.39 24.0971 538.474 23.8431 539.376 23.3353C540.279 22.8161 541.007 22.1277 541.56 21.27C542.124 20.4123 542.503 19.4756 542.694 18.4599H544.997C544.884 19.4305 544.607 20.3728 544.167 21.2869C543.727 22.2011 543.129 23.0193 542.373 23.7416C541.628 24.4526 540.731 25.0225 539.681 25.4513C538.643 25.8689 537.464 26.0777 536.143 26.0777C533.999 26.0777 532.182 25.5924 530.692 24.6218C529.202 23.6513 528.068 22.2349 527.29 20.3728C526.522 18.4994 526.138 16.2084 526.138 13.4999C526.138 10.7801 526.522 8.4891 527.29 6.62698C528.068 4.75357 529.202 3.33723 530.692 2.37796C532.182 1.4074 533.999 0.922119 536.143 0.922119Z" fill="currentcolor" />
        <path d="M550.443 25.7391V1.26069H565.103V3.22438H552.559V12.3826H564.071V14.3632H552.559V23.7077L565.51 23.7416V25.7391H550.443Z" fill="currentcolor" />
        <path d="M571.736 1.26069C571.781 1.26069 571.956 1.26633 572.261 1.27762C572.566 1.27762 572.949 1.27762 573.412 1.27762C573.886 1.27762 574.394 1.28326 574.936 1.29454C575.477 1.29454 576.002 1.29454 576.51 1.29454C579.523 1.31711 581.966 1.77982 583.84 2.68267C585.713 3.57423 587.084 4.90593 587.953 6.67776C588.822 8.4496 589.257 10.6672 589.257 13.3306C589.257 16.1294 588.783 18.4486 587.835 20.2882C586.887 22.1164 585.442 23.482 583.501 24.3848C581.56 25.2877 579.094 25.7391 576.104 25.7391H571.736V1.26069ZM573.971 3.22438V23.7754H576.053C578.423 23.7754 580.42 23.4425 582.045 22.7766C583.682 22.1108 584.918 21.0217 585.753 19.5095C586.599 17.9859 587.022 15.9602 587.022 13.4322C587.022 10.8139 586.599 8.7656 585.753 7.28719C584.906 5.80877 583.676 4.76486 582.062 4.15544C580.46 3.53473 578.513 3.22438 576.222 3.22438H573.971Z" fill="currentcolor" />
        <path d="M413.648 35.2607H417.762V59.7391H413.648V35.2607Z" fill="currentcolor" />
        <path d="M439.714 35.2607H443.827V59.7391H440.12L427.813 41.7781V59.7391H423.699V35.2607H427.728L439.714 52.7477V35.2607Z" fill="currentcolor" />
        <path d="M447.502 38.968V35.2607H466.597V38.968H459.25V59.7391H455.119V38.968H447.502Z" fill="currentcolor" />
        <path d="M470.285 59.7391V35.2607H486.469L486.452 38.7987H474.433V45.6547H485.047V49.1419H474.399V56.1503L486.706 56.1672V59.7391H470.285Z" fill="currentcolor" />
        <path d="M510.192 59.7391H505.892L501.677 49.8022H495.989V59.7391H491.876V35.2607H501.813C503.686 35.2607 505.227 35.5541 506.434 36.141C507.653 36.7165 508.556 37.5347 509.143 38.5956C509.729 39.6451 510.023 40.8753 510.023 42.286C510.023 43.5951 509.808 44.6954 509.38 45.587C508.951 46.4673 508.392 47.1895 507.704 47.7538C507.027 48.3068 506.299 48.7582 505.52 49.1081L510.192 59.7391ZM500.882 46.535C502.439 46.535 503.652 46.1738 504.521 45.4516C505.401 44.7293 505.842 43.7249 505.842 42.4383C505.842 41.1743 505.435 40.1981 504.623 39.5097C503.81 38.8213 502.738 38.4771 501.406 38.4771H495.989V46.535H500.882Z" fill="currentcolor" />
        <path d="M513.473 59.7391L522.276 35.2607H526.728L535.514 59.7391H531.383L529.2 53.5941H519.855L517.621 59.7391H513.473ZM520.803 49.9376H528.184L524.51 39.2727L520.803 49.9376Z" fill="currentcolor" />
        <path d="M547.959 34.9221C549.754 34.9221 551.345 35.2889 552.733 36.0225C554.121 36.7447 555.239 37.7266 556.085 38.968C556.943 40.2094 557.451 41.5919 557.609 43.1154H553.258C553.089 42.2126 552.773 41.4057 552.31 40.6947C551.859 39.9837 551.26 39.4194 550.516 39.0019C549.771 38.5843 548.879 38.3755 547.841 38.3755C546.543 38.3755 545.437 38.6859 544.523 39.3066C543.62 39.916 542.926 40.8865 542.441 42.2182C541.967 43.5499 541.73 45.2766 541.73 47.3983C541.73 50.6147 542.26 52.9565 543.321 54.4236C544.382 55.8907 545.888 56.6243 547.841 56.6243C548.879 56.6243 549.771 56.3986 550.516 55.9472C551.26 55.4844 551.859 54.875 552.31 54.1189C552.773 53.3515 553.089 52.5163 553.258 51.6135H557.609C557.473 52.7195 557.191 53.7803 556.762 54.796C556.345 55.8117 555.752 56.7202 554.985 57.5215C554.217 58.3115 553.252 58.9378 552.09 59.4005C550.939 59.852 549.562 60.0777 547.959 60.0777C545.714 60.0777 543.784 59.5642 542.17 58.5372C540.567 57.4989 539.332 56.0318 538.463 54.1358C537.605 52.2286 537.176 49.9658 537.176 47.3475C537.176 44.7406 537.605 42.5117 538.463 40.6608C539.32 38.81 540.55 37.3937 542.153 36.4118C543.767 35.4187 545.702 34.9221 547.959 34.9221Z" fill="currentcolor" />
        <path d="M559.52 38.968V35.2607H578.615V38.968H571.268V59.7391H567.138V38.968H559.52Z" fill="currentcolor" />
        <path d="M582.304 35.2607H586.417V59.7391H582.304V35.2607Z" fill="currentcolor" />
        <path d="M611.873 35.2607L603.324 59.7391H598.551L590.103 35.2607H594.082L600.937 54.9822L607.895 35.2607H611.873Z" fill="currentcolor" />
        <path d="M615.532 59.7391V35.2607H631.716L631.699 38.7987H619.68V45.6547H630.294V49.1419H619.646V56.1503L631.953 56.1672V59.7391H615.532Z" fill="currentcolor" />
        <path d="M423.399 94.0777C421.142 94.0777 419.189 93.5811 417.542 92.588C415.905 91.5948 414.636 90.1616 413.733 88.2882C412.841 86.4148 412.395 84.1689 412.395 81.5507C412.395 78.9099 412.847 76.6471 413.75 74.7624C414.653 72.8777 415.928 71.4332 417.576 70.4287C419.234 69.4243 421.176 68.9221 423.399 68.9221C425.622 68.9221 427.546 69.4243 429.171 70.4287C430.808 71.4332 432.072 72.8777 432.963 74.7624C433.855 76.6471 434.301 78.9099 434.301 81.5507C434.301 84.1689 433.861 86.4148 432.98 88.2882C432.1 90.1503 430.842 91.5836 429.205 92.588C427.569 93.5811 425.633 94.0777 423.399 94.0777ZM423.399 90.6243C424.731 90.6243 425.876 90.3139 426.835 89.6932C427.795 89.0612 428.528 88.085 429.036 86.7646C429.555 85.4329 429.815 83.7119 429.815 81.6015C429.815 79.4572 429.555 77.7079 429.036 76.3537C428.517 74.9881 427.778 73.9837 426.818 73.3404C425.859 72.6971 424.719 72.3755 423.399 72.3755C422.067 72.3755 420.916 72.7028 419.945 73.3574C418.986 74.0006 418.241 75.005 417.711 76.3706C417.18 77.7249 416.915 79.4685 416.915 81.6015C416.915 83.7119 417.18 85.4329 417.711 86.7646C418.241 88.085 418.986 89.0612 419.945 89.6932C420.916 90.3139 422.067 90.6243 423.399 90.6243Z" fill="currentcolor" />
        <path d="M457.964 69.2607L449.415 93.7391H444.641L436.194 69.2607H440.172L447.028 88.9822L453.986 69.2607H457.964Z" fill="currentcolor" />
        <path d="M461.623 93.7391V69.2607H477.806L477.789 72.7987H465.77V79.6547H476.384V83.1419H465.736V90.1503L478.043 90.1672V93.7391H461.623Z" fill="currentcolor" />
        <path d="M501.53 93.7391H497.23L493.015 83.8022H487.327V93.7391H483.213V69.2607H493.15C495.023 69.2607 496.564 69.5541 497.772 70.141C498.99 70.7165 499.893 71.5347 500.48 72.5956C501.067 73.6451 501.36 74.8753 501.36 76.286C501.36 77.5951 501.146 78.6954 500.717 79.587C500.288 80.4673 499.73 81.1895 499.041 81.7538C498.364 82.3068 497.636 82.7582 496.857 83.1081L501.53 93.7391ZM492.219 80.535C493.776 80.535 494.99 80.1738 495.859 79.4516C496.739 78.7293 497.179 77.7249 497.179 76.4383C497.179 75.1743 496.773 74.1981 495.96 73.5097C495.148 72.8213 494.076 72.4771 492.744 72.4771H487.327V80.535H492.219Z" fill="currentcolor" />
        <path d="M506.473 69.2607H510.638V90.1842H521.692V93.7391H506.457L506.473 69.2607Z" fill="currentcolor" />
        <path d="M524.682 93.7391L533.484 69.2607H537.936L546.722 93.7391H542.592L540.408 87.5941H531.064L528.829 93.7391H524.682ZM532.012 83.9376H539.392L535.719 73.2727L532.012 83.9376Z" fill="currentcolor" />
        <path d="M566.434 69.2607L558.02 83.3959V93.7391H553.907V83.3789L545.476 69.2607H549.945L555.989 79.7901L561.931 69.2607H566.434Z" fill="currentcolor" />
        <path d="M414.19 103.261H428.664L428.647 105.224H416.424V114.4H427.631V116.363H416.424V127.739H414.19V103.261Z" fill="currentcolor" />
        <path d="M442.614 128.078C440.503 128.078 438.675 127.598 437.129 126.639C435.594 125.668 434.409 124.252 433.574 122.39C432.75 120.528 432.338 118.248 432.338 115.551C432.338 112.842 432.756 110.551 433.591 108.678C434.426 106.793 435.611 105.365 437.146 104.395C438.681 103.413 440.503 102.922 442.614 102.922C444.713 102.922 446.524 103.407 448.048 104.378C449.571 105.349 450.739 106.776 451.552 108.661C452.376 110.534 452.788 112.831 452.788 115.551C452.788 118.237 452.376 120.511 451.552 122.373C450.739 124.235 449.571 125.651 448.048 126.622C446.524 127.592 444.713 128.078 442.614 128.078ZM442.614 126.097C444.295 126.097 445.717 125.719 446.88 124.963C448.053 124.207 448.939 123.05 449.537 121.493C450.147 119.924 450.451 117.949 450.451 115.568C450.451 113.141 450.141 111.144 449.52 109.575C448.911 108.006 448.025 106.838 446.863 106.071C445.7 105.292 444.284 104.903 442.614 104.903C440.943 104.903 439.516 105.292 438.331 106.071C437.146 106.838 436.237 108.012 435.605 109.592C434.985 111.172 434.674 113.164 434.674 115.568C434.674 117.96 434.985 119.935 435.605 121.493C436.237 123.05 437.146 124.207 438.331 124.963C439.516 125.719 440.943 126.097 442.614 126.097Z" fill="currentcolor" />
        <path d="M474.904 127.739H472.585L467.862 116.583H460.346V127.739H458.23V103.261H467.236C468.895 103.261 470.3 103.509 471.451 104.006C472.602 104.491 473.471 105.202 474.058 106.139C474.656 107.075 474.955 108.226 474.955 109.592C474.955 110.946 474.696 112.063 474.177 112.944C473.669 113.824 473.031 114.518 472.264 115.026C471.496 115.534 470.723 115.912 469.944 116.16L474.904 127.739ZM466.152 114.653C468.285 114.653 469.916 114.27 471.045 113.502C472.185 112.724 472.755 111.494 472.755 109.812C472.755 108.198 472.264 107.019 471.282 106.274C470.3 105.518 468.906 105.14 467.1 105.14H460.346V114.653H466.152Z" fill="currentcolor" />
        <path d="M414.19 161.739V137.261H421.503C424.347 137.261 426.531 137.808 428.054 138.903C429.578 139.986 430.339 141.555 430.339 143.609C430.339 144.963 429.984 146.114 429.273 147.062C428.573 147.999 427.36 148.738 425.633 149.28C426.559 149.46 427.36 149.731 428.037 150.092C428.714 150.442 429.267 150.865 429.696 151.362C430.125 151.859 430.441 152.428 430.644 153.072C430.847 153.715 430.949 154.42 430.949 155.188C430.949 157.377 430.153 159.019 428.562 160.114C426.971 161.197 424.674 161.739 421.672 161.739H414.19ZM416.306 159.809H421.571C423.929 159.809 425.724 159.454 426.954 158.743C428.195 158.021 428.816 156.852 428.816 155.239C428.816 154.042 428.506 153.089 427.885 152.378C427.275 151.655 426.452 151.136 425.413 150.82C424.386 150.493 423.247 150.329 421.994 150.329H416.306V159.809ZM416.306 148.383H421.994C422.705 148.383 423.427 148.321 424.161 148.196C424.894 148.061 425.571 147.83 426.192 147.502C426.813 147.164 427.315 146.701 427.699 146.114C428.082 145.527 428.274 144.783 428.274 143.88C428.274 142.243 427.648 141.047 426.395 140.291C425.142 139.535 423.512 139.157 421.503 139.157H416.306V148.383Z" fill="currentcolor" />
        <path d="M453.38 161.739H451.061L446.338 150.583H438.822V161.739H436.706V137.261H445.712C447.371 137.261 448.776 137.509 449.927 138.006C451.078 138.491 451.947 139.202 452.534 140.139C453.132 141.075 453.431 142.226 453.431 143.592C453.431 144.946 453.172 146.063 452.652 146.944C452.145 147.824 451.507 148.518 450.739 149.026C449.972 149.534 449.199 149.912 448.42 150.16L453.38 161.739ZM444.628 148.653C446.761 148.653 448.392 148.27 449.521 147.502C450.66 146.724 451.23 145.494 451.23 143.812C451.23 142.198 450.739 141.019 449.758 140.274C448.776 139.518 447.382 139.14 445.576 139.14H438.822V148.653H444.628Z" fill="currentcolor" />
        <path d="M467.709 162.078C465.598 162.078 463.77 161.598 462.224 160.639C460.689 159.668 459.504 158.252 458.669 156.39C457.845 154.528 457.433 152.248 457.433 149.551C457.433 146.842 457.851 144.551 458.686 142.678C459.521 140.793 460.706 139.365 462.241 138.395C463.776 137.413 465.598 136.922 467.709 136.922C469.808 136.922 471.619 137.407 473.143 138.378C474.666 139.349 475.834 140.776 476.647 142.661C477.471 144.534 477.883 146.831 477.883 149.551C477.883 152.237 477.471 154.511 476.647 156.373C475.834 158.235 474.666 159.651 473.143 160.622C471.619 161.592 469.808 162.078 467.709 162.078ZM467.709 160.097C469.39 160.097 470.812 159.719 471.975 158.963C473.148 158.207 474.034 157.05 474.632 155.493C475.242 153.924 475.546 151.949 475.546 149.568C475.546 147.141 475.236 145.144 474.615 143.575C474.006 142.006 473.12 140.838 471.958 140.071C470.795 139.292 469.379 138.903 467.709 138.903C466.038 138.903 464.611 139.292 463.426 140.071C462.241 140.838 461.332 142.012 460.7 143.592C460.08 145.172 459.769 147.164 459.769 149.568C459.769 151.96 460.08 153.935 460.7 155.493C461.332 157.05 462.241 158.207 463.426 158.963C464.611 159.719 466.038 160.097 467.709 160.097Z" fill="currentcolor" />
        <path d="M480.29 161.739L489.211 137.261H491.818L500.705 161.739H498.488L495.864 154.46H485.182L482.49 161.739H480.29ZM485.791 152.378H495.203L490.531 139.411L485.791 152.378Z" fill="currentcolor" />
        <path d="M505.874 137.261C505.919 137.261 506.094 137.266 506.399 137.278C506.704 137.278 507.087 137.278 507.55 137.278C508.024 137.278 508.532 137.283 509.074 137.295C509.615 137.295 510.14 137.295 510.648 137.295C513.661 137.317 516.104 137.78 517.978 138.683C519.851 139.574 521.223 140.906 522.091 142.678C522.96 144.45 523.395 146.667 523.395 149.331C523.395 152.129 522.921 154.449 521.973 156.288C521.025 158.116 519.58 159.482 517.639 160.385C515.698 161.288 513.232 161.739 510.242 161.739H505.874V137.261ZM508.109 139.224V159.775H510.191C512.561 159.775 514.558 159.442 516.184 158.777C517.82 158.111 519.056 157.022 519.891 155.509C520.737 153.986 521.16 151.96 521.16 149.432C521.16 146.814 520.737 144.766 519.891 143.287C519.044 141.809 517.814 140.765 516.2 140.155C514.598 139.535 512.651 139.224 510.36 139.224H508.109Z" fill="currentcolor" />
        <path d="M537.3 136.922C538.971 136.922 540.443 137.278 541.719 137.989C542.994 138.688 544.015 139.614 544.783 140.765C545.55 141.916 546.007 143.157 546.154 144.489H543.852C543.66 143.473 543.282 142.542 542.717 141.696C542.164 140.85 541.436 140.172 540.534 139.665C539.631 139.157 538.547 138.903 537.283 138.903C535.602 138.903 534.191 139.286 533.051 140.054C531.923 140.821 531.071 141.989 530.495 143.558C529.931 145.127 529.649 147.113 529.649 149.517C529.649 153.117 530.286 155.78 531.562 157.507C532.848 159.234 534.755 160.097 537.283 160.097C538.547 160.097 539.631 159.843 540.534 159.335C541.436 158.816 542.164 158.128 542.717 157.27C543.282 156.412 543.66 155.476 543.852 154.46H546.154C546.041 155.43 545.765 156.373 545.324 157.287C544.884 158.201 544.286 159.019 543.53 159.742C542.785 160.453 541.888 161.022 540.838 161.451C539.8 161.869 538.621 162.078 537.3 162.078C535.156 162.078 533.339 161.592 531.849 160.622C530.36 159.651 529.225 158.235 528.447 156.373C527.679 154.499 527.296 152.208 527.296 149.5C527.296 146.78 527.679 144.489 528.447 142.627C529.225 140.754 530.36 139.337 531.849 138.378C533.339 137.407 535.156 136.922 537.3 136.922Z" fill="currentcolor" />
        <path d="M549.458 161.739L558.379 137.261H560.986L569.873 161.739H567.656L565.032 154.46H554.35L551.658 161.739H549.458ZM554.96 152.378H564.372L559.699 139.411L554.96 152.378Z" fill="currentcolor" />
        <path d="M582.367 162.078C581.25 162.078 580.172 161.937 579.134 161.654C578.107 161.372 577.17 160.955 576.324 160.402C575.489 159.849 574.795 159.166 574.242 158.353C573.689 157.541 573.328 156.599 573.158 155.526H575.427C575.664 156.553 576.115 157.417 576.781 158.116C577.458 158.805 578.276 159.324 579.236 159.674C580.206 160.024 581.25 160.199 582.367 160.199C583.654 160.199 584.794 160.012 585.787 159.64C586.78 159.256 587.559 158.726 588.123 158.049C588.699 157.36 588.986 156.565 588.986 155.662C588.986 154.849 588.828 154.138 588.512 153.529C588.196 152.908 587.717 152.383 587.073 151.955C586.441 151.526 585.64 151.193 584.67 150.956L579.557 149.72C577.774 149.291 576.386 148.603 575.393 147.655C574.411 146.695 573.914 145.437 573.903 143.88C573.903 142.548 574.253 141.363 574.953 140.325C575.652 139.275 576.629 138.451 577.881 137.853C579.134 137.255 580.584 136.956 582.232 136.956C584.117 136.956 585.691 137.295 586.955 137.972C588.219 138.638 589.173 139.501 589.816 140.562C590.459 141.611 590.775 142.712 590.764 143.863H588.529C588.405 142.621 588.033 141.639 587.412 140.917C586.803 140.195 586.041 139.681 585.127 139.377C584.224 139.061 583.253 138.903 582.215 138.903C581.504 138.903 580.787 138.999 580.065 139.191C579.354 139.382 578.7 139.681 578.101 140.088C577.515 140.483 577.041 140.985 576.679 141.594C576.318 142.192 576.138 142.903 576.138 143.727C576.138 144.709 576.476 145.51 577.153 146.131C577.831 146.741 579.044 147.271 580.793 147.722L585.719 148.975C586.78 149.223 587.66 149.585 588.36 150.059C589.071 150.521 589.63 151.052 590.036 151.65C590.453 152.248 590.747 152.886 590.916 153.563C591.097 154.24 591.187 154.923 591.187 155.611C591.187 156.796 590.848 157.879 590.171 158.861C589.494 159.832 588.501 160.611 587.192 161.197C585.894 161.784 584.286 162.078 582.367 162.078Z" fill="currentcolor" />
        <path d="M593.496 139.275V137.261H611.423V139.275H603.805V161.739H601.672V139.275H593.496Z" fill="currentcolor" />
        <path d="M615.776 137.261H617.892V161.739H615.776V137.261Z" fill="currentcolor" />
        <path d="M640.78 137.261H642.88V161.739H641.017L627.018 141.053V161.739H624.902V137.261H626.815L640.78 157.863V137.261Z" fill="currentcolor" />
        <path d="M658.379 162.078C656.28 162.078 654.479 161.598 652.978 160.639C651.478 159.668 650.326 158.258 649.525 156.407C648.735 154.545 648.34 152.259 648.34 149.551C648.34 146.865 648.741 144.579 649.542 142.695C650.355 140.81 651.517 139.377 653.029 138.395C654.542 137.413 656.342 136.922 658.429 136.922C659.547 136.922 660.613 137.131 661.629 137.548C662.645 137.955 663.553 138.513 664.354 139.224C665.167 139.924 665.821 140.725 666.318 141.628C666.826 142.531 667.119 143.485 667.198 144.489H664.896C664.727 143.519 664.343 142.61 663.745 141.764C663.147 140.906 662.385 140.218 661.46 139.698C660.545 139.179 659.53 138.92 658.412 138.92C656.832 138.92 655.456 139.309 654.282 140.088C653.108 140.855 652.2 142.023 651.556 143.592C650.913 145.149 650.592 147.124 650.592 149.517C650.592 151.345 650.778 152.925 651.15 154.257C651.534 155.588 652.07 156.689 652.758 157.558C653.458 158.416 654.288 159.053 655.247 159.471C656.217 159.888 657.284 160.097 658.446 160.097C659.631 160.097 660.658 159.888 661.527 159.471C662.396 159.042 663.119 158.489 663.694 157.812C664.27 157.135 664.699 156.412 664.981 155.645C665.274 154.866 665.432 154.127 665.455 153.427L665.489 150.871H659.039V149.195L667.655 149.212V161.739H665.878V157.71C665.449 158.5 664.89 159.228 664.202 159.894C663.514 160.548 662.69 161.079 661.73 161.485C660.771 161.88 659.654 162.078 658.379 162.078Z" fill="currentcolor" />
      </FloatingContent>
    </Container>
  );
}

export default LogoSlider;
