/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Cross({ size = 24, style }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1124 9.06123C20.2674 7.90625 20.2674 6.03366 19.1124 4.87868C17.9574 3.7237 16.0849 3.7237 14.9299 4.87868L12.7027 7.10589C12.3121 7.49642 11.679 7.49642 11.2884 7.10589L9.06123 4.87868C7.90626 3.7237 6.03366 3.7237 4.87868 4.87868C3.72371 6.03366 3.72371 7.90626 4.87869 9.06123L7.1059 11.2884C7.49642 11.679 7.49642 12.3121 7.1059 12.7027L4.87869 14.9299C3.72371 16.0848 3.72371 17.9574 4.87869 19.1124C6.03367 20.2674 7.90626 20.2674 9.06124 19.1124L11.2884 16.8852C11.679 16.4947 12.3121 16.4947 12.7027 16.8852L14.9299 19.1124C16.0848 20.2674 17.9574 20.2674 19.1124 19.1124C20.2674 17.9574 20.2674 16.0848 19.1124 14.9299L16.8852 12.7027C16.4947 12.3121 16.4947 11.679 16.8852 11.2884L19.1124 9.06123Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Cross;
