import React from 'react';
import styled from 'styled-components';
import { useAnimationControls } from 'framer-motion';
import { Lock, Copy as CopyIcon } from '../icons';
import { UIColor } from '../../theme';
import { Notification } from '../common/CopyToClipboard';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const NotificationContainer = styled.div`
  display: flex;
  background-color: transparent;
  align-items: center;
  position: relative;
  width: 100%;
  position: absolute;
`;

const CopyButton = styled.button<{ color : UIColor }>`
  display: flex;
  padding: 4px;
  align-items: center;
  transition: all 300ms;
  border-radius: 8px;
  background-color: ${({ theme }) => (theme.gray[900])};
  color: ${({ theme }) => (theme.gray[500])};
  &:hover {
    cursor: pointer;;
    color: ${({ theme, color }) => theme.getUIColor(color, 400)};
    background-color: ${({ theme, color }) => theme.getUIColor(color, 900)};
  }
`;

const Input = styled.input<{ disabled?: boolean }>`
  flex: 1;
  background-color: transparent;
  font-weight: 600;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 16px;
  line-height: 100%;
  font-family: 'Public Sans', sans-serif;
  color: ${({ theme }) => (theme.gray[500])};
`;

const Box = styled.div<{ color : UIColor }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 8px 8px 16px;
  background-color: ${({ theme }) => (theme.gray[900])};
  color: ${({ theme }) => (theme.gray[500])};
  transition: all 300ms;
  &:hover {
    cursor: text;
    ${CopyButton} {
      color: ${({ theme, color }) => theme.getUIColor(color, 400)};
      background-color: ${({ theme }) => (theme.gray[800])};
    }
  }
  border-radius: 16px;
  gap: 8px;
`;

const BoxButton = styled.button<{ color : UIColor }>`
  width: 350px;
  user-select: none;
  height: 65px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 12px 12px 16px;
  background-color: ${({ theme }) => (theme.gray[900])};
  color: ${({ theme }) => (theme.gray[500])};
  transition: all 300ms;
  &:hover {
    cursor: pointer;
    color: ${({ theme, color }) => theme.getUIColor(color, 400)};
    ${CopyButton} {
      color: ${({ theme, color }) => theme.getUIColor(color, 400)};
    }
  }
  border-radius: 16px;
  gap: 8px;
  > label {
    flex: 1;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    font-family: 'Public Sans', sans-serif;
    text-align: left;
  }
  ${NotificationContainer} {
    padding-left: 26px;
  }
`;

const Info = styled.label`
  color:   ${({ theme }) => (theme.gray[400])};
  line-height: 140%;
  font-size: 12px;
  font-weight: 700;
  padding: 0 16px;
  min-height: 12px;
  white-space: pre-wrap;
`;

// TYPE ------------------------------------------------------------

type TextFieldProps = {
  value: string;
  style?: React.CSSProperties;
  secure?: boolean;
  info?: string;
  color?: UIColor;
};

type FieldProps = {
  value: string;
  color: UIColor;
};

// COMPONENTS ------------------------------------------------------------

function DefaultField({ value, color } : FieldProps) {
  const controls = useAnimationControls();

  const copy = async () => {
    navigator.clipboard.writeText(value);
    await controls.start({
      scale: [0, 1],
      transition:
      {
        type: 'spring', bounce: 0.5, duration: 0.5,
      },
    });
    await controls.start({
      scale: [1, 0],
      transition: { duration: 0.2, delay: 0.5 },
    });
  };

  return (
    <Box color={color}>
      <NotificationContainer>
        <Notification initial={{ scale: 0 }} animate={controls}>Copied</Notification>
      </NotificationContainer>
      <Input value={value} />
      <CopyButton type="button" color={color} onClick={copy}>
        <CopyIcon size={22} />
      </CopyButton>
    </Box>
  );
}

function SecureField({ value, color } : FieldProps) {
  const controls = useAnimationControls();

  const copy = async () => {
    navigator.clipboard.writeText(value);
    await controls.start({
      scale: [0, 1],
      transition:
      {
        type: 'spring', bounce: 0.5, duration: 0.5,
      },
    });
    await controls.start({
      scale: [1, 0],
      transition: { duration: 0.2, delay: 0.5 },
    });
  };

  return (
    <BoxButton color={color} onClick={copy}>
      <NotificationContainer>
        <Notification initial={{ scale: 0 }} animate={controls}>Copied</Notification>
      </NotificationContainer>
      <Lock size={18} />
      <label>Click to copy</label>
      <CopyIcon size={22} />
    </BoxButton>
  );
}

function CopyField({
  value,
  info,
  secure = false,
  color = 'default',
  style,
} : TextFieldProps) {
  return (
    <Container style={style}>
      {secure
        ? <SecureField value={value} color={color} />
        : <DefaultField value={value} color={color} />}
      {info ? <Info>{info}</Info> : null}
    </Container>
  );
}

export default CopyField;
