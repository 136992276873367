import { useEffect, useState } from 'react';
import { onSnapshot, query, where } from 'firebase/firestore';
import useAuthentication from './useAuthentication';
import db from '../utils/db';
import * as Collections from '../common/Collections';

const useWorkspaceList = () => {
  const { user } = useAuthentication();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [workspaces, setWorkspaces] = useState<Collections.WorkspaceDocument[] | undefined>(undefined);

  useEffect(() => {
    const q = query(db.workspaces, where('ownerId', '==', user?.uid));
    return onSnapshot(q, (snapshot) => {
      const res = snapshot.docs.map((doc) => ({ ...doc.data() }));
      setWorkspaces(res);
      setError(undefined);
      setLoading(false);
    }, (e) => {
      // TODO: manage code
      setError(e.message);
      setLoading(false);
    });
  }, [setLoading, setError, setWorkspaces, user?.uid]);

  return { loading, error, workspaces };
};

export default useWorkspaceList;
