import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimationControls } from 'framer-motion';
import { Error } from '../icons';
import { P } from './style';

// STYLED ------------------------------------------------------------

const Container = styled(motion.div)`
  display: flex;
  gap: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme.states.error[500])};
  padding: 8px;
  gap: 8px;
  p {
    font-weight: 700;
    color: ${({ theme }) => (theme.states.error[500])};
  }
`;

// TYPE ------------------------------------------------------------

type AuthenticationErrorProps = {
  label: string;
};

// COMPONENTS ------------------------------------------------------------

function AuthenticationError({
  label,
} : AuthenticationErrorProps) {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start({ x: [0, 10, -10, 10, 0], transition: { duration: 0.3 } });
  }, [label]);

  return (
    <Container animate={controls}>
      <Error size={20} />
      <P>{label}</P>
    </Container>
  );
}

export default AuthenticationError;
