import React from 'react';
import { OverlayConfigProps } from '../Overlay';
import { Counter } from '../../../common/Overlays';
import updateOverlayConfig from '../../../actions/updateOverlayConfig';
import { Button } from '../../atoms';

export default function Config({ overlay, config }: OverlayConfigProps) {
  const overlayConfig = config as Counter.Config;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const [initialValue, setInitialValue] = React.useState(overlayConfig.initialValue);
  const [incrementOffset, setIncrementOffset] = React.useState(overlayConfig.incrementOffset);

  const hasDiff: boolean = React.useMemo(() => (
    initialValue !== overlayConfig.initialValue
    || incrementOffset !== overlayConfig.incrementOffset
  ), [initialValue, incrementOffset, overlayConfig.initialValue, overlayConfig.incrementOffset]);

  const handleCancel = React.useCallback(() => {
    setInitialValue(overlayConfig.initialValue);
    setIncrementOffset(overlayConfig.incrementOffset);
  }, [overlayConfig.initialValue, overlayConfig.incrementOffset]);

  const handleSubmit = React.useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!hasDiff) {
      return;
    }
    setLoading(true);
    const res = await updateOverlayConfig<Counter.Config>({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
      config: {
        initialValue,
        incrementOffset,
      },
    });
    if (res.ok) {
      setError(undefined);
    } else {
      setError(res.error);
    }
    setLoading(false);
  }, [hasDiff, overlay.overlayId, overlay.workspaceId, initialValue, incrementOffset]);

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="number"
        value={initialValue}
        onChange={(e) => setInitialValue(parseInt(e.target.value, 10) || 0)}
        disabled={loading}
      />
      <input
        type="number"
        value={incrementOffset}
        onChange={(e) => setIncrementOffset(parseInt(e.target.value, 10) || 0)}
        disabled={loading}
      />
      {
        hasDiff ? (
          <>
            <Button type="submit" label="Save" loading={loading} />
            <Button variant="tonal" label="Cancel" disabled={loading} onClick={handleCancel} />
          </>
        ) : null
      }
      {error && <p>{error}</p>}
    </form>
  );
}
