import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { shadow99 } from '../common';
import useOnClickOutside from '../../../hooks/useClickOutside';
import Header from './Header';

// STYLED ------------------------------------------------------------

const Box = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.gray[800]};
  border-radius: 16px;
  width: 100%;
  max-width: 450px;
  ${shadow99}
`;

const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000A6;
  z-index: 1000;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

// TYPE ------------------------------------------------------------

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClose?: () => void;
  visible?: boolean;
};

// COMPONENTS ------------------------------------------------------------

function Modal({
  children, style, onClose, visible = true,
} : Props) {
  const boxRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(boxRef, () => onClose?.());
  const transitionIn = { duration: 0.2, ease: [0.25, 1, 0.5, 1] };
  const transitionOut = { duration: 0.15, ease: [0.5, 0, 0.75, 0] };

  return (
    <AnimatePresence>
      {visible && (
      <>
        <Background
          key="background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: transitionIn }}
          exit={{ opacity: 0, transition: transitionOut }}
        />
        <Container>
          <Box
            key="modal"
            ref={boxRef}
            style={style}
            initial={{ scale: 0 }}
            animate={{ scale: 1, transition: transitionIn }}
            exit={{ scale: 0, transition: transitionOut }}
          >
            {children}
          </Box>
        </Container>
      </>
      )}
    </AnimatePresence>
  );
}

Modal.Container = Modal;
Modal.Header = Header;

export default Modal;
