import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { WorkspaceDocument } from '../../common/Collections';
import { Plus, ArrowRight, EmptyBox } from '../icons';
import { Image } from '../atoms';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  overflow: hidden;
  width: 100%;
  max-width: 385px;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  transition: all 150ms;
`;

const Cell = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  transition: all 150ms;
  border-bottom: 2px solid ${({ theme }) => theme.gray[900]};
  background-color: ${({ theme }) => theme.gray[800]};
  color: ${({ theme }) => theme.gray[500]};
  text-decoration: none;
  :focus,
  :hover {
    background-color: ${({ theme }) => theme.gray[700]};
    color: ${({ theme }) => theme.gray[300]};
    ${IconBox} {
      color: ${({ theme }) => theme.ui.default[500]};
    }
  }
`;

const AddCell = styled(Cell)`
  padding: 8px 16px;
  justify-content: center;
  height: 70px;
  border-bottom: 0;
`;

const AddFirstCell = styled(AddCell)`
  color: ${({ theme }) => theme.gray[100]};
  background-color: ${({ theme }) => theme.ui.default[500]};
  :focus,
  :hover {
    color: ${({ theme }) => theme.gray[100]};
    background-color: ${({ theme }) => theme.ui.default[700]};
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  gap: 8px;
  background-color: ${({ theme }) => theme.gray[800]};
  color: ${({ theme }) => theme.gray[600]};
  border-bottom: 2px solid ${({ theme }) => theme.gray[900]};
  p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 140%;
    max-width: 225px;
  }
`;

const Label = styled.p`
  font-size: 20px;
  line-height: 100%;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  flex: 1;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

// TYPE ------------------------------------------------------------

type Props = {
  workspaces : WorkspaceDocument[];
  handleCreate?: () => void;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function WorkspaceSelector({ workspaces, handleCreate, style } : Props) {
  return (
    <Container style={style}>
      {workspaces.length > 0
        ? workspaces.map((workspace) => (
          <Cell to={`/workspace/${workspace.workspaceId}`} key={workspace.workspaceId}>
            <Image
              height={52}
              width={52}
              style={{ borderRadius: 20 }}
              src={workspace.twitch?.profileImageUrl}
              alt={`${workspace.name}'s picture`}
            />
            <Label>{workspace.name}</Label>
            <IconBox>
              <ArrowRight size={24} />
            </IconBox>
          </Cell>
        ))
        : (
          <EmptyContainer>
            <EmptyBox size={56} />
            <p>{'Oh no, you don\'t have any workspace yet.'}</p>
          </EmptyContainer>
        )}
      {handleCreate && (
        workspaces.length > 0
          ? (
            <AddCell as="button" onClick={handleCreate}>
              <IconBox>
                <Plus size={24} />
              </IconBox>
              <Label>Create a new workspace</Label>
            </AddCell>
          )
          : (
            <AddFirstCell as="button" onClick={handleCreate}>
              <Label style={{ textAlign: 'center' }}>Create a workspace</Label>
            </AddFirstCell>
          )
      )}
    </Container>
  );
}

export default WorkspaceSelector;
