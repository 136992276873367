import React, { useCallback } from 'react';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import * as Collections from '../../common/Collections';
import { OverlayActionProps as UpperOverlayActionProps } from './Overlay';
import useOverlayData from '../../hooks/useOverlayData';
import db from '../../utils/db';

export type OverlayDataProps<TConfig extends Record<string, any>, TData extends Record<string, any>> =
  UpperOverlayActionProps & {
    updateOverlayData: (data: Partial<TData>) => Promise<boolean>
    overlayData: Collections.OverlayDataDocument<TConfig, TData>
  };

type OverlayDataMiddlewareProps<TConfig extends Record<string, any>, TData extends Record<string, any>> =
  UpperOverlayActionProps & {
    component: React.ComponentType<OverlayDataProps<TConfig, TData>>
  };

export default function OverlayDataMiddleware<TConfig extends Record<string, any>, TData extends Record<string, any>>(
  { overlay, privateKey, component: Component }: OverlayDataMiddlewareProps<TConfig, TData>,
) {
  const overlayDataRes = useOverlayData<TConfig, TData>(privateKey);

  const updateOverlayData = useCallback(async (data: Partial<TData>): Promise<boolean> => {
    try {
      const overlayDataRef = db.overlayData(privateKey);
      const input = Object.keys(data).reduce((acc: any[], key) => [...acc, `data.${key}`, data[key]], []);
      await updateDoc(overlayDataRef, 'timestamps.updatedAt', serverTimestamp(), ...input);
      return true;
    } catch (_) { console.error(_); return false; }
  }, [privateKey]);

  if (overlayDataRes.state === 'loading') {
    return <div>Loading...</div>;
  }
  if (overlayDataRes.state === 'error') {
    return (
      <div>
        Error:
        {overlayDataRes.error}
      </div>
    );
  }
  return (
    <Component
      overlay={overlay}
      privateKey={privateKey}
      overlayData={overlayDataRes.overlayData}
      updateOverlayData={updateOverlayData}
    />
  );
}
