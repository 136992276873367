export namespace Counter {
  export type Type = 'counter';
  export const type: Type = 'counter';

  export type Availability = 'free';
  export const availability: Availability = 'free';

  export type Config = {
    initialValue: number
    incrementOffset: number
  };

  export type Data = {
    value: number
  };
}

export namespace CustomRivenziRugby {
  export type Type = 'custom_rivenzi_rugby';
  export const type: Type = 'custom_rivenzi_rugby';

  export type Availability = 'custom';
  export const availability: Availability = 'custom';

  export type Config = {
    rapidApiKey: string
    match?: {
      id: string
      homeName: string
      awayName: string
      date: string
    }
    names: {
      home: string
      homeIsHype: boolean
      away: string
      awayIsHype: boolean
    }
    colors: {
      home: [string, string, string]
      away: [string, string, string]
      secondary: string
      autoVariation: boolean
    }
    statsLoopInterval: number
    statsDelay?: number
    requests: {
      loopEnabled: boolean
      loopInterval: number
    }
    styleOverride?: string
  };

  export type Data = {
    displaySection: boolean
    section: 'teamSheets' | 'stats'
    lastAnimation?: {
      team: 'home' | 'away'
      type: 'penalty' | 'try'
      time: string
    } | null
  };
}

export namespace CustomRivenziPollEmote {
  export type Type = 'custom_rivenzi_poll_emote';
  export const type: Type = 'custom_rivenzi_poll_emote';

  export type Availability = 'custom';
  export const availability: Availability = 'custom';

  export enum VoteType {
    ONE_VOTE_PER_MESSAGE = 'ONE_VOTE_PER_MESSAGE',
    ONE_VOTE_PER_EMOTE = 'ONE_VOTE_PER_EMOTE',
    ALL_EMOTES_COUNTED = 'ALL_EMOTES_COUNTED',
  }

  export type Emote = {
    id: string
    name: string
    isAnimated: boolean
  };

  export type Config = {
    emotes: Emote[]
    voteType: VoteType
    durations: {
      polling: number
      result: number
    }
    quality: {
      highQualityAnimation: boolean
      refreshRate: number
    }
    styleOverride: string
  };

  export type Data = {
    status: 'disabled' | 'polling' | 'result'
    isHidden: boolean
    lastResult?: (Emote & { votes: number })[] | null
  };
}
