import React from 'react';
import styled from 'styled-components';
import { Button, TextField, ButtonBase } from '../atoms';
import ImageDrop from '../common/ImageDrop';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  overflow: hidden;
  padding: 16px;
  background-color: ${({ theme }) => theme.gray[800]};
  gap: 16px;
  align-items: center;
  width: 100%;
  max-width: 385px;
`;

const P = styled.p`
  line-height: 140%;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: ${({ theme }) => theme.gray[500]};
  text-align: center;
`;

const CustomButton = styled.button<{ color: string, disabled : boolean }>`
  ${ButtonBase}
  width: 100%;
  transition: all 150ms;
  color: ${({ theme, disabled }) => (theme.gray[disabled ? 500 : 100])};
  background-color: ${({ color }) => color};
  &:focus,
  &:hover {
    background-color: ${({ color }) => `${color}A6`};
  }
`;

const Settings = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;

// TYPE ------------------------------------------------------------

type Props = {
  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
};

type ButtonProps = {
  onClick?: () => void;
  color: string;
  icon?: JSX.Element;
  label: string;
};

// COMPONENTS ------------------------------------------------------------

function WorkspaceCreator({ children, style } : Props) {
  const [workspaceName, setWorkspaceName] = React.useState('');
  const [imageLoad, setImageLoad] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState('');

  const onDrop = (file: File) => {
    setImageLoad(true);
    const allowedExtensions = ['image/jpeg', 'image/png'];
    if (!allowedExtensions.includes(file.type)) {
      console.log('Invalid file extension. Please select a jpeg, jpg, or png file.');
      // TODO: show error
      setImageLoad(false);
      return;
    }
    // TODO: upload image
    console.log(file);
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const imageUrl = event.target?.result as string;
      setImageSrc(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Container style={style}>
      <Settings>
        <ImageDrop
          height={64}
          width={64}
          src={imageSrc}
          alt="Droped worspace picture"
          onChange={onDrop}
          loading={imageLoad}
          style={{ minWidth: '64px' }}
        />
        <TextField
          label="Workspace name"
          value={workspaceName}
          onChange={setWorkspaceName}
          style={{ flex: 1, width: 'auto', minWidth: '0px' }}
        />
      </Settings>
      <Button label="create" style={{ width: '100%' }} />
      {children
      && (
      <>
        <P>or</P>
        {children}
      </>
      )}
    </Container>
  );
}

export function OptionButton({
  onClick, color, icon, label,
} : ButtonProps) {
  return (
    <CustomButton type="button" onClick={onClick} color={color} disabled={false}>
      {icon}
      {label}
    </CustomButton>
  );
}

export default WorkspaceCreator;
