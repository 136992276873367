/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Box({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V7C22 7.55228 21.5523 8 21 8H3C2.44772 8 2 7.55228 2 7V5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21C20.1046 21 21 20.1046 21 19L21 11C21 10.4477 20.5523 10 20 10H4C3.44771 10 3 10.4477 3 11V19C3 20.1046 3.89543 21 5 21H19ZM10.375 11.8333C9.61561 11.8333 9 12.4489 9 13.2083C9 13.9677 9.61561 14.5833 10.375 14.5833H13.625C14.3844 14.5833 15 13.9677 15 13.2083C15 12.4489 14.3844 11.8333 13.625 11.8333H10.375Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Box;
