import React, { useCallback, useState } from 'react';
import { Button, TextField } from '../atoms';
import Error from './AuthenticationError';
import {
  Container,
  H1,
  FormContainer,
  Spacer,
  OtherOptionSection,
} from './style';
import useAuthentication, { AuthErrorCodes } from '../../hooks/useAuthentication';

// TYPE ------------------------------------------------------------

type LoginFormProps = {
  setRegister: () => void
  setForgottenPassword: () => void
};

// COMPONENTS ------------------------------------------------------------

function LoginForm({ setRegister, setForgottenPassword }: LoginFormProps) {
  const { login } = useAuthentication();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submit = useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    const loginError = await login({ email, password });
    if (loginError) {
      if (loginError === AuthErrorCodes.INVALID_AUTH) {
        setError('Wrong email and/or password');
        // TODO manage others errors
      } else {
        setError(loginError);
      }
    } else {
      // TODO popup success
    }
    setLoading(false);
  }, [login, email, password, setLoading, setError]);

  return (
    <Container>
      <H1>Welcome</H1>
      <FormContainer onSubmit={submit}>
        { error && <Error label={error} />}
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          disabled={loading}
          state={error ? 'error' : undefined}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
          disabled={loading}
          state={error ? 'error' : undefined}
        />
        <Spacer />
        <Button
          onClick={submit}
          disabled={loading || email === '' || password === ''}
          type="submit"
          label="Login"
          loading={loading}
        />
      </FormContainer>
      <OtherOptionSection>
        <Button
          variant="text"
          label="Forgot your password ?"
          onClick={setForgottenPassword}
        />
        <Button
          variant="text"
          label="Create an account"
          onClick={setRegister}
        />
      </OtherOptionSection>
    </Container>
  );
}

export default LoginForm;
