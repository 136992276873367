import { useMemo } from 'react';
import db from '../utils/db';
import { useBaseFirestoreItem } from './useBaseFirestore';
import * as Collections from '../common/Collections';

type UseOverlayState = {
  state: 'loading'
} | {
  state: 'error'
  error: string
} | {
  state: 'success'
  overlay: Collections.OverlayDocument
  privateKey?: string
  config?: Record<string, any>
};

const useOverlay = (workspaceId: string, overlayId: string): UseOverlayState => {
  const overlayRef = useMemo(() => db.overlay(workspaceId, overlayId), [workspaceId, overlayId]);
  const overlayPrivateKeyRef = useMemo(() => db.overlayPrivateKey(workspaceId, overlayId), [workspaceId, overlayId]);
  const overlayConfigRef = useMemo(() => db.overlayConfig(workspaceId, overlayId), [workspaceId, overlayId]);

  const {
    loading: overlayLoading, error: overlayError, item: overlay,
  } = useBaseFirestoreItem(overlayRef);

  const {
    loading: overlayPrivateKeyLoading, error: overlayPrivateKeyError, item: overlayPrivateKey,
  } = useBaseFirestoreItem(overlayPrivateKeyRef);

  const {
    loading: overlayConfigLoading, error: overlayConfigError, item: overlayConfig,
  } = useBaseFirestoreItem(overlayConfigRef);

  if (overlayLoading || overlayPrivateKeyLoading || overlayConfigLoading) {
    return {
      state: 'loading',
    };
  }

  if (overlayError || overlayPrivateKeyError || overlayConfigError || !overlay) {
    return {
      state: 'error',
      error: overlayError || overlayPrivateKeyError || overlayConfigError || 'Overlay not found',
    };
  }

  return {
    state: 'success',
    overlay,
    privateKey: overlayPrivateKey?.privateKey,
    config: overlayConfig?.config,
  };
};

export default useOverlay;
