import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { EmailIsValid, PasswordIsValid } from '../../utils/validation';
import { Button, TextField } from '../atoms';
import CopyToClipboard from '../common/CopyToClipboard';
import Error from './AuthenticationError';
import {
  Container,
  H1,
  FormContainer,
  Spacer,
  OtherOptionSection,
  ParagraphContainer,
  Mail,
  P,
} from './style';
import useAuthentication, { AuthErrorCodes } from '../../hooks/useAuthentication';

// STYLED ------------------------------------------------------------

const ParagraphContainerWithMargin = styled(ParagraphContainer)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const MailUnderline = styled(Mail)`
  color: ${({ theme }) => (theme.gray[200])};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// TYPE ------------------------------------------------------------

type RegisterFormProps = {
  setLogin: () => void
};

// COMPONENTS ------------------------------------------------------------

function RegisterForm({ setLogin }: RegisterFormProps) {
  const { register } = useAuthentication();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [betaKey, setBetaKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const errorEmail: string | undefined = useMemo(() => {
    if (email.length > 0 && !EmailIsValid(email)) {
      return '*bad email format';
    }
    return undefined;
  }, [email]);

  const errorPassword: string | undefined = useMemo(() => {
    if (password.length > 0) {
      return PasswordIsValid(password);
    }
    return undefined;
  }, [password]);

  const errorPasswordConf: string | undefined = useMemo(() => {
    if (passwordConf.length > 0 && password !== passwordConf) {
      return '*passwords need to match';
    }
    return undefined;
  }, [password, passwordConf]);

  const errorBetaKey: string | undefined = useMemo(() => {
    if (betaKey.length > 0 && !betaKey.match(/^[A-Z0-9]{16}$/)) {
      return '*valid Beta Key required';
    }
    return undefined;
  }, [betaKey]);

  const buttonEnabled = useMemo(() => (!loading
      && email.length && password.length && passwordConf.length && betaKey.length
      && !errorEmail && !errorPassword && !errorPasswordConf && !errorBetaKey
  ), [loading, email, password, betaKey, passwordConf, errorEmail, errorPassword, errorPasswordConf, errorBetaKey]);

  const submit = useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!buttonEnabled) return;
    setLoading(true);
    const registerError = await register({ email, password, betaKey });
    if (registerError) {
      if (registerError === AuthErrorCodes.EMAIL_EXISTS) {
        setError('Email already exists');
        // TODO manage others errors
      } else {
        setError(registerError);
      }
    } else {
      // TODO popup success
    }
    setLoading(false);
  }, [buttonEnabled, email, password, betaKey]);

  return (
    <Container>
      <H1>Register</H1>
      <FormContainer onSubmit={submit}>
        { error && <Error label={error} />}
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          disabled={loading}
          info={errorEmail}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={setPassword}
          disabled={loading}
          info={errorPassword}
        />
        <TextField
          label="Confirm"
          type="password"
          value={passwordConf}
          onChange={setPasswordConf}
          disabled={loading}
          info={errorPasswordConf}
        />
        <Spacer />
        <ParagraphContainerWithMargin>
          <P>
            We are currently in closed beta, feel free to request access by sending us an email.
          </P>
          <CopyToClipboard toCopy="iotvapp.contact@gmail.com">
            <MailUnderline>iotvapp.contact@gmail.com</MailUnderline>
          </CopyToClipboard>
        </ParagraphContainerWithMargin>
        <TextField
          label="BetaKey"
          type="text"
          value={betaKey}
          onChange={(b) => setBetaKey(b.trim().substring(0, 16).toUpperCase())}
          disabled={loading}
          info={errorPasswordConf}
        />
        <Spacer />
        <Button
          disabled={!buttonEnabled}
          type="submit"
          label="Sign Up"
          loading={loading}
        />
      </FormContainer>
      <OtherOptionSection>
        <Button
          variant="text"
          label="Already have an account"
          onClick={setLogin}
        />
      </OtherOptionSection>
    </Container>
  );
}

export default RegisterForm;
