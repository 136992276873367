import React from 'react';
import styled, { css } from 'styled-components';

// STYLED ------------------------------------------------------------

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
`;

const SplitPageContentContainer = styled.div`
  flex: 1;
  max-width: 1350px;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const ContentBox = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.gray[800]};
  padding: 64px;
  gap: 32px;
  flex-direction: column;
  overflow-x: hidden;
  align-items: center;
`;

export const ContentEmpty = styled(ContentBox)`
  border-left: 4px solid ${({ theme }) => (theme.gray[800])};
  background-color: ${({ theme }) => (theme.gray[900])};
  color: ${({ theme }) => (theme.gray[600])};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`;

const NavContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 16px;
`;

const NavBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  :hover {
    overflow-y: auto;
  }
`;

export const NavSection = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const NavTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.gray[600]};
  padding: 8px;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 800;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 8px;
`;

export const NavSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.gray[800]};
  border-radius: 1px;
`;

export const CellBase = css`
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  transition: all 150ms;
  :focus,
  :hover {
      cursor: pointer;
  }
  p {
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 100%;
    font-size: 16px;
    font-weight: 700;
  }
  user-select: none;
`;

// TYPE ------------------------------------------------------------

type Props = {
  children?: JSX.Element | JSX.Element[] | string | boolean | null;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

export function SplitPageNav({ children, style } : Props) {
  return (
    <NavContainer style={style}>
      <NavBox>
        {children}
      </NavBox>
    </NavContainer>
  );
}

export function SplitPageContent({ children, style } : Props) {
  return (
    <SplitPageContentContainer style={style}>
      {children}
    </SplitPageContentContainer>
  );
}

export default function SplitPage({ children, style } : Props) {
  return (
    <PageContainer style={style}>
      {children}
    </PageContainer>
  );
}
