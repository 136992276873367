/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Minus({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 12C2 10.3431 3.34315 9 5 9H19C20.6569 9 22 10.3431 22 12C22 13.6569 20.6569 15 19 15H5C3.34315 15 2 13.6569 2 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Minus;
