import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Subtitle } from '../common';
import Button from '../Button';
import { StateColor, UIColor } from '../../../theme';
import { Cross } from '../../icons';

// STYLED ------------------------------------------------------------

const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 8px;
  ${Subtitle} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    padding: 0 8px;
    align-self: stretch;
  }
  align-items: center;
  padding: 8px;
  border-bottom: 2px solid ${({ theme }) => (theme.gray[900])};
`;

const CloseButton = styled(Button)`
  color: ${({ theme }) => (theme.gray[500])};
  background-color: transparent;
`;

// TYPE ------------------------------------------------------------

type Props = {
  title: string;
  style?: React.CSSProperties;
  onClose?: () => void;
  color?: UIColor | StateColor;
};

// COMPONENTS ------------------------------------------------------------

export default function Header({
  style, onClose, title, color = 'error',
} : Props) {
  return (
    <Container style={style}>
      <Subtitle>{title}</Subtitle>
      <CloseButton onClick={onClose} variant="tonal" color={color} icon={<Cross size={22} />} />
    </Container>
  );
}
