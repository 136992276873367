export function EmailIsValid(mail : string) {
  // eslint-disable-next-line max-len
  return !!mail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function PasswordIsValid(password : string) {
  let error = '';
  if (password.length < 8) {
    error += '\n - 8 characters';
  }
  if (!/[A-Z]/.test(password)) {
    error += '\n - 1 uppercase letter';
  }
  if (!/[a-z]/.test(password)) {
    error += '\n - 1 lowercase letter';
  }
  if (!/[1-9]/.test(password)) {
    error += '\n - 1 number';
  }
  if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
    error += '\n - 1 special character';
  }
  if (error.length <= 0) {
    return undefined;
  }
  error = `*Password must contain at least:${error}`;
  return error;
}
