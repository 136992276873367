import { useMemo } from 'react';
import db from '../utils/db';
import { useBaseFirestoreItem } from './useBaseFirestore';
import * as Collections from '../common/Collections';

type UseOverlayDataState<TConfig extends Record<string, any>, TData extends Record<string, any>> = {
  state: 'loading'
} | {
  state: 'error'
  error: string
} | {
  state: 'success'
  overlayData: Collections.OverlayDataDocument<TConfig, TData>
};

const useOverlayData = <TConfig extends Record<string, any>, TData extends Record<string, any>>(privateKey: string): UseOverlayDataState<TConfig, TData> => {
  const overlayDataRef = useMemo(() => db.overlayData<TConfig, TData>(privateKey), [privateKey]);

  const {
    loading: overlayDataLoading, error: overlayDataError, item: overlayData,
  } = useBaseFirestoreItem(overlayDataRef);

  if (overlayDataLoading) {
    return {
      state: 'loading',
    };
  }

  if (overlayDataError || !overlayData) {
    return {
      state: 'error',
      error: overlayDataError || 'Overlay Data not found',
    };
  }

  return {
    state: 'success',
    overlayData,
  };
};

export default useOverlayData;
