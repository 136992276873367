import React from 'react';
import ForgottenPasswordForm from '../components/authentication/ForgottenPasswordForm';
import RegisterForm from '../components/authentication/RegisterForm';
import LoginForm from '../components/authentication/LoginForm';
import AuthPage, { AnimatedForm } from '../components/authentication/AuthPage';
import useWindowSize from '../hooks/useWindowSize';

// COMPONENTS ------------------------------------------------------------

function AuthenticationForm({ isMobile }: { isMobile: boolean }) {
  const [form, setForm] = React.useState<'LOGIN' | 'REGISTER' | 'FORGOTTEN_PASSWORD'>('LOGIN');
  const setLogin = React.useCallback(() => setForm('LOGIN'), [setForm]);
  const setRegister = React.useCallback(() => setForm('REGISTER'), [setForm]);
  const setForgottenPassword = React.useCallback(() => setForm('FORGOTTEN_PASSWORD'), [setForm]);

  switch (form) {
    case 'FORGOTTEN_PASSWORD':
      return (
        <AnimatedForm id="forgotten-password" isMobile={isMobile}>
          <ForgottenPasswordForm setLogin={setLogin} />
        </AnimatedForm>
      );
    case 'REGISTER':
      return (
        <AnimatedForm id="register" isMobile={isMobile}>
          <RegisterForm setLogin={setLogin} />
        </AnimatedForm>
      );
    default: // LOGIN
      return (
        <AnimatedForm id="sign-in" isMobile={isMobile}>
          <LoginForm setRegister={setRegister} setForgottenPassword={setForgottenPassword} />
        </AnimatedForm>
      );
  }
}

function Authentication() {
  const windowSize = useWindowSize();

  return (
    <AuthPage windowSize={windowSize}>
      <AuthenticationForm isMobile={windowSize.width < 850} />
    </AuthPage>
  );
}

export default Authentication;
