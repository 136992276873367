import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  Outlet, useParams,
} from 'react-router-dom';
import useWorkspace from '../hooks/useWorkspace';
import { Loader, ButtonLink } from '../components/atoms';
import NavBar, {
  NavBarLink,
  NavBarLinkSkeleton,
  NavBarWorkspace,
  NavBarWorkspaceSkeleton,
} from '../components/common/NavBar';
import SplitPage, {
  SplitPageContent,
  SplitPageNav,
  ContentEmpty,
} from '../components/common/SplitPage';
import {
  Gear, Focus, Box, Error as ErrorIcon, Library,
} from '../components/icons';
import OverlayList, { OverlayCell, OverlayListSkeleton } from '../components/workspace/OverlayList';
import * as Collections from '../common/Collections';
import { WorkspaceOutletContext } from '../types/OutletTypes';

// STYLED ------------------------------------------------------------

const ContentError = styled(ContentEmpty)`
  border-color: ${({ theme }) => theme.states.error[500]};
  color: ${({ theme }) => theme.states.error[400]};
  flex-direction: column;
  text-transform: none;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  p {
    line-height: 140%;
    text-align: center;
  }
  svg {
    color: ${({ theme }) => theme.states.error[500]};
  }
`;

const OverlayBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  gap: 32px;
  flex: 1;
  min-height: 200px;
  overflow-y: auto;
`;

// TYPE ------------------------------------------------------------

type ListProps = {
  workspaceId : string;
  overlays : Collections.OverlayDocument[];
  selected : string | undefined;
};

// COMPONENTS ------------------------------------------------------------

function WorkspaceOverlayList({
  workspaceId, overlays, selected,
} :ListProps) {
  return (
    <OverlayList
      icon={<Box size={20} />}
      label="Overlays"
    >
      <>
        {overlays.map((overlay) => (
          <OverlayCell
            label={overlay.name}
            color={overlay.color}
            selected={selected === overlay.overlayId}
            to={`/workspace/${workspaceId}/overlay/${overlay.overlayId}`}
            key={overlay.overlayId}
          />
        ))}
        {overlays.length <= 0 && (<div>Go to Library to create an overlay</div>)}
      </>
    </OverlayList>
  );
}

function Workspace() {
  const { workspaceId, overlayId } = useParams();
  const workspaceResult = useWorkspace(workspaceId!);
  const outletContext = useMemo<WorkspaceOutletContext>(() => ({
    workspaceId: workspaceId!,
    workspace: workspaceResult.state === 'success' ? workspaceResult.workspace : undefined,
    overlayId,
  }), [workspaceId, overlayId, workspaceResult]);

  if (workspaceResult.state !== 'success') {
    return (
      <SplitPage>
        <SplitPageNav>
          <NavBar style={{ width: '100%' }}>
            <NavBarWorkspaceSkeleton />
            <NavBarLinkSkeleton />
            <NavBarLinkSkeleton />
            <NavBarLinkSkeleton />
          </NavBar>
          <OverlayBox>
            <OverlayListSkeleton />
          </OverlayBox>
        </SplitPageNav>
        <SplitPageContent>
          <>
            {workspaceResult.state === 'error' && (
            <ContentError>
              <ErrorIcon size={64} />
              <p>Oups, an error append while loading your workspace !</p>
              <p>{workspaceResult.error}</p>
              <ButtonLink
                to="/"
                label="back to hub"
                variant="tonal"
                style={{ marginTop: 8, width: 'auto' }}
              />
            </ContentError>
            )}
            {workspaceResult.state === 'loading' && <ContentEmpty><Loader>Loading workspace...</Loader></ContentEmpty>}
          </>
        </SplitPageContent>
      </SplitPage>
    );
  }
  const { workspace, overlays } = workspaceResult;

  return (
    <SplitPage>
      <SplitPageNav>
        <NavBar>
          <NavBarWorkspace
            name={workspace.name}
            image={workspace.twitch?.profileImageUrl}
            to={`/workspace/${workspaceId}`}
          />
          <NavBarLink
            icon={<Gear size={20} />}
            label="settings"
            to={`/settings?workspace=${workspaceId}`}
          />
          <NavBarLink
            icon={<Library size={20} />}
            label="library"
            to={`/workspace/${workspaceId}/library`}
          />
          <NavBarLink
            icon={<Focus size={20} />}
            label="open focus"
            to={`/workspace/${workspaceId}/focus`}
          />
        </NavBar>
        <OverlayBox>
          <WorkspaceOverlayList
            workspaceId={workspaceId!}
            overlays={overlays}
            selected={overlayId}
          />
        </OverlayBox>
      </SplitPageNav>
      <SplitPageContent>
        <Outlet context={outletContext} key={overlayId} />
      </SplitPageContent>
    </SplitPage>
  );
}

export default Workspace;
