import React, { useRef } from 'react';
import styled from 'styled-components';
import { ColorCell, InputContainer, InputInfo } from '../atoms';

// STYLED ------------------------------------------------------------

const OriginalInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
`;

const ColorCellContainerBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.gray[600]};
  border-radius: 8px;
  transition: all 150ms;
`;

const ColorCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.gray[500]};
  font-weight: 700;
  height: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 100%;
  font-family: 'Public Sans', sans-serif;
  pointer-events: none;
  transition: transform 300ms;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  transition: all 150ms;
  padding: 0 4px;
`;

const Container = styled.button`
  position: relative;
  border: none;
  padding: 0;
  background-color: transparent;
  padding: 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.gray[900]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  :focus,
  :hover {
    cursor: 'pointer';
    ${ColorCellContainerBorder} {
      border: 2px solid transparent;
    }
    ${Label} {
      color: ${({ theme }) => theme.gray[400]};
    }
  }
`;

// TYPE ------------------------------------------------------------

type Props = {
  label : string;
  value : string;
  array : string[];
  onChange : (value : string) => void;
  disabled? : boolean;
  style? : React.CSSProperties;
  boxStyle? : React.CSSProperties;
  info? : string;
};

// COMPONENTS ------------------------------------------------------------

export default function ColorArray({
  label, value, array, onChange, disabled, style, boxStyle, info,
} : Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const openPicker = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <InputContainer style={style}>
      <Container onClick={openPicker} disabled={disabled} style={boxStyle}>
        <Label>{label}</Label>
        <OriginalInput
          type="color"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          ref={inputRef}
        />
        <ColorCellContainer>
          {array.map((color, index) => (
            <ColorCell
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={color}
              disabled
              style={{ borderRadius: 0, border: 'none', flex: 1 }}
            />
          ))}
          <ColorCellContainerBorder />
        </ColorCellContainer>
      </Container>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}
