import React, { useMemo } from 'react';
import styled from 'styled-components';
import { UIColor } from '../../theme';
import {
  Button,
  InputContainer, InputInfo, Label,
} from '../atoms';
import { Bin, Edit, Plus } from '../icons';
import { CustomRivenziPollEmote } from '../../common/Overlays';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
`;

const ButtonSection = styled.div`
  align-items: center;
  display: none;
`;

const CellContainer = styled.div`
  height: 44px;
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 150ms;
  gap: 8px;
  background-color: ${({ theme }) => (theme.gray[900])};
  ${Label} {
    flex: 1;
    text-align: left;
    color: ${({ theme }) => (theme.gray[500])};
  }
  &:hover {
    background-color: ${({ theme }) => (theme.gray[800])};
    padding: 0 0 0 16px;
    ${ButtonSection}  {
      display: flex  ;
    }
  }
`;

const InlineButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => (theme.gray[500])};
  border-radius: 0;
`;

const EmoteBox = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButton = styled(Button)<{ variant?: 'filled' | 'tonal'; color: UIColor }>`
  background-color: ${({ theme, variant, color }) => (variant === 'filled' ? theme.getColor(color, 500) : theme.gray[900])};
  width: 100%;
  border-radius: 0;
  > p {
    flex: 1;
    text-align: left;
    text-transform: none;
  }
`;

// TYPE ------------------------------------------------------------

type TextFieldProps = {
  color?: UIColor;
  style?: React.CSSProperties;
  info?: string;
  onAdd: () => void;
  children: React.ReactNode;
};

type CellProps = {
  label : string;
  onRemove: () => void;
  onEdit: () => void;
  emote: CustomRivenziPollEmote.Emote;
  disabled?: boolean;
};

// COMPONENTS ------------------------------------------------------------

function Cell({
  label, onRemove, onEdit, emote, disabled,
} : CellProps) {
  return (
    <CellContainer>
      <EmoteBox>
        <img
          src={`https://static-cdn.jtvnw.net/emoticons/v2/${emote.id}/${emote.isAnimated ? 'animated' : 'static'}/light/1.0`}
          alt={emote.name}
          style={{ width: '100%', height: '100%' }}
        />
      </EmoteBox>
      <Label>{label}</Label>
      <ButtonSection>
        <InlineButton onClick={onEdit} icon={<Edit size={22} />} color="default" variant="tonal" disabled={disabled} />
        <InlineButton onClick={onRemove} icon={<Bin size={22} />} color="error" variant="tonal" disabled={disabled} />
      </ButtonSection>
    </CellContainer>
  );
}

function EmoteList({
  info,
  color = 'default',
  style,
  onAdd,
  children,
} : TextFieldProps) {
  const count = React.Children.count(children);
  const addLabel = useMemo(() => {
    switch (count) {
      case 0:
        return 'Choose an emote';
      case 1:
        return 'Add one more';
      default:
        return 'Add';
    }
  }, [count]);

  return (
    <InputContainer style={style}>
      <Container>
        {children}
        <AddButton
          icon={<EmoteBox><Plus size={20} /></EmoteBox>}
          label={addLabel}
          variant={count > 1 ? 'tonal' : 'filled'}
          onClick={onAdd}
          color={color}
        />
      </Container>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

EmoteList.Container = EmoteList;
EmoteList.Cell = Cell;

export default EmoteList;
