import { execCallable } from '../utils/callable';
import {
  generateTwitchUrlFunctionName,
  GenerateTwitchUrlInput,
  GenerateTwitchUrlOutput,
} from '../common/Actions';

const generateTwitchUrl = (input: { state: string }) => execCallable<GenerateTwitchUrlInput, GenerateTwitchUrlOutput>(
  generateTwitchUrlFunctionName,
  {
    state: input.state,
    origin: window.location.origin,
  },
);

export default generateTwitchUrl;
