/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Copy({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="6" width="13" height="16" rx="3" fill="currentColor" />
      <rect x="9" y="3" width="11" height="14" rx="2" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

export default Copy;
