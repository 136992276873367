import React from 'react';
import styled from 'styled-components';
import { OverlayConfigProps } from '../Overlay';
import { CustomRivenziRugby } from '../../../common/Overlays';
import updateOverlayConfig from '../../../actions/updateOverlayConfig';
import {
  Button, TextField, HStack, Label, P, Separator, Subtitle, VStack, Switch, Radio, NumberInput, TextArea, Modal,
} from '../../atoms';
import getDayDate from '../../../utils/getDayDate';
import { searchMatchForDate, SearchMatchResult } from './api';
import { Calendar } from '../../icons';
import { contrastColor, darkenColor } from './ColorVariation';
import BottomNotification from '../../molecules/BottomNotification';
import ColorArray from '../../molecules/ColorArray';
import InfoBox from '../../molecules/InfoBox';

const TeamContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.gray[900]};
  border-radius: 16px;
  width: 430px;
  color: ${({ theme }) => theme.gray[600]};
  font-size: 14px;
  font-weight: 700;

  > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    > p {
      font-size: 16px;
      font-weight: 700;
      flex: 1;
      color: ${({ theme }) => theme.gray[100]};
    }
  }
`;

function FormMatch(
  {
    rapidApiKey, matchInfo, setMatch, loading: loadingForm,
  }:
  {
    rapidApiKey: string
    matchInfo: CustomRivenziRugby.Config['match'] | undefined
    setMatch: (match: CustomRivenziRugby.Config['match'] | undefined) => void,
    loading: boolean
  },
) {
  const [visible, setVisible] = React.useState(false);
  const [date, setDate] = React.useState<string>(getDayDate(new Date()));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [searchResult, setSearchResult] = React.useState<SearchMatchResult | undefined>(undefined);
  const [selectedMatch, setSelectedMatch] = React.useState<CustomRivenziRugby.Config['match'] | undefined>(undefined);

  const handleCancel = React.useCallback(() => {
    setVisible(false);
    setSearchResult(undefined);
    setSelectedMatch(undefined);
    setLoading(false);
    setError(undefined);
    setDate(getDayDate(new Date()));
  }, []);

  const handleSave = React.useCallback(() => {
    if (!selectedMatch) return;
    setMatch(selectedMatch);
    handleCancel();
  }, [selectedMatch, handleCancel]);

  const handleSearch = React.useCallback(async () => {
    setLoading(true);
    setError(undefined);
    const res = await searchMatchForDate({ date, rapidApiKey });
    if (!res.ok) {
      setError(res.error);
    } else {
      setSearchResult(res.data);
    }
    setLoading(false);
  }, [date, rapidApiKey]);

  const displayRequestUsage = React.useMemo((): string => (
    `You have ${searchResult?.remainingRequests} / ${searchResult?.totalRequests} requests remaining this month.`
  ), [searchResult]);

  return (
    <HStack>
      <Label>Choose a match</Label>
      <P>Click on the match you want to follow.</P>
      {matchInfo
        ? (
          <VStack>
            <TeamContainer>
              <div>
                <p>{matchInfo.homeName}</p>
                VS
                <p>{matchInfo.awayName}</p>
              </div>
            </TeamContainer>
            <Button
              onClick={() => setVisible(true)}
              icon={<Calendar />}
              variant="tonal"
              style={{ height: '100%' }}
              disabled={loadingForm || rapidApiKey === ''}
            />
          </VStack>

        )
        : (
          <Button
            label="Search a match"
            style={{ width: 430, height: 94 }}
            onClick={() => setVisible(true)}
            icon={<Calendar />}
            disabled={loadingForm || rapidApiKey === ''}
          />
        )}
      <Modal.Container visible={visible} onClose={handleCancel}>
        <HStack>
          <Subtitle>Select a date to search a match</Subtitle>
          <VStack>
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} disabled={loading} />
            <Button label="Search" onClick={handleSearch} loading={loading} />
          </VStack>
          {error && <P color="red">{error}</P>}
          {searchResult && (
            <HStack>
              {displayRequestUsage}
              {
                searchResult.matches.map((match) => (
                  <VStack key={match.id}>
                    <input
                      type="radio"
                      id={match.id}
                      value={match.id}
                      checked={selectedMatch?.id === match.id}
                      onChange={() => setSelectedMatch(match)}
                    />
                    <label>{`${match.homeName} vs ${match.awayName}, ${new Date(match.date).toISOString()}`}</label>
                  </VStack>
                ))
              }
            </HStack>
          )}
        </HStack>
        <VStack>
          <Button
            onClick={handleCancel}
            label="Cancel"
            color="default"
            variant="tonal"
          />
          <Button
            onClick={handleSave}
            label="Save"
            disabled={!selectedMatch}
          />
        </VStack>
      </Modal.Container>
    </HStack>
  );
}

function FormNames({
  names, match, setNames, loading,
}: {
  names: CustomRivenziRugby.Config['names']
  match: CustomRivenziRugby.Config['match'] | undefined
  setNames: (names: CustomRivenziRugby.Config['names']) => void
  loading: boolean
}) {
  return (
    <HStack>
      <Label>Team name override</Label>
      <P>Leave blank to use the default team name.</P>
      <VStack>
        <TextField
          label="Home team name"
          value={names.home}
          onChange={(home: string) => setNames({ ...names, home })}
          disabled={loading}
          placeholder={match?.homeName}
        />
        <P>vs</P>
        <TextField
          label="Away team name"
          value={names.away}
          onChange={(away: string) => setNames({ ...names, away })}
          disabled={loading}
          placeholder={match?.awayName}
        />
      </VStack>
    </HStack>
  );
}

function FormColorsWithAutoVariation({
  colors, setColors, loading,
}: {
  colors: CustomRivenziRugby.Config['colors']
  setColors: (colors: CustomRivenziRugby.Config['colors']) => void
  loading: boolean
}) {
  return (
    <>
      <ColorArray
        style={{ flex: 1 }}
        label="Team home"
        value={colors.home[1]}
        array={colors.home}
        onChange={(value: string) => setColors({
          ...colors,
          home: [contrastColor(value), value, darkenColor(value)],
        })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <P>vs</P>
      <ColorArray
        style={{ flex: 1 }}
        label="Team away"
        value={colors.away[1]}
        array={colors.away}
        onChange={(value: string) => setColors({
          ...colors,
          away: [contrastColor(value), value, darkenColor(value)],
        })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
    </>
  );
}

function FormColorsWithoutAutoVariation({
  colors, setColors, loading,
}: {
  colors: CustomRivenziRugby.Config['colors']
  setColors: (colors: CustomRivenziRugby.Config['colors']) => void
  loading: boolean
}) {
  return (
    <>
      <ColorArray
        style={{ flex: 1 }}
        label="Home text"
        value={colors.home[0]}
        array={[colors.home[0]]}
        onChange={(value: string) => setColors({ ...colors, home: [value, colors.home[1], colors.home[2]] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <ColorArray
        style={{ flex: 1 }}
        label="Home"
        value={colors.home[1]}
        array={[colors.home[1]]}
        onChange={(value: string) => setColors({ ...colors, home: [colors.home[0], value, colors.home[2]] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <ColorArray
        style={{ flex: 1 }}
        label="Home back"
        value={colors.home[2]}
        array={[colors.home[2]]}
        onChange={(value: string) => setColors({ ...colors, home: [colors.home[0], colors.home[1], value] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <P>vs</P>
      <ColorArray
        style={{ flex: 1 }}
        label="Away text"
        value={colors.away[0]}
        array={[colors.away[0]]}
        onChange={(value: string) => setColors({ ...colors, away: [value, colors.away[1], colors.away[2]] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <ColorArray
        style={{ flex: 1 }}
        label="Away"
        value={colors.away[1]}
        array={[colors.away[1]]}
        onChange={(value: string) => setColors({ ...colors, away: [colors.away[0], value, colors.away[2]] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
      <ColorArray
        style={{ flex: 1 }}
        label="Away back"
        value={colors.away[2]}
        array={[colors.away[2]]}
        onChange={(value: string) => setColors({ ...colors, away: [colors.away[0], colors.away[1], value] })}
        disabled={loading}
        boxStyle={{ borderRadius: '8px 8px 16px 16px' }}
      />
    </>
  );
}

function FormColors({
  colors, setColors, loading,
}: {
  colors: CustomRivenziRugby.Config['colors']
  setColors: (colors: CustomRivenziRugby.Config['colors']) => void
  loading: boolean
}) {
  const handleSwitch = () => {
    if (!colors.autoVariation) {
      setColors({
        home: [contrastColor(colors.home[1]), colors.home[1], darkenColor(colors.home[1])],
        away: [contrastColor(colors.away[1]), colors.away[1], darkenColor(colors.away[1])],
        secondary: colors.secondary,
        autoVariation: true,
      });
    } else {
      setColors({
        ...colors,
        autoVariation: false,
      });
    }
  };

  return (
    <>
      <HStack>
        <Label>Team colors</Label>
        <P>Disable “Auto variation” only if you are confident with your choices.</P>
        <HStack style={{ width: 'auto', maxWidth: '731px' }}>
          <Switch
            label="Auto variation"
            onChange={handleSwitch}
            disabled={loading}
            value={colors.autoVariation}
            style={{ width: '100%' }}
            boxStyle={{ borderRadius: '16px 16px 8px 8px' }}
          />
          <VStack>
            <VStack style={{ flex: 1, flexWrap: 'wrap' }}>
              {
            colors.autoVariation
              ? <FormColorsWithAutoVariation colors={colors} setColors={setColors} loading={loading} />
              : <FormColorsWithoutAutoVariation colors={colors} setColors={setColors} loading={loading} />
          }
            </VStack>
          </VStack>
        </HStack>
      </HStack>
      <Separator />
      <HStack>
        <Label>Other colors</Label>
        <P>Choose your colors carefully to avoid compromising clarity.</P>
        <ColorArray
          style={{ width: 150 }}
          label="Secondary"
          value={colors.secondary}
          array={[colors.secondary]}
          onChange={(value: string) => setColors({ ...colors, secondary: value })}
          disabled={loading}
        />
      </HStack>
    </>
  );
}

function FormAnimations({
  names, setNames, loading,
}: {
  names: CustomRivenziRugby.Config['names']
  setNames: (names: CustomRivenziRugby.Config['names']) => void
  loading: boolean
}) {
  return (
    <HStack>
      <Label>Events animation</Label>
      <P>Set animation styles for each team. It may affect : sound, duration and visual effects.</P>
      <VStack>
        <Radio<boolean>
          name="homeAnimation"
          value={names.homeIsHype}
          onChange={(value) => setNames({ ...names, homeIsHype: value })}
          disabled={loading}
          choices={[
            { label: 'Default', value: false },
            { label: 'Hype', value: true },
          ]}
        />
        <P>vs</P>
        <Radio<boolean>
          name="awayAnimation"
          value={names.awayIsHype}
          onChange={(value) => setNames({ ...names, awayIsHype: value })}
          disabled={loading}
          choices={[
            { label: 'Default', value: false },
            { label: 'Hype', value: true },
          ]}
        />
      </VStack>
    </HStack>
  );
}

function FormDisplayLoop(
  {
    statsLoopInterval, setStatsLoopInterval, statsDelay, setStatsDelay, loading,
  }: {
    statsLoopInterval: CustomRivenziRugby.Config['statsLoopInterval']
    setStatsLoopInterval: (statsLoopInterval: CustomRivenziRugby.Config['statsLoopInterval']) => void
    statsDelay: number
    setStatsDelay: (statsDelay: number) => void
    loading: boolean
  },
) {
  return (
    <HStack>
      <Label>Display loop</Label>
      <P>These settings control how often the stats section loop on pages.</P>
      <NumberInput
        label="Loop interval"
        valueType="Sec."
        onChange={(value) => setStatsLoopInterval(value)}
        value={statsLoopInterval}
        disabled={loading}
        min={1}
        max={360}
        increment={2}
      />
      <P>This setting control the delay before new stats are display on the overlay after fetch.</P>
      <NumberInput
        label="Delay"
        valueType="Sec."
        onChange={(value) => setStatsDelay(value)}
        value={statsDelay}
        disabled={loading}
        min={1}
        max={360}
        increment={2}
      />
    </HStack>
  );
}

function FormAPIUsage(
  { requests, setRequests, loading }: {
    requests: CustomRivenziRugby.Config['requests']
    setRequests: (requests: CustomRivenziRugby.Config['requests']) => void
    loading: boolean
  },
) {
  const usageSentence = React.useMemo(() => {
    if (!requests.loopEnabled) return 'You will only use one request on overlay load.';
    return `You will consume ${Math.floor(3600 / requests.loopInterval)} requests per hour.`;
  }, [requests.loopEnabled, requests.loopInterval]);

  return (
    <HStack>
      <Label>API Usage</Label>
      <P>These settings control how often the overlay will request data from the API.</P>
      <Switch
        label="Enable loop"
        onChange={() => setRequests({ ...requests, loopEnabled: !requests.loopEnabled })}
        value={requests.loopEnabled}
        disabled={loading}
      />
      <NumberInput
        label="Request interval"
        valueType="Sec."
        onChange={(value) => setRequests({ ...requests, loopInterval: value })}
        value={requests.loopInterval}
        disabled={loading}
        min={1}
        max={360}
        increment={1}
      />
      <InfoBox label={usageSentence} />
    </HStack>
  );
}

function FormStyleOverride(
  { styleOverride, setStyleOverride, loading }: {
    styleOverride: CustomRivenziRugby.Config['styleOverride']
    setStyleOverride: (styleOverride: CustomRivenziRugby.Config['styleOverride']) => void
    loading: boolean
  },
) {
  return (
    <HStack>
      <Label>Style override</Label>
      <P>Override default CSS.</P>
      <TextArea
        value={styleOverride || ''}
        onChange={(newValue) => setStyleOverride(newValue)}
        disabled={loading}
        placeholder="body { background-color: transparent; ..."
      />
    </HStack>
  );
}

export default function Config({ overlay, config }: OverlayConfigProps) {
  const overlayConfig = config as CustomRivenziRugby.Config;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const [rapidApiKey, setRapidApiKey] = React.useState(overlayConfig.rapidApiKey);
  const [match, setMatch] = React.useState<CustomRivenziRugby.Config['match'] | undefined>(overlayConfig.match);
  const [names, setNames] = React.useState<CustomRivenziRugby.Config['names']>(overlayConfig.names);
  const [colors, setColors] = React.useState<CustomRivenziRugby.Config['colors']>(overlayConfig.colors);
  const [statsLoopInterval, setStatsLoopInterval] = React.useState<CustomRivenziRugby.Config['statsLoopInterval']>(overlayConfig.statsLoopInterval);
  const [statsDelay, setStatsDelay] = React.useState<number>(overlayConfig.statsDelay || 0);
  const [requests, setRequests] = React.useState<CustomRivenziRugby.Config['requests']>(overlayConfig.requests);
  const [styleOverride, setStyleOverride] = React.useState<CustomRivenziRugby.Config['styleOverride']>(
    overlayConfig.styleOverride,
  );

  const rapidApiKeyHasDiff = React.useMemo(
    () => rapidApiKey !== overlayConfig.rapidApiKey,
    [rapidApiKey, overlayConfig.rapidApiKey],
  );
  const matchHasDiff = React.useMemo(
    () => match?.id !== overlayConfig.match?.id,
    [match?.id, overlayConfig.match?.id],
  );
  const namesHasDiff = React.useMemo(
    () => names.home !== overlayConfig.names.home
      || names.away !== overlayConfig.names.away
      || names.homeIsHype !== overlayConfig.names.homeIsHype
      || names.awayIsHype !== overlayConfig.names.awayIsHype,
    [
      names.home, names.away, names.homeIsHype, names.awayIsHype,
      overlayConfig.names.home, overlayConfig.names.away,
      overlayConfig.names.homeIsHype, overlayConfig.names.awayIsHype,
    ],
  );
  const colorsHasDiff = React.useMemo(
    () => colors.home.join() !== overlayConfig.colors.home.join()
      || colors.away.join() !== overlayConfig.colors.away.join()
      || colors.secondary !== overlayConfig.colors.secondary,
    [
      colors.home.join(), colors.away.join(), colors.secondary,
      overlayConfig.colors.home.join(), overlayConfig.colors.away.join(),
      overlayConfig.colors.secondary,
    ],
  );
  const statsLoopIntervalHasDiff = React.useMemo(
    () => statsLoopInterval !== overlayConfig.statsLoopInterval,
    [statsLoopInterval, overlayConfig.statsLoopInterval],
  );
  const statsDelayHasDiff = React.useMemo(
    () => statsDelay !== overlayConfig.statsDelay,
    [statsDelay, overlayConfig.statsDelay],
  );
  const requestsHasDiff = React.useMemo(
    () => requests.loopEnabled !== overlayConfig.requests.loopEnabled
      || requests.loopInterval !== overlayConfig.requests.loopInterval,
    [
      requests.loopEnabled, requests.loopInterval,
      overlayConfig.requests.loopEnabled, overlayConfig.requests.loopInterval,
    ],
  );
  const styleOverrideHasDiff = React.useMemo(
    () => styleOverride !== overlayConfig.styleOverride,
    [styleOverride, overlayConfig.styleOverride],
  );
  const hasDiff = React.useMemo(
    () => rapidApiKeyHasDiff
      || matchHasDiff
      || namesHasDiff
      || colorsHasDiff
      || statsLoopIntervalHasDiff
      || statsDelayHasDiff
      || requestsHasDiff
      || styleOverrideHasDiff,
    [rapidApiKeyHasDiff, matchHasDiff, namesHasDiff, colorsHasDiff, statsLoopIntervalHasDiff, statsDelayHasDiff, requestsHasDiff, styleOverrideHasDiff],
  );

  const handleCancel = React.useCallback(() => {
    setRapidApiKey(overlayConfig.rapidApiKey);
    setMatch(overlayConfig.match);
    setNames(overlayConfig.names);
    setColors(overlayConfig.colors);
    setStatsLoopInterval(overlayConfig.statsLoopInterval);
    setStatsDelay(overlayConfig.statsDelay || 0);
    setRequests(overlayConfig.requests);
    setStyleOverride(overlayConfig.styleOverride);
  }, [overlayConfig]);

  const handleSubmit = React.useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!hasDiff) {
      return;
    }
    setLoading(true);
    const res = await updateOverlayConfig<CustomRivenziRugby.Config>({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
      config: {
        rapidApiKey,
        match,
        names,
        colors,
        statsLoopInterval,
        statsDelay,
        requests,
        styleOverride,
      },
    });
    if (res.ok) {
      setError(undefined);
    } else {
      setError(res.error);
    }
    setLoading(false);
  }, [
    hasDiff, overlay.overlayId, overlay.workspaceId,
    rapidApiKey, match, names, colors, statsLoopInterval, statsDelay, requests, styleOverride,
  ]);

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <HStack>
        <Label>Required</Label>
        <P>
          You need to have an API subscription to
          {' '}
          <a href="https://rapidapi.com/sportcontentapi/api/rugby-live-data" target="_blank" rel="noreferrer">
            Rugby Live Data from Rapid API
          </a>
          {' '}
          to configure and use the layout.
        </P>
        <TextField
          label="RAPID api key"
          onChange={setRapidApiKey}
          value={rapidApiKey}
          disabled={loading}
          secured
        />
      </HStack>
      <Separator />
      <FormMatch
        rapidApiKey={rapidApiKey}
        matchInfo={match}
        setMatch={setMatch}
        loading={loading}
      />
      <Separator />
      <FormNames
        names={names}
        setNames={setNames}
        match={match}
        loading={loading}
      />
      <Separator />
      <FormColors
        colors={colors}
        setColors={setColors}
        loading={loading}
      />
      <Separator />
      <FormAnimations
        names={names}
        setNames={setNames}
        loading={loading}
      />
      <Separator />
      <FormDisplayLoop
        setStatsLoopInterval={setStatsLoopInterval}
        statsLoopInterval={statsLoopInterval}
        statsDelay={statsDelay}
        setStatsDelay={setStatsDelay}
        loading={loading}
      />
      <Separator />
      <FormAPIUsage
        requests={requests}
        setRequests={setRequests}
        loading={loading}
      />
      <Separator />
      <FormStyleOverride
        styleOverride={styleOverride}
        setStyleOverride={setStyleOverride}
        loading={loading}
      />
      <BottomNotification.Container show={hasDiff}>
        <BottomNotification.InlineNotification
          info="Careful, you have unsaved changes!"
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          confirmLabel="Save changes"
          cancelLabel="Reset"
          loading={loading}
        />
      </BottomNotification.Container>
      {error && <p>{error}</p>}
    </form>
  );
}
