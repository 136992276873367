import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import NeedAuthentication from './NeedAuthentication';
import NeedAnonymous from './NeedAnonymous';
import Hub from '../pages/Hub';
import Workspace from '../pages/Workspace';
import Overlay from '../pages/Overlay';
import Authentication from '../pages/Authentication';
import AuthenticationAction from '../pages/AuthenticationAction';
import AuthenticationTwitch from '../pages/AuthenticationTwitch';
import Test from '../pages/Test';
import Settings from '../pages/Settings';
import NewWorkspace from '../pages/NewWorkspace';
import { ContentBox, ContentEmpty } from '../components/common/SplitPage';
import Library from '../pages/Library';
import WorkspaceHome from '../pages/WorkspaceHome';

const needAuthentication = (Component: React.ComponentType) => (
  <NeedAuthentication>
    <Component />
  </NeedAuthentication>
);
const needAnonymous = (Component: React.ComponentType) => (
  <NeedAnonymous>
    <Component />
  </NeedAnonymous>
);

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={needAuthentication(Hub)} />
        <Route path="/settings" element={needAuthentication(Settings)} />
        <Route path="/library" element={needAuthentication(Library)} />
        <Route path="/get-started" element={needAuthentication(NewWorkspace)} />
        <Route path="/workspace/:workspaceId/focus" element={<div> Focus </div>} />
        <Route path="/workspace/:workspaceId/library" element={needAuthentication(Library)} />
        <Route path="/workspace/:workspaceId" element={needAuthentication(Workspace)}>
          <Route index element={<ContentBox><WorkspaceHome /></ContentBox>} />
          <Route path="overlay/:overlayId" element={<ContentBox><Overlay /></ContentBox>} />
          <Route path="*" element={<ContentEmpty>SELECT A TOOL TO EDIT</ContentEmpty>} />
        </Route>

        <Route path="/authentication" element={needAnonymous(Authentication)} />

        <Route path="/__/auth/action" element={<AuthenticationAction />} />
        <Route path="/__/auth/twitch" element={needAuthentication(AuthenticationTwitch)} />

        <Route path="/test" element={<Test />} />

        <Route path="*" element={<div>404</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
