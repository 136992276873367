import { execCallable } from '../utils/callable';
import {
  relinkTwitchFunctionName,
  RelinkTwitchInput,
  RelinkTwitchOutput,
} from '../common/Actions';

const relinkTwitch = (
  input: { workspaceId: string, code: string },
) => execCallable<RelinkTwitchInput, RelinkTwitchOutput>(
  relinkTwitchFunctionName,
  {
    workspaceId: input.workspaceId,
    code: input.code,
    origin: window.location.origin,
  },
);

export default relinkTwitch;
