/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Logout({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C5.34315 2 4 3.34315 4 5V19C4 20.6569 5.34315 22 7 22H16C17.6569 22 19 20.6569 19 19V15.2616L16.4843 17.662C16.2569 17.879 15.9547 18 15.6405 18C14.9654 18 14.4182 17.4527 14.4182 16.7777V14.6667C14.4182 14.2985 14.1197 14 13.7515 14H10C8.89543 14 8 13.1046 8 12C8 10.8954 8.89543 10 10 10H13.7515C14.1197 10 14.4182 9.70152 14.4182 9.33333V7.22233C14.4182 6.54725 14.9654 6 15.6405 6C15.9547 6 16.2569 6.12104 16.4843 6.33799L19 8.73844V5C19 3.34315 17.6569 2 16 2H7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6405 4.79741C16.2639 4.79741 16.8634 5.03754 17.3145 5.46793L21.9443 9.88564C23.1509 11.037 23.1509 12.963 21.9443 14.1144L17.3145 18.5321C16.8634 18.9625 16.2639 19.2026 15.6405 19.2026C14.3012 19.2026 13.2156 18.1169 13.2156 16.7777V15.2026H10C8.23126 15.2026 6.79741 13.7687 6.79741 12C6.79741 10.2313 8.23126 8.79741 10 8.79741H13.2156V7.22233C13.2156 5.88308 14.3012 4.79741 15.6405 4.79741ZM15.6405 6C15.9547 6 16.2569 6.12104 16.4843 6.33799L21.1141 10.7557C21.8242 11.4333 21.8242 12.5667 21.1141 13.2443L16.4843 17.662C16.2569 17.879 15.9547 18 15.6405 18C14.9654 18 14.4182 17.4527 14.4182 16.7777V14.6667C14.4182 14.2985 14.1197 14 13.7515 14H10C8.89543 14 8 13.1046 8 12C8 10.8954 8.89543 10 10 10H13.7515C14.1197 10 14.4182 9.70152 14.4182 9.33333V7.22233C14.4182 6.54725 14.9654 6 15.6405 6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Logout;
