/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Plus({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5C15 3.34315 13.6569 2 12 2C10.3431 2 9 3.34315 9 5L9 8C9 8.55228 8.55228 9 8 9H5C3.34315 9 2 10.3431 2 12C2 13.6569 3.34315 15 5 15H8C8.55228 15 9 15.4477 9 16L9 19C9 20.6569 10.3431 22 12 22C13.6569 22 15 20.6569 15 19V16C15 15.4477 15.4477 15 16 15H19C20.6569 15 22 13.6569 22 12C22 10.3431 20.6569 9 19 9H16C15.4477 9 15 8.55228 15 8V5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Plus;
