import styled from 'styled-components';

export const Separator = styled.div`
  min-height: 2px;
  background-color: ${({ theme }) => theme.gray[700]};
  border-radius: 1px;
  width: 100%;
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  font-weight: 800;
  line-height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.gray[400]};
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  width: 100%;
  color: ${({ theme }) => theme.gray[500]};
  > a {
    color: ${({ theme }) => theme.gray[500]};
    text-decoration: underline;
    transition: all 0.2s;
    :focus,
    :hover {
      color: ${({ theme }) => theme.ui.blue[500]};
    }
  }
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  color: ${({ theme }) => theme.gray[200]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const HStack = styled.div<{ layer? : number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-left: ${({ layer }) => (layer ? `${layer * 32}px` : '0px')};
`;

export const VStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  align-items: center;
  > p {
    width: auto;
  }
`;

export const FocusSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 32px 64px;
  width: calc(128px + 100%);
  background-color: ${({ theme }) => theme.gray[700]};
  align-items: center;
  ${Separator} {
    background-color: ${({ theme }) => theme.gray[600]};
  }
  > form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    align-items: center;
  }
`;
