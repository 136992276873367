import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import VerifyEmail from '../components/authentication/VerifyEmail';
import AuthPage from '../components/authentication/AuthPage';

// COMPONENTS ------------------------------------------------------------

function AuthenticationActionSwitch() {
  const { search } = useLocation();

  const { mode, oobCode } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      mode: params.get('mode') || undefined,
      oobCode: params.get('oobCode') || undefined,
      lang: params.get('lang') || undefined,
    };
  }, [search]);

  switch (mode) {
    case 'verifyEmail': return <VerifyEmail oobCode={oobCode} />;
    default: return (
      <div>
        <h1> Invalid Action Mode </h1>
        <Link to="/"> Go to home </Link>
      </div>
    );
  }
}

function AuthenticationAction() {
  const windowSize = useWindowSize();

  return (
    <AuthPage windowSize={windowSize}>
      <AuthenticationActionSwitch />
    </AuthPage>
  );
}

export default AuthenticationAction;
