import React, { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import {
  Loader, Label, Button, Separator,
} from '../components/atoms';
import { WorkspaceOutletContext } from '../types/OutletTypes';
import { generateTwitchUrl, unlinkTwitch, updateWorkspaceName } from '../actions';
import * as Collections from '../common/Collections';

const PageName = styled.input`
  width: 100%;
  font-size: 32px;
  background-color: transparent;
  color: white;
  font-weight: 700;
  &::placeholder {
    color: white;
  }
`;

const BaseContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  align-items: center;
`;

function WorkspaceHeader({ workspace }: { workspace: Collections.WorkspaceDocument }) {
  const [name, setName] = React.useState<string>(workspace.name);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setName(workspace.name);
  };

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    const res = await updateWorkspaceName({
      workspaceId: workspace.workspaceId,
      name,
    });
    if (!res.ok) {
      await handleCancel();
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <BaseContainer>
        <PageName
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Overlay name"
          type="text"
          disabled={loading}
        />
        {
          name !== workspace.name ? (
            <>
              <Button label="Save" type="submit" loading={loading} />
              <Button label="Cancel" type="button" onClick={handleCancel} variant="tonal" disabled={loading} />
            </>
          ) : null
        }
      </BaseContainer>
    </form>
  );
}

function WorkspaceHome() {
  const { workspaceId, workspace } = useOutletContext<WorkspaceOutletContext>();
  const [loadingUnlinkTwitch, setLoadingUnlinkTwitch] = useState(false);
  const [errorUnlinkTwitch, setErrorUnlinkTwitch] = useState<string | null>(null);
  const [loadingRelinkTwitch, setLoadingRelinkTwitch] = useState(false);
  const [errorRelinkTwitch, setErrorRelinkTwitch] = useState<string | null>(null);

  const handleUnlinkTwitch = useCallback(async () => {
    setLoadingUnlinkTwitch(true);
    setErrorUnlinkTwitch(null);
    const res = await unlinkTwitch({
      workspaceId,
    });
    if (!res.ok) {
      setErrorUnlinkTwitch(res.error);
    }
    setLoadingUnlinkTwitch(false);
  }, [workspaceId]);

  const handleRelinkTwitch = useCallback(async () => {
    setLoadingRelinkTwitch(true);
    setErrorRelinkTwitch(null);
    const res = await generateTwitchUrl({
      state: `relinkTwitch|${workspaceId}`,
    });
    if (!res.ok) {
      setErrorRelinkTwitch(res.error);
    } else {
      window.location.replace(res.data.url);
    }
    setLoadingRelinkTwitch(false);
  }, [workspaceId]);

  if (!workspace) {
    return <Loader />;
  }

  return (
    <>
      <WorkspaceHeader workspace={workspace} />
      <Separator />
      <Label>Twitch</Label>
      {
        workspace.twitch ? (
          <div>
            <p>
              Connected to:
              {' '}
              {workspace.twitch.displayName}
            </p>
            <img src={workspace.twitch.profileImageUrl} alt="Twitch Profile" />
            {errorUnlinkTwitch && <p>{errorUnlinkTwitch}</p>}
            <Button label="Unlink twitch account" onClick={handleUnlinkTwitch} loading={loadingUnlinkTwitch} />
          </div>
        ) : (
          <div>
            <p>Not connected to Twitch</p>
            {errorRelinkTwitch && <p>{errorRelinkTwitch}</p>}
            <Button label="Link a twitch account" onClick={handleRelinkTwitch} loading={loadingRelinkTwitch} />
          </div>
        )
      }
    </>
  );
}

export default WorkspaceHome;
