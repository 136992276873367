import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import { Button, Loader } from '../components/atoms';
import Page from '../components/common/Page';
import useInterval from '../hooks/useInterval';
import Error from '../components/authentication/AuthenticationError';
import {
  Container,
  H1,
  FormContainer,
  Spacer,
  OtherOptionSection,
  ParagraphContainer,
  Mail,
  P,
} from '../components/authentication/style';

// COMPONENTS ------------------------------------------------------------

function VerifyEmailPage({
  email, sendEmail, logout,
}: { email: string, sendEmail: () => Promise<string | undefined>, logout: () => void }) {
  const [lastEmailSent, setLastEmailSent] = React.useState<number>(Date.now() - 1000 * 60);
  const [remainingTime, setRemainingTime] = React.useState<number>(0);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const updateRemainingTime = React.useCallback(() => {
    const now = Date.now();
    const diff = now - lastEmailSent;
    const remaining = 60 - Math.floor(diff / 1000);
    setRemainingTime(remaining < 0 ? 0 : remaining);
  }, [lastEmailSent]);

  useInterval(updateRemainingTime, 1000);

  const sendVerificationEmail = React.useCallback(async () => {
    setLoading(true);
    setError(undefined);
    const errorSend = await sendEmail();
    if (errorSend) {
      setError(errorSend);
    } else {
      setLastEmailSent(Date.now());
    }
    setLoading(false);
  }, [sendEmail]);

  return (
    <Page>
      <Container>
        <H1>Verify your email</H1>
        <FormContainer>
          {error && <Error label={`Error while sending verification email: ${error} `} /> }
          <ParagraphContainer>
            <P>
              We have sent you an email,
              <br />
              check your Inbox and Spams at :
            </P>
          </ParagraphContainer>
          <Mail>{email}</Mail>
          <Spacer />
          <Button
            disabled={remainingTime !== 0 || loading}
            onClick={sendVerificationEmail}
            label={`Resend an Email ${remainingTime ? `(${remainingTime})` : ''}`}
            loading={loading}
          />
        </FormContainer>
        <OtherOptionSection>
          <Button
            variant="text"
            label="Logout"
            onClick={logout}
          />
        </OtherOptionSection>
      </Container>
    </Page>
  );
}

function NeedAuthentication({ children }: { children: JSX.Element }) {
  const {
    loading, user, sendVerificationEmail: sendEmail, logout,
  } = useAuthentication();

  if (loading) return <Page><Loader>Connecting...</Loader></Page>;

  const currentLocation = window.location.pathname + window.location.search;
  if (!user) return <Navigate to="/authentication" state={{ from: currentLocation }} replace />;

  if (!user.email) return <div> Invalid account </div>;

  if (!user.emailVerified) return <VerifyEmailPage email={user.email} sendEmail={sendEmail} logout={logout} />;

  return children;
}

export default NeedAuthentication;
