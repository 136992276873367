import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import overlays from '../components/overlays';
import createOverlay from '../actions/createOverlay';
import { Button } from '../components/atoms';
import * as Collections from '../common/Collections';

// COMPONENTS ------------------------------------------------------------

function OverlayCard({ workspaceId, overlay }: { workspaceId: string, overlay: typeof overlays[number] }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleCreateOverlay = React.useCallback(async () => {
    setLoading(true);
    const res = await createOverlay({
      workspaceId,
      type: overlay.type,
      color: Collections.overlayColors[Math.floor(Math.random() * (Collections.overlayColors.length - 1))],
    });
    if (res.ok) {
      navigate(`/workspace/${workspaceId}/overlay/${res.data.overlayId}`);
    } else {
      setError(res.error);
    }
    setLoading(false);
  }, [workspaceId, overlay.type, navigate]);

  return (
    <div>
      <h2>{overlay.displayName}</h2>
      <p>{overlay.description}</p>
      <Button type="button" onClick={handleCreateOverlay} loading={loading} label="Add overlay to workspace" />
      {error && <p>{error}</p>}
    </div>
  );
}

function Library() {
  const { workspaceId } = useParams();

  if (!workspaceId) {
    return (
      <div>
        <h1>Library</h1>
        <p>Workspace not found</p>
      </div>
    );
  }
  return (
    <div>
      <h1>Library</h1>
      <ul>
        {overlays.map((overlay) => (
          <li key={overlay.type}>
            <OverlayCard workspaceId={workspaceId} overlay={overlay} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Library;
