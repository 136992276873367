/* eslint-disable max-len */
import React from 'react';
import styled, { keyframes } from 'styled-components';

// STYLED ------------------------------------------------------------

const rotate = keyframes`
  0% {
    transform: rotate(0deg)
  }
  16.7% {
    transform: rotate(60deg)
  }
  33.4% {
    transform: rotate(120deg)
  }
  50.1% {
    transform: rotate(180deg)
  }
  66.8% {
    transform: rotate(240deg)
  }
  83.5% {
    transform: rotate(300deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const SVGRotating = styled.svg`
  animation-name: ${rotate};
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: step-end;
`;

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function MiniLoader({ size = 24, style } : IconProps) {
  return (
    <SVGRotating width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7258 8.01761C21.8631 7.50526 21.7912 6.95937 21.526 6.50001C21.2608 6.04066 20.824 5.70545 20.3116 5.56817C19.7993 5.4309 19.2533 5.50278 18.794 5.76798L16.1983 7.26663C15.9705 7.39679 15.7704 7.57052 15.6096 7.77794C15.448 7.98639 15.3292 8.22475 15.2601 8.4793C15.191 8.73385 15.1729 8.99955 15.2069 9.2611C15.2409 9.52266 15.3263 9.77492 15.4582 10.0033C15.5901 10.2318 15.7659 10.4318 15.9754 10.5921C16.1849 10.7523 16.4241 10.8695 16.6791 10.9369C16.934 11.0043 17.1999 11.0206 17.4612 10.9849C17.7213 10.9493 17.9718 10.8629 18.1984 10.7306L18.2017 10.7287L20.794 9.23201C21.2534 8.96678 21.5886 8.52995 21.7258 8.01761Z"
        fill="currentColor"
        fillOpacity="0.25"
      />
      <path
        d="M8.73583 15.5176C8.8731 15.0053 8.80124 14.4594 8.53603 14C8.27081 13.5407 7.83395 13.2054 7.32161 13.0682C6.80926 12.9309 6.26335 13.0028 5.80399 13.268L3.20839 14.7666C2.98049 14.8968 2.78043 15.0705 2.61961 15.2779C2.45801 15.4864 2.33923 15.7248 2.27013 15.9793C2.20102 16.2338 2.18294 16.4995 2.21694 16.7611C2.25094 17.0227 2.33634 17.2749 2.46822 17.5033C2.6001 17.7318 2.77587 17.9318 2.98539 18.0921C3.19491 18.2523 3.43405 18.3695 3.68905 18.4369C3.94405 18.5043 4.20986 18.5206 4.4712 18.4849C4.73135 18.4493 4.98194 18.3629 5.20867 18.2305L5.21175 18.2287L7.80403 16.732C8.26338 16.4668 8.59855 16.03 8.73583 15.5176Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M20.3116 18.4318C20.824 18.2946 21.2608 17.9594 21.526 17.5C21.7912 17.0407 21.8631 16.4947 21.7258 15.9824C21.5886 15.4701 21.2534 15.0332 20.794 14.768L18.196 13.268L18.1903 13.2647C17.7311 13.0036 17.1874 12.9348 16.6777 13.0732C16.168 13.2116 15.7338 13.5459 15.4697 14.0033C15.2056 14.4607 15.1332 15.0039 15.2682 15.5145C15.4032 16.0251 15.7346 16.4616 16.1902 16.7287L18.794 18.232C19.2534 18.4972 19.7993 18.5691 20.3116 18.4318Z"
        fill="currentColor"
        fillOpacity="0.4"
      />
      <path
        d="M7.32161 10.9318C7.83395 10.7946 8.27078 10.4594 8.53601 10C8.80121 9.54067 8.8731 8.99474 8.73583 8.48239C8.59855 7.97005 8.26336 7.53321 7.80401 7.26798L5.20839 5.76936C4.98173 5.63707 4.73124 5.55068 4.4712 5.51512C4.20987 5.47939 3.94405 5.4957 3.68905 5.56312C3.43405 5.63054 3.19491 5.74773 2.98539 5.90794C2.77586 6.06816 2.6001 6.26824 2.46822 6.49666C2.33634 6.72508 2.25094 6.97733 2.21694 7.23889C2.18294 7.50045 2.20102 7.76616 2.27013 8.0207C2.33923 8.27525 2.45801 8.51361 2.61961 8.72206C2.78041 8.92948 2.98045 9.10321 3.20832 9.23336L5.80401 10.732C6.26337 10.9972 6.80926 11.0691 7.32161 10.9318Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
      <path
        d="M13.4142 1.58579C13.0391 1.21071 12.5304 1 12 1C11.4696 1 10.9609 1.21071 10.5858 1.58579C10.2107 1.96086 10 2.46957 10 3V6C10 6.53043 10.2107 7.03914 10.5858 7.41421C10.9609 7.78929 11.4696 8 12 8C12.5304 8 13.0391 7.78929 13.4142 7.41421C13.7893 7.03914 14 6.53043 14 6V3C14 2.46957 13.7893 1.96086 13.4142 1.58579Z"
        fill="currentColor"
      />
      <path
        d="M13.4142 16.5858C13.0391 16.2107 12.5304 16 12 16C11.4696 16 10.9609 16.2107 10.5858 16.5858C10.2107 16.9609 10 17.4696 10 18V21C10 21.5304 10.2107 22.0391 10.5858 22.4142C10.9609 22.7893 11.4696 23 12 23C12.5304 23 13.0391 22.7893 13.4142 22.4142C13.7893 22.0391 14 21.5304 14 21V18C14 17.4696 13.7893 16.9609 13.4142 16.5858Z"
        fill="currentColor"
        fillOpacity="0.55"
      />
    </SVGRotating>
  );
}

export default MiniLoader;
