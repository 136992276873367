import React from 'react';
import * as FirebaseAuth from 'firebase/auth';
import { auth } from '../utils/firebaseConfig';
import notImplemented from '../utils/notImplemented';
import setBetaKeyEmail from '../actions/setBetaKeyEmail';

export type AuthenticationContextState = {
  loading: boolean;
  user: FirebaseAuth.User | null;
  register: (input: { email: string, password: string, betaKey: string }) => Promise<string | undefined>;
  login: (input: { email: string, password: string }) => Promise<string | undefined>;
  logout: () => Promise<string | undefined>;
  sendVerificationEmail: () => Promise<string | undefined>;
  sendPasswordResetEmail: (input: { email: string }) => Promise<string | undefined>;
  verifyEmail: (input: { oobCode: string }) => Promise<string | undefined>;
  getEmailFromResetPasswordCode: (input: { oobCode: string }) => Promise<string | undefined>;
};

export const AuthenticationContext = React.createContext<AuthenticationContextState>({
  loading: true,
  user: null,
  register: notImplemented,
  login: notImplemented,
  logout: notImplemented,
  sendVerificationEmail: notImplemented,
  sendPasswordResetEmail: notImplemented,
  verifyEmail: notImplemented,
  getEmailFromResetPasswordCode: notImplemented,
});

export function AuthenticationContextProvider({ children }: React.PropsWithChildren) {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState<FirebaseAuth.User | null>(null);

  React.useEffect(() => FirebaseAuth.onAuthStateChanged(auth, (userRes) => {
    setUser(userRes);
    setLoading(false);
  }), [setUser, setLoading]);

  const register = React.useCallback(async (
    input: { email: string, password: string, betaKey: string },
  ): Promise<string | undefined> => {
    if (!(await setBetaKeyEmail({ betaKey: input.betaKey, email: input.email })).ok) {
      return 'betaKey/failed-to-claim';
    }
    try {
      const res = await FirebaseAuth.createUserWithEmailAndPassword(auth, input.email, input.password);
      if (!res.user.emailVerified) await FirebaseAuth.sendEmailVerification(res.user);
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code === FirebaseAuth.AuthErrorCodes.INTERNAL_ERROR) {
        const { message } = e;
        // eslint-disable-next-line max-len
        if (message === 'Firebase: HTTP Cloud Function returned an error: {"error":{"message":"Email already exists","status":"PERMISSION_DENIED"}} (auth/internal-error).') {
          return FirebaseAuth.AuthErrorCodes.EMAIL_EXISTS;
        }
        // eslint-disable-next-line max-len
        if (message === 'Firebase: HTTP Cloud Function returned an error: {"error":{"message":"Beta Key required","status":"PERMISSION_DENIED"}} (auth/internal-error).') {
          return 'betaKey/already-used';
        }
      }
      if (e?.code) return e.code;
      return error.toString();
    }
  }, []);

  const login = React.useCallback(async (input: { email: string, password: string }): Promise<string | undefined> => {
    try {
      await FirebaseAuth.signInWithEmailAndPassword(auth, input.email, input.password);
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code) return e.code;
      return error.toString();
    }
  }, []);

  const logout = React.useCallback(async (): Promise<string | undefined> => {
    try {
      await auth.signOut();
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code) return e.code;
      return error.toString();
    }
  }, []);

  const sendVerificationEmail = React.useCallback(async (): Promise<string | undefined> => {
    try {
      if (!user) return 'You need to login first';
      await FirebaseAuth.sendEmailVerification(user);
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code) return e.code;
      return error.toString();
    }
  }, [user]);

  const sendPasswordResetEmail = React.useCallback(async (input: { email: string }): Promise<string | undefined> => {
    try {
      await FirebaseAuth.sendPasswordResetEmail(auth, input.email);
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code) return e.code;
      return error.toString();
    }
  }, []);

  const verifyEmail = React.useCallback(async (input: { oobCode: string }): Promise<string | undefined> => {
    try {
      await FirebaseAuth.applyActionCode(auth, input.oobCode);
      return undefined;
    } catch (error: any) {
      const e = error as FirebaseAuth.AuthError;
      if (e?.code) return e.code;
      return error.toString();
    }
  }, []);

  const getEmailFromResetPasswordCode = React.useCallback(async (
    input: { oobCode: string },
  ): Promise<string | undefined> => {
    try {
      return await FirebaseAuth.verifyPasswordResetCode(auth, input.oobCode);
    } catch (error: any) {
      console.error(error);
      return undefined;
    }
  }, []);

  const value = React.useMemo(() => ({
    loading,
    user,
    register,
    login,
    logout,
    sendVerificationEmail,
    sendPasswordResetEmail,
    verifyEmail,
    getEmailFromResetPasswordCode,
  }), [
    loading,
    user,
    register,
    login,
    logout,
    sendVerificationEmail,
    sendPasswordResetEmail,
    verifyEmail,
    getEmailFromResetPasswordCode,
  ]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}
