/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  width?: number | string;
  style?: React.CSSProperties;
  className?: string;
};

// COMPONENTS ------------------------------------------------------------

function BoxPointer({ width = 40, style, className } : IconProps) {
  return (
    <svg width={width} style={style} className={className} viewBox="0 0 40 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14H40L31.5737 13.1846C27.3382 12.7747 23.826 9.72929 22.8203 5.59457L21.8414 1.57008C21.3525 -0.439883 18.507 -0.475955 17.9673 1.52096L16.7987 5.8447C15.7158 9.8516 12.2702 12.773 8.14012 13.186L0 14Z"
        fill="currentColor"
      />
    </svg>

  );
}

export default BoxPointer;
