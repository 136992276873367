import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import LogoSlider from './LogoSlider';
import Logo from '../common/Logo';

// STYLED  ------------------------------------------------------------

const Page = styled.div`
  background-color: ${({ theme }) => (theme.gray[900])};
  color: ${({ theme }) => (theme.gray[100])};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
`;

const FormSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

const LogoSide = styled.div`
  flex: 1;
  height: 100vh;
  padding: 0 16px;
`;

// TYPE  ------------------------------------------------------------

type Props = {
  children?: JSX.Element | JSX.Element[] | string | boolean | null;
  style?: React.CSSProperties;
  windowSize: { width: number; height: number };
};

// COMPONENTS ------------------------------------------------------------

export function AnimatedForm({ id, children, isMobile } : {
  id: string,
  children: JSX.Element,
  isMobile: boolean
}) {
  const transitionIn = { duration: 0.3, ease: [0.25, 1, 0.5, 1] };
  const transitionOut = { duration: 0.3, ease: [0.5, 0, 0.75, 0] };

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        key={id}
        style={{ width: '100%' }}
        initial={isMobile ? { x: '-100%' } : { y: '100%' }}
        animate={isMobile ? { x: '0', transition: transitionIn } : { y: '0', transition: transitionIn }}
        exit={isMobile ? { x: '100%', transition: transitionOut } : { y: '-100%', transition: transitionOut }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

function AuthPage({ children, style, windowSize }: Props) {
  return (
    <Page style={style}>
      {windowSize.width < 850 || <LogoSide><LogoSlider windowSize={windowSize} /></LogoSide>}
      <FormSide>
        {windowSize.width >= 850 || <Logo width={200} />}
        {children}
      </FormSide>
    </Page>
  );
}

export default AuthPage;
