import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useWorkspaceList from '../hooks/useWorkspaceList';
import WorkspaceSelector from '../components/workspace/Selector';
import Logo from '../components/common/Logo';
import Page from '../components/common/Page';
import { Loader } from '../components/atoms';
import NavBar, { NavBarLink } from '../components/common/NavBar';
import { H1 } from '../components/authentication/style';
import { Gear, Error as ErrorIcon } from '../components/icons';

// STYLED ------------------------------------------------------------

const Error = styled.div`
  color: ${({ theme }) => theme.states.error[400]};
  max-width: 300px;
  gap: 8px;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  flex-direction: column;
  p {
    line-height: 140%;
    text-align: center;
  }
  svg {
    color: ${({ theme }) => theme.states.error[500]};
  }
`;

// COMPONENTS ------------------------------------------------------------

function Content() {
  const navigate = useNavigate();
  const { workspaces, error: listError, loading: listLoading } = useWorkspaceList();

  if (listLoading) {
    return <Loader>Loading workspaces...</Loader>;
  }
  if (listError) {
    return (
      <Error>
        <ErrorIcon size={64} />
        <p>Oups, something bad append while loading your workspaces !</p>
        <p>{listError}</p>
      </Error>
    );
  }
  return (
    <>
      <Logo style={{ maxWidth: 200 }} />
      <H1>Choose your workspace</H1>
      <WorkspaceSelector
        workspaces={workspaces || []}
        handleCreate={() => navigate('/get-started')}
      />
    </>
  );
}

function Hub() {
  return (
    <Page>
      <NavBar style={{
        width: 'auto', position: 'absolute', top: '16px', left: '16px',
      }}
      >
        <NavBarLink icon={<Gear size={20} />} label="settings" to="/settings" />
      </NavBar>
      <Content />
    </Page>
  );
}

export default Hub;
