import React from 'react';
import styled from 'styled-components';
import { P } from '../atoms';
import { Info } from '../icons';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.ui.default[500]};
  gap: 8px;
  ${P} {
    color: ${({ theme }) => theme.gray[100]};
  }
`;

// TYPE ------------------------------------------------------------

type Props = {
  label: string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function InfoBox({
  label,
  style,
} : Props) {
  return (
    <Container
      style={style}
    >
      <Info size={24} />
      <P>{label}</P>
    </Container>

  );
}

export default InfoBox;
