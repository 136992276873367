/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type LogoProps = {
  width?: number | string;
  height?: number | string ;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Logo({ width, height, style } : LogoProps) {
  return (
    <svg style={style} width={width} height={height} viewBox="0 0 246 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.56263 4.42266C2.56263 3.36492 3.41981 2.50745 4.4772 2.50745H58.0853C59.1427 2.50745 59.9999 3.36492 59.9999 4.42266V22.6172C59.9999 23.6749 59.1427 24.5324 58.0853 24.5324H45.6406C44.5832 24.5324 43.726 25.3899 43.726 26.4476L43.726 39.8541C43.7259 40.9118 44.5831 41.7693 45.6405 41.7693H58.0853C59.1427 41.7693 59.9999 42.6268 59.9999 43.6845L59.9999 61.879C59.9999 62.9368 59.1427 63.7942 58.0853 63.7942H4.4772C3.41981 63.7942 2.56262 62.9368 2.56262 61.879L2.56256 43.6845C2.56256 42.6268 3.41974 41.7693 4.47714 41.7693H16.9219C17.9793 41.7693 18.8365 40.9118 18.8365 39.8541L18.8365 26.4476C18.8365 25.3899 17.9793 24.5324 16.9219 24.5324H4.4772C3.41981 24.5324 2.56263 23.6749 2.56263 22.6172V4.42266Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8432 19.7353C63.8482 10.2192 71.5614 2.50747 81.0743 2.50746L104.058 2.50745C113.575 2.50744 121.29 10.2247 121.29 19.7444V46.5615C121.29 56.0796 113.577 63.7961 104.063 63.7984L81.0644 63.804C71.5427 63.8063 63.8241 56.0829 63.8291 46.558L63.8432 19.7353ZM94.4854 21.1373C94.4854 20.0795 93.6282 19.222 92.5708 19.222C91.5135 19.222 90.6563 20.0795 90.6563 21.1373V45.1645C90.6563 46.2222 91.5135 47.0797 92.5708 47.0797C93.6282 47.0797 94.4854 46.2222 94.4854 45.1645V21.1373Z"
        fill="currentcolor"
      />
      <path
        d="M125.119 4.42266C125.119 3.36492 125.976 2.50745 127.033 2.50745H180.641C181.699 2.50745 182.556 3.36492 182.556 4.42266V22.6172C182.556 23.6749 181.699 24.5324 180.641 24.5324H168.197C167.139 24.5324 166.282 25.3899 166.282 26.4476V61.879C166.282 62.9368 165.425 63.7942 164.367 63.7942H143.307C142.25 63.7942 141.393 62.9368 141.393 61.879V26.4476C141.393 25.3899 140.535 24.5324 139.478 24.5324H127.033C125.976 24.5324 125.119 23.6749 125.119 22.6172V4.42266Z"
        fill="currentcolor"
      />
      <path
        d="M230.995 63.0773C230.632 63.5305 230.082 63.7942 229.502 63.7942H200.706C200.125 63.7942 199.576 63.5305 199.212 63.0773L186.806 47.6048C186.534 47.2649 186.385 46.8422 186.385 46.4065V4.42266C186.385 3.36492 187.242 2.50745 188.3 2.50745H209.36C210.417 2.50745 211.275 3.36491 211.275 4.42266V45.1645C211.275 46.2222 212.132 47.0797 213.189 47.0797H217.018C218.076 47.0797 218.933 46.2222 218.933 45.1645V26.4476C218.933 25.3899 218.076 24.5324 217.018 24.5324C215.961 24.5324 215.104 23.6749 215.104 22.6172V4.42266C215.104 3.36492 215.961 2.50745 217.018 2.50745H241.908C242.965 2.50745 243.822 3.36492 243.822 4.42266V46.4065C243.822 46.8422 243.674 47.2649 243.401 47.6048L230.995 63.0773Z"
        fill="currentcolor"
      />
    </svg>

  );
}

export default Logo;
