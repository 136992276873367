import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UIColor } from '../../theme';
import { CellBase, NavTitle, NavSection } from '../common/SplitPage';
import { SkeletonBox } from '../atoms';

// STYLED ------------------------------------------------------------

const SkeletonBoxCustom = styled(SkeletonBox)`
  background-color: ${({ theme }) => theme.gray[700]};
`;

const SkeletonCell = styled.div<{ index : number }>`
  padding: 12px;
  width: 100%;
  opacity: ${({ index }) => 1 / index};;
  ${SkeletonBoxCustom} {
    animation-delay: ${({ index }) => `${index * 100}ms`};
  }
`;

const Title = styled(NavTitle)`
  padding: 4px 4px 4px 8px;
  user-select: none;
  p {
    flex: 1;
  }
`;

const CellContainer = styled(Link)<{ color : UIColor, selected: boolean }>`
  background-color: ${({ theme, selected }) => theme.gray[selected ? 800 : 900]};
  color: ${({ theme, color }) => theme.getUIColor(color, 500)};
  opacity: ${({ selected }) => (selected ? 1 : 0.65)};
  :focus,
  :hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.gray[800]};;
  }
  text-decoration: none;
  ${CellBase}
`;

// TYPE ------------------------------------------------------------

type Props = {
  children?: JSX.Element | JSX.Element[] | boolean;
  style?: React.CSSProperties;
  icon?: JSX.Element;
  label: string;
};

type CellProps = {
  label: string;
  color?: UIColor;
  to: string;
  selected?: boolean;
};

// COMPONENTS ------------------------------------------------------------

export default function OverlayList({
  children, style, icon, label,
} : Props) {
  return (
    <NavSection style={style}>
      <Title>
        {icon}
        <p>{label}</p>
      </Title>
      {children}
    </NavSection>
  );
}

export function OverlayListSkeleton() {
  return (
    <NavSection>
      <Title style={{ padding: 8 }}>
        <SkeletonBoxCustom style={{ height: 20, width: 20 }} />
        <SkeletonBoxCustom style={{ height: 14, width: 100 }} />
      </Title>
      <SkeletonCell index={1}><SkeletonBoxCustom style={{ height: 16, width: '75%' }} /></SkeletonCell>
      <SkeletonCell index={2}><SkeletonBoxCustom style={{ height: 16, width: '75%' }} /></SkeletonCell>
      <SkeletonCell index={3}><SkeletonBoxCustom style={{ height: 16, width: '75%' }} /></SkeletonCell>
      <SkeletonCell index={4}><SkeletonBoxCustom style={{ height: 16, width: '75%' }} /></SkeletonCell>
      <SkeletonCell index={5}><SkeletonBoxCustom style={{ height: 16, width: '75%' }} /></SkeletonCell>
    </NavSection>
  );
}

export function OverlayCell({
  label, color = 'default', to, selected = false,
} : CellProps) {
  return (
    <CellContainer to={to} color={color} selected={selected}>
      <p>{label}</p>
    </CellContainer>
  );
}
