import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SkeletonBox, Image } from '../atoms';

// STYLED ------------------------------------------------------------

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  background-color: ${({ theme }) => theme.gray[800]};
  border-radius: 24px;
  box-shadow: 0 0 20px 0 #0000001A;
  width: 100%;
`;

export default NavBar;

const NavContainer = styled(Link)<{ selected?: boolean }>`
  display: flex;
  gap: 8px;
  padding: 12px;
  font-size: 16px;
  font-weight: 800;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  border-radius: 16px;
  background-color: ${({ theme, selected }) => theme.gray[selected ? 700 : 800]};
  color: ${({ theme, selected }) => theme.gray[selected ? 400 : 500]};
  transition: all 150ms;
  :focus,
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray[700]};
    color: ${({ theme }) => theme.gray[400]};
  }
`;

const WorkspaceContainer = styled(Link)`
  display: flex;
  gap: 8px;
  padding: 8px;
  padding-right: 16px;
  font-size: 16px;
  font-weight: 800;
  line-height: 100%;
  align-items: center;
  text-decoration: none;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.gray[900]};
  color: ${({ theme }) => theme.gray[500]};
  transition: all 150ms;
  width: 100%;
  :focus,
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.gray[400]};
  }
  p {
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
  }
`;

const WorkspaceContainerEmpty = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  padding-right: 16px;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.gray[900]};
  border-radius: 16px;
  ${SkeletonBox} {
    background-color: ${({ theme }) => theme.gray[600]};
  }
`;

const NavContainerEmpty = styled.div`
  display: flex;
  gap: 12px;
  padding: 12px;
  align-items: center;
  width: 100%;
  ${SkeletonBox} {
    background-color: ${({ theme }) => theme.gray[600]};
  }
`;

// TYPE ------------------------------------------------------------

type NavProps = {
  icon: JSX.Element;
  label: string;
  to: string;
  selected?: boolean;
};

type WorkspaceProps = {
  to: string;
  name: string;
  image?: string;
};

// COMPONENTS ------------------------------------------------------------

export function NavBarLinkSkeleton() {
  return (
    <NavContainerEmpty>
      <SkeletonBox style={{ height: 20, width: 20 }} />
      <SkeletonBox style={{ height: 16, width: '50%' }} />
    </NavContainerEmpty>
  );
}

export function NavBarLink({
  icon, label, to, selected,
} : NavProps) {
  return (
    <NavContainer to={to} selected={selected}>
      {icon}
      {label}
    </NavContainer>
  );
}

export function NavBarWorkspaceSkeleton() {
  return (
    <WorkspaceContainerEmpty>
      <SkeletonBox style={{ height: 32, minWidth: 32, borderRadius: 8 }} />
      <SkeletonBox style={{ height: 16, width: '100%' }} />
    </WorkspaceContainerEmpty>
  );
}

export function NavBarWorkspace({ to, name, image } : WorkspaceProps) {
  return (
    <WorkspaceContainer to={to}>
      <Image
        src={image}
        alt="workspace image"
        height={32}
        width={32}
        style={{ borderRadius: 8, minWidth: 32 }}
      />
      <p>{name}</p>
    </WorkspaceContainer>
  );
}
