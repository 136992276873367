import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Eye, Lock } from '../icons';
import { UIColor } from '../../theme';
import {
  InputContainer, InputInfo, InputLabel, StateToBorder,
} from './common';

// STYLED ------------------------------------------------------------

const Label = styled(InputLabel)<{ center: boolean }>`
  transition: transform 300ms;
  transform: translateY(${({ center }) => (center ? '12px' : '0px')});
`;

const Box = styled.div<{ isFocus: boolean, state : string, disabled?: boolean, color : UIColor }>`
  display: flex;
  padding: 14px;
  background-color: ${({ theme }) => (theme.gray[900])};
  border: 2px solid ${({
    theme, color, state, isFocus, disabled,
  }) => (StateToBorder(theme, color, state, isFocus, disabled))} ;
  transition: all 300ms;
  &:hover {
    cursor:  ${({ disabled }) => (!disabled ? 'text' : 'default')};
  }
  border-radius: 16px;
  gap: 8px;
  ${Label} {
    user-select: none;
    color: ${({ disabled, theme }) => (disabled ? theme.gray[500] : theme.gray[400])};
  }
`;

const TextInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0;
`;

const Input = styled.input<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) => (!disabled ? theme.gray[100] : theme.gray[500])};
  background-color: transparent;
  font-weight: 600;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 16px;
  line-height: 100%;
  font-family: 'Public Sans', sans-serif;
  &::placeholder {
    color: ${({ theme }) => (theme.gray[500])};
  }
`;

const ShowHideButton = styled.button`
  color: ${({ theme }) => theme.gray[500]};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  transition: color 100ms;
  display: flex;
  align-items: center;
  user-select: none;
  &:focus,
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => (theme.gray[100])};
  }
`;

const LockButton = styled.button`
  color: ${({ theme }) => theme.gray[500]};
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  transition: color 100ms;
  display: flex;
  align-items: center;
  user-select: none;
  &:focus,
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => (theme.gray[100])};
  }
`;

// TYPE ------------------------------------------------------------

type TextFieldProps = {
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  type?: 'email' | 'password' | 'text' | 'url';
  state?: 'default' | 'warning' | 'error';
  color?: UIColor;
  secured?: boolean;
};

type TextFieldSwitchProps = {
  style?: React.CSSProperties;
  info?: string;
} & TextFieldProps;

// COMPONENTS ------------------------------------------------------------

function DefaultTextField({
  label,
  placeholder = '',
  onChange,
  value,
  disabled = false,
  type = 'text',
  state = 'default',
  color = 'default',
  secured = false,
} : TextFieldProps) {
  const [locked, setLocked] = useState(true);
  const [focus, setFocus] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  const forceFocus = () => {
    if (refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Box isFocus={focus} onClick={forceFocus} state={state} disabled={disabled} color={color}>
      <TextInputBox>
        <Label center={!value && !focus && !placeholder}>
          {label}
          {disabled && <Lock size={16} style={{ marginTop: '-3px' }} />}
        </Label>
        <Input
          ref={refInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => { onChange(e.target.value); }}
          disabled={disabled || (secured && locked)}
        />
      </TextInputBox>
      {
          secured ? (
            <LockButton type="button" onClick={() => { setLocked(!locked); }} aria-label="lock/unlock field">
              <Lock size={22} />
            </LockButton>
          ) : null
        }
    </Box>
  );
}

function PwdTextField({
  label,
  placeholder = '',
  onChange,
  value,
  disabled = false,
  type = 'text',
  state = 'default',
  color = 'default',
  secured = false,
} : TextFieldProps) {
  const [locked, setLocked] = useState(true);
  const [focus, setFocus] = useState(false);
  const [visibilty, setVisibilty] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);

  const forceFocus = () => {
    if (refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <Box isFocus={focus} onClick={forceFocus} state={state} disabled={disabled} color={color}>
      <TextInputBox>
        <Label center={!value && !focus && !placeholder}>
          {label}
          {disabled && <Lock size={16} style={{ marginTop: '-3px' }} />}
        </Label>
        <Input
          ref={refInput}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          type={visibilty ? 'text' : type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => { onChange(e.target.value); }}
          disabled={disabled || (secured && locked)}
        />
      </TextInputBox>
      <ShowHideButton type="button" onClick={() => { setVisibilty(!visibilty); }} aria-label="show/hide password">
        <Eye size={22} cross={!visibilty} />
      </ShowHideButton>
      {
          secured ? (
            <LockButton type="button" onClick={() => { setLocked(!locked); }} aria-label="show/hide password">
              <Lock size={22} />
            </LockButton>
          ) : null
        }
    </Box>
  );
}

// SWITCH ------------------------------------------------------------

function Switch({ ...props } : TextFieldSwitchProps) {
  switch (props.type) {
    case 'password':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <PwdTextField {...props} />;
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DefaultTextField {...props} />;
  }
}

function TextField({
  label,
  placeholder = '',
  onChange,
  value,
  disabled = false,
  type = 'text',
  info,
  state = 'default',
  color = 'default',
  style,
  secured = false,
} : TextFieldSwitchProps) {
  return (
    <InputContainer style={style}>
      <Switch
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
        type={type}
        info={info}
        state={state}
        color={color}
        secured={secured}
      />
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

export default TextField;
