import { useMemo } from 'react';
import { query } from 'firebase/firestore';
import db from '../utils/db';
import * as Collections from '../common/Collections';
import { useBaseFirestoreItem, useBaseFirestoreList } from './useBaseFirestore';

type UseWorkspaceState = {
  state: 'loading'
} | {
  state: 'error'
  error: string
} | {
  state: 'success'
  workspace: Collections.WorkspaceDocument
  overlays: Collections.OverlayDocument[]
};

const useWorkspace = (workspaceId: string): UseWorkspaceState => {
  const workspaceRef = useMemo(() => db.workspace(workspaceId), [workspaceId]);
  const overlaysQuery = useMemo(() => query(db.overlays(workspaceId)), [workspaceId]);

  const {
    loading: workspaceLoading, error: workspaceError, item: workspace,
  } = useBaseFirestoreItem(workspaceRef);

  const {
    loading: overlaysLoading, error: overlaysError, items: overlays,
  } = useBaseFirestoreList(overlaysQuery);

  return useMemo<UseWorkspaceState>(() => {
    if (workspaceLoading || overlaysLoading) {
      return {
        state: 'loading',
      };
    }

    if (workspaceError || overlaysError || !workspace || !overlays) {
      return {
        state: 'error',
        error: workspaceError || overlaysError || 'Workspace not found',
      };
    }

    return {
      state: 'success',
      workspace,
      overlays,
    };
  }, [workspaceLoading, overlaysLoading, workspaceError, overlaysError, workspace, overlays]);
};

export default useWorkspace;
