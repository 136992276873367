import React from 'react';
import { OverlayConfigProps } from '../Overlay';
import { CustomRivenziPollEmote } from '../../../common/Overlays';
import updateOverlayConfig from '../../../actions/updateOverlayConfig';
import {
  HStack, Label, P, Separator, TextArea, NumberInput, Switch, Radio,
} from '../../atoms';
import { getTwitchEmotes } from '../../../actions';
import { GetTwitchEmotesOutput } from '../../../common/Actions';
import EmoteList from '../../molecules/EmoteList';
import BottomNotification from '../../molecules/BottomNotification';
import EmotePicker from '../../molecules/EmotePicker';
import isDeepEqual from '../../../utils/isDeepEqual';

function FormEmoteList({
  workspaceId, emotes, setEmotes, loading,
}: {
  workspaceId: string,
  emotes: CustomRivenziPollEmote.Config['emotes'],
  setEmotes: (emotes: CustomRivenziPollEmote.Config['emotes']) => void,
  loading: boolean
}) {
  const [modalState, setModalState] = React.useState<{ visible: boolean, emote?: CustomRivenziPollEmote.Emote }>(
    { visible: false },
  );
  const [loadingEmotes, setLoadingEmotes] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [allEmotes, setAllEmotes] = React.useState<GetTwitchEmotesOutput | undefined>(undefined);

  const handleEnableEditing = React.useCallback(async (emote?: CustomRivenziPollEmote.Emote) => {
    setModalState({ visible: true, emote });
    setLoadingEmotes(true);
    setError(undefined);
    const res = await getTwitchEmotes({ workspaceId });
    if (res.ok) {
      setAllEmotes(res.data);
    } else {
      setError(res.error);
    }
    setLoadingEmotes(false);
  }, [workspaceId]);

  const handleSelect = (emote: CustomRivenziPollEmote.Emote) => {
    if (modalState.emote
      && emotes.map((e) => e.id).indexOf(modalState.emote.id) !== -1
      && !emotes.map((e) => e.id).includes(emote.id)
    ) {
      const newArray = [...emotes];
      newArray[emotes.map((e) => e.id).indexOf(modalState.emote.id)] = emote;
      setEmotes(newArray);
    } else {
      setEmotes([...emotes, emote]);
    }
    setModalState({ visible: false });
  };

  return (
    <>
      <HStack>
        <Label>Emotes</Label>
        <P>Set emotes to count.</P>
        <EmoteList.Container onAdd={() => handleEnableEditing()}>
          {emotes.map((emote) => (
            <EmoteList.Cell
              key={emote.id}
              label={emote.name}
              onRemove={() => setEmotes(emotes.filter((emote2) => emote2.id !== emote.id))}
              onEdit={() => handleEnableEditing(emote)}
              emote={emote}
              disabled={loading}
            />
          ))}
        </EmoteList.Container>
      </HStack>
      <EmotePicker
        modalTitle={!modalState.emote ? 'Modify emote' : 'Add an emote'}
        onClose={() => setModalState({ visible: false })}
        onSelect={(emote) => handleSelect(emote)}
        visible={modalState.visible}
        selectedEmotes={emotes}
        list={allEmotes ? [allEmotes.channelEmotes, allEmotes.globalEmotes] : []}
        error={error}
        loading={loadingEmotes}
      />
    </>
  );
}

function FormQuality({
  highQualityAnimation, setHighQualityAnimation,
  refreshRate, setRefreshRate,
  loading,
}: {
  highQualityAnimation: boolean,
  setHighQualityAnimation: (highQualityAnimation: boolean) => void,
  refreshRate: number,
  setRefreshRate: (refreshRate: number) => void,
  loading: boolean
}) {
  return (
    <HStack>
      <Label>Quality</Label>
      <P>Play with those setting to balance between performances and style.</P>
      <Switch
        label="High quality animation"
        onChange={(value) => setHighQualityAnimation(value)}
        value={highQualityAnimation}
        disabled={loading}
      />
      <NumberInput
        label="Refresh rate"
        valueType="Sec."
        onChange={(value) => setRefreshRate(value)}
        value={refreshRate}
        disabled={loading}
        min={1}
        max={360}
        increment={2}
      />
    </HStack>
  );
}

function FormStyleOverride(
  { styleOverride, setStyleOverride, loading }: {
    styleOverride: CustomRivenziPollEmote.Config['styleOverride']
    setStyleOverride: (styleOverride: CustomRivenziPollEmote.Config['styleOverride']) => void
    loading: boolean
  },
) {
  return (
    <HStack>
      <Label>Style override</Label>
      <P>Override default CSS.</P>
      <TextArea
        value={styleOverride || ''}
        onChange={(newValue) => setStyleOverride(newValue)}
        disabled={loading}
        placeholder="body { background-color: transparent; ..."
      />
    </HStack>
  );
}

export default function Config({ overlay, config }: OverlayConfigProps) {
  const overlayConfig = config as CustomRivenziPollEmote.Config;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const [emotes, setEmotes] = React.useState<CustomRivenziPollEmote.Config['emotes']>(overlayConfig.emotes);
  const [voteType, setVoteType] = React.useState(overlayConfig.voteType);
  const [pollingDuration, setPollingDuration] = React.useState(overlayConfig.durations.polling);
  const [resultDuration, setResultDuration] = React.useState(overlayConfig.durations.result);
  const [highQualityAnimation, setHighQualityAnimation] = React.useState(overlayConfig.quality.highQualityAnimation);
  const [refreshRate, setRefreshRate] = React.useState(overlayConfig.quality.refreshRate);
  const [styleOverride, setStyleOverride] = React.useState(overlayConfig.styleOverride);

  const emotesHasDiff = React.useMemo(
    () => !isDeepEqual(overlayConfig.emotes, emotes),
    [overlayConfig.emotes, emotes],
  );
  const voteTypeHasDiff = React.useMemo(
    () => overlayConfig.voteType !== voteType,
    [overlayConfig.voteType, voteType],
  );
  const pollingDurationHasDiff = React.useMemo(
    () => overlayConfig.durations.polling !== pollingDuration,
    [overlayConfig.durations.polling, pollingDuration],
  );
  const resultDurationHasDiff = React.useMemo(
    () => overlayConfig.durations.result !== resultDuration,
    [overlayConfig.durations.result, resultDuration],
  );
  const highQualityAnimationHasDiff = React.useMemo(
    () => overlayConfig.quality.highQualityAnimation !== highQualityAnimation,
    [overlayConfig.quality.highQualityAnimation, highQualityAnimation],
  );
  const refreshRateHasDiff = React.useMemo(
    () => overlayConfig.quality.refreshRate !== refreshRate,
    [overlayConfig.quality.refreshRate, refreshRate],
  );
  const styleOverrideHasDiff = React.useMemo(
    () => overlayConfig.styleOverride !== styleOverride,
    [overlayConfig.styleOverride, styleOverride],
  );
  const hasDiff = React.useMemo(
    () => emotesHasDiff
      || voteTypeHasDiff
      || pollingDurationHasDiff
      || resultDurationHasDiff
      || highQualityAnimationHasDiff
      || refreshRateHasDiff
      || styleOverrideHasDiff,
    [
      emotesHasDiff, voteTypeHasDiff, pollingDurationHasDiff, resultDurationHasDiff,
      highQualityAnimationHasDiff, refreshRateHasDiff, styleOverrideHasDiff,
    ],
  );

  const handleCancel = React.useCallback(() => {
    setEmotes(overlayConfig.emotes);
    setVoteType(overlayConfig.voteType);
    setPollingDuration(overlayConfig.durations.polling);
    setResultDuration(overlayConfig.durations.result);
    setHighQualityAnimation(overlayConfig.quality.highQualityAnimation);
    setRefreshRate(overlayConfig.quality.refreshRate);
    setStyleOverride(overlayConfig.styleOverride);
  }, [overlayConfig]);

  const handleSubmit = React.useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!hasDiff) {
      return;
    }
    setLoading(true);
    const res = await updateOverlayConfig<CustomRivenziPollEmote.Config>({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
      config: {
        emotes,
        voteType,
        durations: {
          polling: pollingDuration,
          result: resultDuration,
        },
        quality: {
          highQualityAnimation,
          refreshRate,
        },
        styleOverride,
      },
    });
    if (res.ok) {
      setError(undefined);
    } else {
      setError(res.error);
    }
    setLoading(false);
  }, [
    hasDiff, overlay.overlayId, overlay.workspaceId,
    emotes, voteType, pollingDuration, resultDuration, highQualityAnimation, refreshRate, styleOverride,
  ]);

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <FormEmoteList
        workspaceId={overlay.workspaceId}
        emotes={emotes}
        setEmotes={setEmotes}
        loading={loading}
      />
      <Separator />
      <HStack>
        <Label>Vote Type</Label>
        <P>Set how you want the overlay to count votes.</P>
        <Radio<CustomRivenziPollEmote.VoteType>
          choices={[
            { label: 'One vote per message', value: CustomRivenziPollEmote.VoteType.ONE_VOTE_PER_MESSAGE },
            { label: 'One vote per emote', value: CustomRivenziPollEmote.VoteType.ONE_VOTE_PER_EMOTE },
            { label: 'All emotes are counted', value: CustomRivenziPollEmote.VoteType.ALL_EMOTES_COUNTED },
          ]}
          value={voteType}
          name="countType"
          onChange={(value) => setVoteType(value)}
          disabled={loading}
        />
      </HStack>
      <Separator />
      <HStack>
        <Label>Durations</Label>
        <P>Set default duration of the poll and display delay result.</P>
        <NumberInput
          label="Poll Duration"
          valueType="Sec."
          onChange={(value) => setPollingDuration(value)}
          value={pollingDuration}
          disabled={loading}
          min={1}
          max={3600}
          increment={15}
        />
        <NumberInput
          label="Display result"
          valueType="Sec."
          onChange={(value) => setResultDuration(value)}
          value={resultDuration}
          disabled={loading}
          min={1}
          max={3600}
          increment={5}
        />
      </HStack>
      <Separator />
      <FormQuality
        highQualityAnimation={highQualityAnimation}
        setHighQualityAnimation={setHighQualityAnimation}
        refreshRate={refreshRate}
        setRefreshRate={setRefreshRate}
        loading={loading}
      />
      <Separator />
      <FormStyleOverride
        styleOverride={styleOverride}
        setStyleOverride={setStyleOverride}
        loading={loading}
      />
      <BottomNotification.Container show={hasDiff}>
        <BottomNotification.InlineNotification
          info="Careful, you have unsaved changes!"
          onCancel={handleCancel}
          onConfirm={handleSubmit}
          confirmLabel="Save changes"
          cancelLabel="Reset"
          loading={loading}
        />
      </BottomNotification.Container>
      {error && <p>{error}</p>}
    </form>
  );
}
