import { execCallable } from '../utils/callable';
import {
  updateOverlayConfigFunctionName,
  UpdateOverlayConfigInput,
  UpdateOverlayConfigOutput,
} from '../common/Actions';

const updateOverlayConfig = <TConfig extends Record<string, any>>(
  input: { workspaceId: string, overlayId: string, config: TConfig },
) => execCallable<UpdateOverlayConfigInput, UpdateOverlayConfigOutput>(
    updateOverlayConfigFunctionName,
    {
      workspaceId: input.workspaceId,
      overlayId: input.overlayId,
      config: input.config,
    },
  );

export default updateOverlayConfig;
