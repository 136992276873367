export type DocumentTimestamps = {
  createdAt: Date
  updatedAt: Date
};

export type TwitchAccount = {
  id: string
  login: string
  displayName: string
  profileImageUrl: string
};

export type OverlayColor =
  'red' | 'pink' | 'purple' | 'lavender' | 'blue' | 'sky' | 'cyan' | 'green' | 'lemon' | 'yellow' | 'orange' | 'spice';
export const overlayColors: OverlayColor[] = [
  'red', 'pink', 'purple', 'lavender', 'blue', 'sky', 'cyan', 'green', 'lemon', 'yellow', 'orange', 'spice',
];

export const UserName = 'user';
export type UserDocument = {
  userId: string
  email: string
  betaKey: string
  timestamps: DocumentTimestamps
};

export const BetaKeyName = 'betaKey';
export type BetaKeyDocument = {
  betaKey: string
  description: string
  consumed: boolean
  consumedBy?: string
  consumedAt?: Date
  email: string
  timestamps: DocumentTimestamps
};

export const WorkspaceName = 'workspace';
export type WorkspaceDocument = {
  workspaceId: string
  ownerId: string
  name: string
  twitch?: TwitchAccount
  timestamps: DocumentTimestamps
};

export const WorkspaceTokenName = 'workspaceToken';
export type WorkspaceTokenDocument = {
  workspaceId: string
  twitch?: {
    accessToken: string
    refreshToken: string
    scope: string[]
    tokenType: string
    expiresIn: number
    expiresAt: Date
    generatedAt: Date
  }
  timestamps: DocumentTimestamps
};

export const OverlayName = 'overlay';
export type OverlayDocument = {
  workspaceId: string
  overlayId: string
  name: string
  type: string
  color: OverlayColor
  timestamps: DocumentTimestamps
};

export const OverlayConfigName = 'overlayConfig';
export type OverlayConfigDocument<TConfig extends Record<string, any> = Record<string, any>> = {
  workspaceId: string
  overlayId: string
  config: TConfig
  timestamps: DocumentTimestamps
};

export const OverlayPrivateKeyName = 'overlayPrivateKey';
export type OverlayPrivateKeyDocument = {
  workspaceId: string
  overlayId: string
  privateKey: string
  timestamps: DocumentTimestamps
};

export const OverlayDataName = 'overlayData';
export type OverlayDataDocument<
  TConfig extends Record<string, any> = Record<string, any>,
  TData extends Record<string, any> = Record<string, any>,
> = {
  type: string
  config: TConfig
  data: TData
  twitch?: TwitchAccount
  timestamps: DocumentTimestamps
};
