import React, { useMemo } from 'react';
import { OverlayDisplayProps } from '../Overlay';
import getOverlayUrl from '../../../utils/getOverlayUrl';
import {
  CopyField, HStack, Label, VStack,
} from '../../atoms';

export default function Display({ privateKey }: OverlayDisplayProps) {
  const defaultUrl = useMemo(() => getOverlayUrl(`/custom/rivenzi/poll_emote?key=${privateKey}`), [privateKey]);

  return (
    <VStack>
      <HStack>
        <Label>Browser source URL</Label>
        <CopyField
          value={defaultUrl}
          style={{ maxWidth: 350 }}
          info="*this URL is private, make sure to not share it"
          secure
        />
      </HStack>
      <HStack>
        <Label>Private Key for Stream Deck</Label>
        <CopyField
          value={privateKey}
          style={{ maxWidth: 350 }}
          info="*this Key is private, make sure to not share it"
          secure
        />
      </HStack>
    </VStack>
  );
}
