import React from 'react';
import { OverlayActionProps } from '../Overlay';
import OverlayDataMiddleware, { OverlayDataProps } from '../OverlayDataMiddleware';
import { CustomRivenziRugby } from '../../../common/Overlays';
import { Button } from '../../atoms';

function ActionPrivate({ overlayData, updateOverlayData }: OverlayDataProps<CustomRivenziRugby.Config, CustomRivenziRugby.Data>) {
  const [loadingId, setLoadingId] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleSwitchSection = React.useCallback(async () => {
    setLoadingId('switchSection');
    const res = await updateOverlayData({
      section: overlayData.data.section === 'stats' ? 'teamSheets' : 'stats',
    });
    if (!res) setError('Failed to switch section');
    setLoadingId(undefined);
  }, [updateOverlayData, overlayData.data.section]);

  const handleToggleSection = React.useCallback(async () => {
    setLoadingId('toggleSection');
    const res = await updateOverlayData({
      displaySection: !overlayData.data.displaySection,
    });
    if (!res) setError('Failed to toggle section');
    setLoadingId(undefined);
  }, [updateOverlayData, overlayData.data.displaySection]);

  const handleTriggerEvent = React.useCallback(async (type: 'try' | 'penalty', team: 'home' | 'away') => {
    setLoadingId('triggerEvent');
    const res = await updateOverlayData({
      lastAnimation: {
        type,
        team,
        time: Date.now().toString(),
      },
    });
    if (!res) setError('Failed to trigger event');
    setLoadingId(undefined);
  }, [updateOverlayData]);

  const handleResetEvent = React.useCallback(async () => {
    setLoadingId('resetEvent');
    const res = await updateOverlayData({
      lastAnimation: null,
    });
    if (!res) setError('Failed to reset event');
    setLoadingId(undefined);
  }, [updateOverlayData]);

  return (
    <>
      {error && <p>{error}</p>}
      <p>
        Section is currently:
        {' '}
        {overlayData.data.displaySection ? 'Displayed' : 'Hidden'}
      </p>
      <Button
        label={overlayData.data.displaySection ? 'Hide Section' : 'Show Section'}
        loading={loadingId === 'toggleSection'}
        disabled={loadingId !== undefined && loadingId !== 'toggleSection'}
        onClick={handleToggleSection}
      />
      <p>
        Currently displaying:
        {' '}
        {overlayData.data.section === 'stats' ? 'Stats' : 'Team Sheets'}
      </p>
      <Button
        label={overlayData.data.section === 'stats' ? 'Switch to Team Sheets' : 'Switch to Stats'}
        loading={loadingId === 'switchSection'}
        disabled={loadingId !== undefined && loadingId !== 'switchSection'}
        onClick={handleSwitchSection}
      />
      <p>
        Trigger an event:
        {overlayData.data.lastAnimation && ` (current ${overlayData.data.lastAnimation.type} ${overlayData.data.lastAnimation.team})`}
      </p>
      <Button
        label="Try Home"
        loading={loadingId === 'triggerEvent'}
        disabled={loadingId !== undefined && loadingId !== 'triggerEvent'}
        onClick={() => handleTriggerEvent('try', 'home')}
      />
      <Button
        label="Penalty Home"
        loading={loadingId === 'triggerEvent'}
        disabled={loadingId !== undefined && loadingId !== 'triggerEvent'}
        onClick={() => handleTriggerEvent('penalty', 'home')}
      />
      <Button
        label="Try Away"
        loading={loadingId === 'triggerEvent'}
        disabled={loadingId !== undefined && loadingId !== 'triggerEvent'}
        onClick={() => handleTriggerEvent('try', 'away')}
      />
      <Button
        label="Penalty Away"
        loading={loadingId === 'triggerEvent'}
        disabled={loadingId !== undefined && loadingId !== 'triggerEvent'}
        onClick={() => handleTriggerEvent('penalty', 'away')}
      />
      <p> Reset event:</p>
      <Button
        label="Reset Event"
        loading={loadingId === 'resetEvent'}
        disabled={(loadingId !== undefined && loadingId !== 'resetEvent') || !overlayData.data.lastAnimation}
        onClick={handleResetEvent}
      />
    </>
  );
}

export default function Action({ overlay, privateKey }: OverlayActionProps) {
  return <OverlayDataMiddleware overlay={overlay} privateKey={privateKey} component={ActionPrivate} />;
}
