/* eslint-disable max-len */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Skeleton } from './common';

// STYLED ------------------------------------------------------------

const rotate = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, calc(50% + 1.5px));
  }
  50% {
    transform: translate(calc(50% + 1.5px), calc(50% + 1.5px));
  }
  75% {
    transform: translate(calc(50% + 1.5px), 0%);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const RotatingPath = styled.path<{ index: number }>`
  animation: ${rotate};
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
  animation-iteration-count: infinite;
  animation-delay: ${({ index }) => (index * 4) / -4}s;
  transform-origin: calc(50% - 3px) calc(50% - 3px);
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  max-width: 64px;
  max-height: 64px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  flex-direction: column;
  ${Skeleton}
`;

// TYPE ------------------------------------------------------------

type Props = {
  style?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[] | string;
};

// COMPONENTS ------------------------------------------------------------

export default function Loader({ style, children } : Props) {
  return (
    <Container style={style}>
      <SVG viewBox="0 0 93 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <RotatingPath
          index={0}
          d="M5.22346e-05 1.51515C5.22346e-05 0.678356 0.671494 0 1.49976 0L43.4916 0C44.3198 0 44.9913 0.678357 44.9913 1.51515V15.9091C44.9913 16.7459 44.3198 17.4242 43.4916 17.4242H33.7435C32.9152 17.4242 32.2437 18.1026 32.2437 18.9394L32.2437 29.5454C32.2437 30.3822 32.9151 31.0606 33.7434 31.0606H43.4915C44.3198 31.0606 44.9912 31.739 44.9912 32.5758L44.9913 46.9697C44.9913 47.8065 44.3198 48.4848 43.4915 48.4848H1.49975C0.671489 48.4848 5.00073e-05 47.8065 4.72598e-05 46.9697L0 32.5758C-2.74746e-06 31.739 0.67144 31.0606 1.49971 31.0606H11.2478C12.0761 31.0606 12.7475 30.3823 12.7475 29.5455L12.7476 18.9394C12.7476 18.1026 12.0761 17.4242 11.2478 17.4242H1.49976C0.671494 17.4242 5.22346e-05 16.7459 5.22346e-05 15.9091L5.22346e-05 1.51515Z"
          fill="currentColor"
        />
        <RotatingPath
          index={1}
          d="M34.9435 47.9177C34.6589 48.2762 34.2285 48.4849 33.7736 48.4849H11.2176C10.7627 48.4849 10.3323 48.2762 10.0477 47.9177L0.329823 35.6772C0.116343 35.4083 0 35.0739 0 34.7291L0 1.51515C0 0.678357 0.671445 0 1.49971 0L17.9965 0C18.8248 0 19.4962 0.678357 19.4962 1.51515V33.7466C19.4962 34.5834 20.1676 35.2617 20.9959 35.2617H23.9953C24.8236 35.2617 25.495 34.5834 25.495 33.7466V18.9394C25.495 18.1026 24.8236 17.4242 23.9953 17.4242C23.167 17.4242 22.4956 16.7459 22.4956 15.9091V1.51515C22.4956 0.678357 23.167 0 23.9953 0L43.4915 0C44.3198 0 44.9912 0.678357 44.9912 1.51515V34.7291C44.9912 35.0739 44.8749 35.4083 44.6614 35.6772L34.9435 47.9177Z"
          fill="currentColor"
        />
        <RotatingPath
          index={2}
          d="M0 1.51515C0 0.678357 0.671442 0 1.49971 0L43.4915 0C44.3198 0 44.9912 0.678357 44.9912 1.51515V15.9091C44.9912 16.7459 44.3198 17.4242 43.4915 17.4242H33.7434C32.9151 17.4242 32.2437 18.1026 32.2437 18.9394V46.9697C32.2437 47.8065 31.5723 48.4849 30.744 48.4849H14.2472C13.419 48.4849 12.7475 47.8065 12.7475 46.9697V18.9394C12.7475 18.1026 12.0761 17.4242 11.2478 17.4242H1.49971C0.671442 17.4242 0 16.7459 0 15.9091L0 1.51515Z"
          fill="currentColor"
        />
        <RotatingPath
          index={3}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0110493 13.6292C0.0149678 6.10084 6.05679 1.53287e-05 13.5084 1.08415e-05L31.512 0C38.9664 -4.48892e-06 45.0093 6.10521 45.0093 13.6364V34.8518C45.0093 42.3817 38.9684 48.4863 31.5153 48.4882L13.5006 48.4926C6.0422 48.4944 -0.00392025 42.3843 1.90717e-06 34.8491L0.0110493 13.6292ZM24.0134 14.7383C24.0134 13.9015 23.342 13.2232 22.5137 13.2232C21.6855 13.2232 21.014 13.9015 21.014 14.7383V33.7466C21.014 34.5834 21.6855 35.2617 22.5137 35.2617C23.342 35.2617 24.0134 34.5834 24.0134 33.7466V14.7383Z"
          fill="currentColor"
        />
      </SVG>
      {children}
    </Container>
  );
}
