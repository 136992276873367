import { execCallable } from '../utils/callable';
import {
  getTwitchEmotesFunctionName,
  GetTwitchEmotesInput,
  GetTwitchEmotesOutput,
} from '../common/Actions';

const getTwitchEmotes = (
  input: { workspaceId: string },
) => execCallable<GetTwitchEmotesInput, GetTwitchEmotesOutput>(
  getTwitchEmotesFunctionName,
  {
    workspaceId: input.workspaceId,
  },
);

export default getTwitchEmotes;
