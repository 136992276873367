import React from 'react';
import styled from 'styled-components';
import { motion, useAnimationControls } from 'framer-motion';

// STYLED ------------------------------------------------------------

const Container = styled.button`
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0px;
  outline: none;
  box-sizing: border-box;
  width: fit-content;
`;

export const Notification = styled(motion.div)`
  position: absolute;
  background-color:  ${({ theme }) => (theme.gray[100])};
  color: ${({ theme }) => (theme.gray[900])};
  padding: 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 800;
  line-height: 100%;
  font-family: 'Public Sans', sans-serif;
  box-shadow: 0 0 16px #000000;
`;

// TYPE ------------------------------------------------------------

type CopyProps = {
  children: JSX.Element;
  toCopy: string;
  disabled?: boolean;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function CopyToClipboard({
  children,
  toCopy,
  disabled = false,
  style,
} : CopyProps) {
  const controls = useAnimationControls();

  const copy = async () => {
    navigator.clipboard.writeText(toCopy);
    await controls.start({
      scale: [0, 1],
      transition:
      {
        type: 'spring', bounce: 0.5, duration: 0.5,
      },
    });
    await controls.start({
      scale: [1, 0],
      transition: { duration: 0.2, delay: 0.5 },
    });
  };

  return (
    <Container onClick={copy} disabled={disabled} type="button" style={style}>
      <Notification initial={{ scale: 0 }} animate={controls}>Copied</Notification>
      {children}
    </Container>
  );
}

export default CopyToClipboard;
