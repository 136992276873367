/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function ArrowRight({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0992 3.50699C13.7582 3.18156 13.3049 3 12.8335 3C11.8209 3 11 3.82088 11 4.83349V8C11 8.55228 10.5523 9 10 9H5C3.34315 9 2 10.3431 2 12C2 13.6569 3.34315 15 5 15H10C10.5523 15 11 15.4477 11 16V19.1665C11 20.1791 11.8209 21 12.8335 21C13.3049 21 13.7582 20.8184 14.0992 20.493L21.0439 13.8664C22.1091 12.8501 22.1091 11.1499 21.0439 10.1335L14.0992 3.50699Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowRight;
