/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  cross?: boolean
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

const Rect = styled.rect`
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: width 300ms;
`;

function Eye({
  size = 24, cross = false, style,
} : IconProps) {
  const [id] = useState(uuid());

  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect width={cross ? 28.46 : 0} height="3" x="-2.23" y="10.5" rx="1.5" fill="currentcolor" />
      <mask id={id}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20C17.5228 20 22 16.4183 22 12C22 7.58172 17.5228 4 12 4C6.47715 4 2 7.58172 2 12C2 16.4183 6.47715 20 12 20ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
          fill="white"
        />
        <Rect width={cross ? 28.46 : 0} height="7" x="-2.23" y="8.5" rx="1.5" fill="black" />
      </mask>
      <rect
        width="24"
        height="24"
        fill="currentcolor"
        mask={`url(#${id})`}
      />
    </svg>
  );
}

export default Eye;
