import React from 'react';
import { OverlayActionProps } from '../Overlay';
import OverlayDataMiddleware, { OverlayDataProps } from '../OverlayDataMiddleware';
import { CustomRivenziPollEmote } from '../../../common/Overlays';
import { Button } from '../../atoms';

function ActionPrivate({ overlayData, updateOverlayData }: OverlayDataProps<CustomRivenziPollEmote.Config, CustomRivenziPollEmote.Data>) {
  const [loadingId, setLoadingId] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleSetStatus = React.useCallback(async (status: 'disabled' | 'polling' | 'result') => {
    setLoadingId(status);
    setError(undefined);
    const res = await updateOverlayData({
      status,
    });
    if (!res) setError('Failed to change status');
    setLoadingId(undefined);
  }, [updateOverlayData]);

  const handleToggleIsHidden = React.useCallback(async () => {
    setLoadingId('isHidden');
    setError(undefined);
    const res = await updateOverlayData({
      isHidden: !overlayData.data.isHidden,
    });
    if (!res) setError('Failed to change display');
    setLoadingId(undefined);
  }, [overlayData.data.isHidden]);

  const displayStatus = React.useMemo(() => {
    if (overlayData.data.status === 'disabled') return 'Disabled';
    if (overlayData.data.status === 'polling') return 'Polling';
    if (overlayData.data.status === 'result') return 'Result';
    return 'Unknown';
  }, [overlayData.data.status]);

  return (
    <>
      {error && <p>{error}</p>}
      <p>
        Status:
        {' '}
        {displayStatus}
      </p>
      <Button
        label={overlayData.data.isHidden ? 'Show' : 'Hide'}
        loading={loadingId === 'isHidden'}
        disabled={loadingId !== undefined}
        onClick={handleToggleIsHidden}
      />
      <Button
        label="Start poll"
        loading={loadingId === 'polling'}
        disabled={loadingId !== undefined || overlayData.data.status !== 'disabled'}
        onClick={() => handleSetStatus('polling')}
      />
      <Button
        label="Stop poll"
        loading={loadingId === 'result'}
        disabled={loadingId !== undefined || overlayData.data.status !== 'polling'}
        onClick={() => handleSetStatus('result')}
      />
      <Button
        label="Show last result"
        loading={loadingId === 'result'}
        disabled={loadingId !== undefined || overlayData.data.status !== 'disabled'}
        onClick={() => handleSetStatus('result')}
      />
      <Button
        label="Clear poll"
        loading={loadingId === 'disabled'}
        disabled={loadingId !== undefined || overlayData.data.status === 'disabled'}
        onClick={() => handleSetStatus('disabled')}
      />
    </>
  );
}

export default function Action({ overlay, privateKey }: OverlayActionProps) {
  return <OverlayDataMiddleware overlay={overlay} privateKey={privateKey} component={ActionPrivate} />;
}
