/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Lock({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 8.5C5.5 4.91015 8.41015 2 12 2C15.5899 2 18.5 4.91015 18.5 8.5V9H19C20.6569 9 22 10.3431 22 12V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V12C2 10.3431 3.34315 9 5 9H5.5V8.5ZM15.5 8.5V9H8.5V8.5C8.5 6.567 10.067 5 12 5C13.933 5 15.5 6.567 15.5 8.5ZM12 13C10.8954 13 10 13.8954 10 15V16C10 17.1046 10.8954 18 12 18C13.1046 18 14 17.1046 14 16V15C14 13.8954 13.1046 13 12 13Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Lock;
