import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import { Logout } from '../components/icons';
import { Button } from '../components/atoms';
import SplitPage, {
  CellBase, ContentBox, NavSection, NavSeparator, NavTitle, SplitPageContent, SplitPageNav,
} from '../components/common/SplitPage';

// STYLED ------------------------------------------------------------

const NavCell = styled.button<{ isSelected: boolean }>`
  background-color: ${({ isSelected, theme }) => theme.gray[isSelected ? 800 : 900]};
  color: ${({ isSelected, theme }) => theme.gray[isSelected ? 300 : 500]};;
  :focus,
  :hover {
    background-color: ${({ theme }) => theme.gray[800]};
    color: ${({ theme }) => theme.gray[300]};;
  }
  ${CellBase}
`;

const NavLink = styled(Link)`
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  transition: all 150ms;
  background-color: ${({ theme }) => theme.gray[900]};
  color: ${({ theme }) => theme.ui.default[500]};
  padding: 8px 12px;
  text-decoration: none;
  :focus,
  :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.ui.default[900]};
      color: ${({ theme }) => theme.ui.default[400]};;
  }
`;

// TYPE ------------------------------------------------------------

type PropsNav = {
  onChange: (location: SettingsSection) => void;
  selected?: SettingsSection;
};

type SettingsSection = 'user-profile' | 'user-account' | 'workspace-members' | 'workspace-links';

// COMPONENTS ------------------------------------------------------------

function SettingsNav({ onChange, selected }: PropsNav) {
  return (
    <SplitPageNav>
      <NavSection>
        <NavTitle>User Settings</NavTitle>
        <NavCell
          onClick={() => onChange('user-profile')}
          isSelected={selected === 'user-profile'}
        >
          Profile
        </NavCell>
        <NavCell
          onClick={() => onChange('user-account')}
          isSelected={selected === 'user-account'}
        >
          My account
        </NavCell>
      </NavSection>
      <NavSeparator />
      <NavSection>
        <NavTitle>Workspace Settings</NavTitle>
        <NavCell
          onClick={() => onChange('workspace-members')}
          isSelected={selected === 'workspace-members'}
        >
          Members
        </NavCell>
        <NavCell
          onClick={() => onChange('workspace-links')}
          isSelected={selected === 'workspace-links'}
        >
          Links
        </NavCell>
        <NavLink to="/">
          <p>Exit Workspace</p>
          <Logout size={20} />
        </NavLink>
      </NavSection>
    </SplitPageNav>
  );
}

function Settings() {
  const [selected, setSelected] = React.useState<SettingsSection>('user-profile');
  const { logout } = useAuthentication();

  return (
    <SplitPage>
      <SettingsNav onChange={setSelected} selected={selected} />
      <SplitPageContent>
        <ContentBox>
          <Button label="Logout" onClick={logout} />
        </ContentBox>
      </SplitPageContent>
    </SplitPage>
  );
}

export default Settings;
