import React, {
  useState, DragEvent, useRef, ChangeEvent,
} from 'react';
import styled from 'styled-components';
import { ImagePlus, MiniLoader } from '../icons';
import { Image } from '../atoms';

// STYLED ------------------------------------------------------------

const Empty = styled.div`
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Container = styled.div<{ isDragging : boolean }>`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${({ theme, isDragging }) => (isDragging ? theme.ui.default[700] : theme.gray[700])};
  border: 2px solid ${({ theme, isDragging }) => (isDragging ? theme.ui.default[400] : theme.gray[600])};
  color: ${({ theme, isDragging }) => (isDragging ? theme.ui.default[400] : theme.gray[500])};
  transition: all 150ms;
  cursor: default;
  :focus,
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.ui.default[700]};
    border: 2px solid ${({ theme }) => theme.ui.default[400]};
    color: ${({ theme }) => theme.ui.default[400]};
  }
  user-select: none;
`;

// TYPE ------------------------------------------------------------

type Props = {
  width?: number | string;
  height?: number | string ;
  style?: React.CSSProperties;
  src?: string;
  alt: string;
  onChange: (file: File) => void;
  loading?: boolean;
};

// COMPONENTS ------------------------------------------------------------

function ImageDrop({
  width, height, style, src, alt, onChange, loading,
} : Props) {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input element

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleFile = (file: File) => {
    onChange(file);
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDragging(false);
    const file = event.target.files && event.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0]; // Get the first file from the dropped files
    handleFile(file);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDropboxClick = () => {
    // Trigger click event on the file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Container
      style={{ height, width, ...style }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrag={handleDragOver}
      onClick={handleDropboxClick}
      isDragging={isDragging}
    >
      {src ? (
        <Image
          height="100%"
          width="100%"
          src={src}
          alt={alt}
          style={{
            opacity: loading ? 0.35 : 1,
          }}
        />
      ) : (
        <Empty>
          <ImagePlus style={{
            height: '100%',
            width: '100%',
            maxHeight: '32px',
            maxWidth: '32px',
          }}
          />
        </Empty>
      )}
      {loading && (
      <Empty>
        <MiniLoader style={{
          height: '100%',
          width: '100%',
          maxHeight: '32px',
          maxWidth: '32px',
        }}
        />
      </Empty>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".jpeg, .jpg, .png"
        onChange={handleFileInputChange}
      />
    </Container>
  );
}

export default ImageDrop;
