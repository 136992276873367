import React from 'react';
import { Counter } from '../../../common/Overlays';
import { Button } from '../../atoms';
import { OverlayActionProps } from '../Overlay';
import OverlayDataMiddleware, { OverlayDataProps } from '../OverlayDataMiddleware';

function ActionPrivate({ overlayData, updateOverlayData }: OverlayDataProps<Counter.Config, Counter.Data>) {
  const [loadingId, setLoadingId] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleReset = React.useCallback(async () => {
    setLoadingId('reset');
    const res = await updateOverlayData({
      value: overlayData.config.initialValue,
    });
    if (!res) setError('Failed to reset counter');
    setLoadingId(undefined);
  }, [updateOverlayData, overlayData.config.initialValue]);

  const handleIncrement = React.useCallback(async () => {
    setLoadingId('increment');
    const res = await updateOverlayData({
      value: overlayData.data.value + overlayData.config.incrementOffset,
    });
    if (!res) setError('Failed to increment counter');
    setLoadingId(undefined);
  }, [updateOverlayData, overlayData.data.value, overlayData.config.incrementOffset]);

  return (
    <>
      <p>
        Current Value:
        {}
        {overlayData.data.value}
      </p>
      <Button
        type="submit"
        label="Reset Counter"
        loading={loadingId === 'reset'}
        disabled={loadingId !== undefined && loadingId !== 'reset'}
        onClick={handleReset}
      />
      <Button
        type="submit"
        label="Increment"
        loading={loadingId === 'increment'}
        disabled={loadingId !== undefined && loadingId !== 'increment'}
        onClick={handleIncrement}
      />
      {error && <p>{error}</p>}
    </>
  );
}

export default function Action({ overlay, privateKey }: OverlayActionProps) {
  return <OverlayDataMiddleware overlay={overlay} privateKey={privateKey} component={ActionPrivate} />;
}
