import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import Page from '../components/common/Page';
import { Loader } from '../components/atoms';

// COMPONENTS ------------------------------------------------------------

function NeedAnonymous({ children }: { children: JSX.Element }) {
  const { loading, user } = useAuthentication();
  const location = useLocation();

  if (loading) return <Page><Loader /></Page>;

  if (user) return <Navigate to={location.state?.from || '/'} replace />;

  return children;
}

export default NeedAnonymous;
