import { updateDoc, serverTimestamp } from 'firebase/firestore';
import db from '../utils/db';
import { ActionResult } from '../utils/callable';

const setBetaKeyEmail = async (input: { email: string, betaKey: string }): Promise<ActionResult<boolean>> => {
  try {
    await updateDoc(
      db.betaKey(input.betaKey),
      'email',
      input.email,
      'timestamps.updatedAt',
      serverTimestamp(),
    );
    console.log('Beta key email updated', input);
    return { ok: true, data: true };
  } catch (e: any) {
    console.error(e);
    return { ok: false, error: e.message };
  }
};

export default setBetaKeyEmail;
