import { CustomRivenziRugby } from '../../../common/Overlays';
import Overlay from '../Overlay';
import Config from './Config';
import Display from './Display';
import Action from './Action';

const customRivenziRugby = new Overlay({
  type: CustomRivenziRugby.type,
  availability: CustomRivenziRugby.availability,
  displayName: 'Samedi Sport Rugby',
  description: 'Rugby Watch Party for Rivenzi',
  config: Config,
  display: Display,
  action: Action,
});

export default customRivenziRugby;
