import { httpsCallable } from 'firebase/functions';
import { functions } from './firebaseConfig';

export type ActionResult<TOut> = {
  ok: true
  data: TOut
} | {
  ok: false
  error: string
};

export const execCallable = async <TIn, TOut>(
  functionName: string,
  input: TIn,
): Promise<ActionResult<TOut>> => {
  try {
    const callable = httpsCallable<TIn, TOut>(functions, functionName);
    return {
      ok: true,
      data: (await callable(input)).data,
    };
  } catch (e: any) {
    console.error(e);
    return {
      ok: false,
      error: e.message,
    };
  }
};
