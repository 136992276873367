import styled from 'styled-components';
import { Label } from './form';

export const InputContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  width: 350px;
`;

export const InputBox = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${({ theme }) => (theme.gray[800])};
  padding-left: 16px;
  border-radius: 16px;
  position: relative;
  box-sizing: border-box;
  height: 44px;
`;

export const InputInfo = styled.p`
  color: ${({ theme }) => (theme.gray[400])};
  line-height: 140%;
  font-size: 12px;
  font-weight: 600;
  padding: 0 16px;
  min-height: 12px;
  white-space: pre-wrap;
`;

export const InputLabel = styled(Label)`
  flex: 1;
  font-weight: 600;
`;
