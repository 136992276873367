/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Library({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.96969 2.66238C9.37585 2.26144 8.59753 2.79348 8.7555 3.49237L9.38549 6.27965C9.46642 6.63771 9.29038 7.00439 8.96037 7.16517L6.39141 8.4167C5.74727 8.73051 5.81886 9.67057 6.5031 9.88321L9.23196 10.7313C9.58251 10.8402 9.81205 11.176 9.78628 11.5422L9.58565 14.3927C9.53535 15.1075 10.3853 15.5155 10.9115 15.0293L13.0104 13.09V13.09C13.182 12.9314 13.3895 12.7991 13.5063 12.5967L14.0083 11.7273C14.7131 10.5065 15.9784 9.93279 17.2099 10.0063C17.4114 10.0183 17.6152 9.99098 17.7967 9.90257L19.9365 8.86014C20.5806 8.54633 20.509 7.60627 19.8248 7.39363L17.0959 6.54558C16.7454 6.43663 16.5158 6.10084 16.5416 5.73465L16.7422 2.88411C16.7925 2.16936 15.9426 1.76133 15.4163 2.24758L13.3175 4.18681C13.0478 4.43592 12.6423 4.46681 12.338 4.2614L9.96969 2.66238Z"
        fill="currentColor"
      />
      <path
        d="M15.2403 12.7273C15.8002 11.7576 17.1998 11.7576 17.7597 12.7273L21.8536 19.8182C22.4135 20.7879 21.7136 22 20.5939 22H12.4061C11.2863 22 10.5865 20.7879 11.1464 19.8182L15.2403 12.7273Z"
        fill="currentColor"
      />
      <path
        d="M9.91436 18.8182C10.2847 18.1768 9.69642 17.254 9.0447 16.9022C8.14809 16.4182 7.50565 15.4592 7.59059 14.2523L7.66837 13.1471C7.70095 12.6842 7.41077 12.2597 6.96761 12.1219L5.90955 11.7931C5.70271 11.7288 5.51173 11.6457 5.33685 11.5468C4.90552 11.3027 4.35358 11.2092 3.98538 11.5409C2.76637 12.6391 2 14.2301 2 16C2 19.3137 4.68629 22 8 22C8.20555 22 8.40868 21.9897 8.6089 21.9695C9.19029 21.9109 9.47912 21.2901 9.45046 20.7065C9.41968 20.0795 9.56193 19.4286 9.91436 18.8182V18.8182Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Library;
