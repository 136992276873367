import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { createWorkspace, relinkTwitch } from '../actions';
import { Loader } from '../components/atoms';

// STYLE ------------------------------------------------------------

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// COMPONENTS ------------------------------------------------------------

function AuthenticationTwitchCreateWorkspace({ code }: { code: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    createWorkspace({ code })
      .then((res) => {
        if (res.ok) {
          navigate(`/workspace/${res.data.workspaceId}`, { replace: true });
        } else {
          console.error(res.error);
          // TODO toast error
          navigate('/', { replace: true });
        }
      });
  }, []);

  return <Page><Loader>Collecting Twitch infos...</Loader></Page>;
}

function AuthenticationTwitchRelinkTwitch({ code, workspaceId }: { code: string, workspaceId: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    relinkTwitch({ code, workspaceId })
      .then((res) => {
        if (res.ok) {
          navigate(`/workspace/${res.data.workspaceId}`, { replace: true });
        } else {
          console.error(res.error);
          // TODO toast error
          navigate('/', { replace: true });
        }
      });
  }, []);

  return <Page><Loader>Collecting Twitch infos...</Loader></Page>;
}

function AuthenticationTwitch() {
  const { search } = useLocation();
  const { code, scope, state } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      code: params.get('code') || undefined,
      scope: params.get('scope') || undefined,
      state: params.get('state') || undefined,
    };
  }, [search]);

  if (code && scope && state === 'createWorkspace') {
    return <AuthenticationTwitchCreateWorkspace code={code} />;
  }

  const splitState = state?.split('|');

  if (code && scope && splitState && splitState.length === 2 && splitState[0] === 'relinkTwitch') {
    return <AuthenticationTwitchRelinkTwitch code={code} workspaceId={splitState[1]} />;
  }

  return <Navigate to="/" replace />;
}

export default AuthenticationTwitch;
