import { execCallable } from '../utils/callable';
import {
  updateOverlayFunctionName,
  UpdateOverlayInput,
  UpdateOverlayOutput,
} from '../common/Actions';

const updateOverlay = (
  input: { workspaceId: string, overlayId: string, name: string, color: UpdateOverlayInput['color'] },
) => execCallable<UpdateOverlayInput, UpdateOverlayOutput>(
  updateOverlayFunctionName,
  {
    workspaceId: input.workspaceId,
    overlayId: input.overlayId,
    name: input.name,
    color: input.color,
  },
);

export default updateOverlay;
