import { useEffect, useState } from 'react';
import { onSnapshot, DocumentReference, Query } from 'firebase/firestore';

type FirestoreItemState<T> = {
  loading: true
  error: undefined
  item: undefined
} | {
  loading: false
  error: string
  item: undefined
} | {
  loading: false
  error: undefined
  item?: T
};
export const useBaseFirestoreItem = <T>(docRef: DocumentReference<T>): FirestoreItemState<T> => {
  const [state, setState] = useState<FirestoreItemState<T>>({ loading: true, error: undefined, item: undefined });

  useEffect(() => onSnapshot(docRef, async (snapshot) => {
    // await new Promise((resolve) => { setTimeout(resolve, 3000); });
    if (snapshot.exists()) {
      setState({ loading: false, error: undefined, item: snapshot.data() });
    } else {
      setState({ loading: false, error: undefined, item: undefined });
    }
  }, (e) => {
    if (e.message === 'Missing or insufficient permissions.') {
      setState({ loading: false, error: undefined, item: undefined });
      return;
    }
    setState({ loading: false, error: e.message, item: undefined });
  }), [docRef]);

  return state;
};

type FirestoreListState<T> = {
  loading: true
  error: undefined
  items: undefined
} | {
  loading: false
  error: string
  items: undefined
} | {
  loading: false
  error: undefined
  items: T[]
};
export const useBaseFirestoreList = <T>(query: Query<T>): FirestoreListState<T> => {
  const [state, setState] = useState<FirestoreListState<T>>({ loading: true, error: undefined, items: undefined });

  useEffect(() => onSnapshot(query, async (snapshot) => {
    const res = snapshot.docs.map((doc) => ({ ...doc.data() }));
    // await new Promise((resolve) => { setTimeout(resolve, 3000); });
    setState({ loading: false, error: undefined, items: res });
  }, (e) => {
    // TODO: manage code
    setState({ loading: false, error: e.message, items: undefined });
  }), [query]);

  return state;
};
