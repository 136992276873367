import React, { useState } from 'react';
import styled from 'styled-components';
import { Image as ImgIcon, MiniLoader } from '../icons';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  position: relative;
`;

const Empty = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.gray[700]};
  padding: 12px;
  color: ${({ theme }) => theme.gray[500]};
`;

const Img = styled.img`
  object-fit: cover;
`;

// TYPE ------------------------------------------------------------

type Props = {
  width?: number | string;
  height?: number | string ;
  style?: React.CSSProperties;
  src?: string;
  alt: string;
};

// COMPONENTS ------------------------------------------------------------

function Image({
  width, height, style, src, alt,
} : Props) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  if (!src) {
    return (
      <Empty style={{ height, width, ...style }}>
        <ImgIcon style={{ maxHeight: '32px', maxWidth: '32px' }} />
      </Empty>
    );
  }
  return (
    <Container
      style={{
        height,
        width,
        ...style,
      }}
    >
      {isLoading && (
        <Empty style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          ...style,
        }}
        >
          <MiniLoader style={{ maxHeight: '32px', maxWidth: '32px' }} />
        </Empty>
      )}
      <Img
        width="100%"
        height="100%"
        style={style}
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
      />
    </Container>
  );
}

export default Image;
