import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const H1 = styled.h1`
  line-height: 100%;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  color: ${({ theme }) => theme.gray[100]};
`;

export const FormContainer = styled.form`
  color: ${({ theme }) => theme.gray[500]};
  background-color: ${({ theme }) => theme.gray[800]};
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  padding: 16px;
  gap: 12px;
  max-width: 384px;
  width: 100%;
  align-items: center;
  > button {
    width: 100%;
  }
`;

export const OtherOptionSection = styled.div`
  width: 100%;
  padding-top: 12px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 140%;
  color: ${({ theme }) => theme.gray[400]};
`;

export const ParagraphContainer = styled.div`
  padding: 0px 16px;
  width: 100%;
  color: ${({ theme }) => theme.gray[500]};
  text-align: center;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Spacer = styled.div`
  width: 100%;
  height: 4px;
`;

export const Mail = styled(P)`
  color: ${({ theme }) => (theme.gray[300])};
`;
