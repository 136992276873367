import { OverlayColor } from './Collections';

export const generateTwitchUrlFunctionName = 'generateTwitchUrl';
export type GenerateTwitchUrlInput = {
  origin: string
  state: string
};
export type GenerateTwitchUrlOutput = {
  url: string
};

export const createWorkspaceFunctionName = 'createWorkspace';
export type CreateWorkspaceInput = {
  name?: string
  code: string
  origin: string
};
export type CreateWorkspaceOutput = {
  workspaceId: string
};

export const updateWorkspaceNameFunctionName = 'updateWorkspaceName';
export type UpdateWorkspaceNameInput = {
  workspaceId: string
  name: string
};
export type UpdateWorkspaceNameOutput = {
  workspaceId: string
};

export const unlinkTwitchFunctionName = 'unlinkTwitch';
export type UnlinkTwitchInput = {
  workspaceId: string
};
export type UnlinkTwitchOutput = {
  workspaceId: string
};

export const relinkTwitchFunctionName = 'relinkTwitch';
export type RelinkTwitchInput = {
  workspaceId: string
  code: string
  origin: string
};
export type RelinkTwitchOutput = {
  workspaceId: string
};

export const deleteWorkspaceFunctionName = 'deleteWorkspace';
export type DeleteWorkspaceInput = {
  workspaceId: string
};
export type DeleteWorkspaceOutput = {
  workspaceId: string
};

export const createOverlayFunctionName = 'createOverlay';
export type CreateOverlayInput = {
  name?: string
  workspaceId: string
  type: string
  color?: OverlayColor
};
export type CreateOverlayOutput = {
  workspaceId: string
  overlayId: string
};

export const duplicateOverlayFunctionName = 'duplicateOverlay';
export type DuplicateOverlayInput = {
  name?: string
  workspaceId: string
  overlayId: string
};
export type DuplicateOverlayOutput = {
  workspaceId: string
  overlayId: string
};

export const updateOverlayFunctionName = 'updateOverlay';
export type UpdateOverlayInput = {
  workspaceId: string
  overlayId: string
  name: string
  color: OverlayColor
};
export type UpdateOverlayOutput = {
  workspaceId: string
  overlayId: string
};

export const updateOverlayConfigFunctionName = 'updateOverlayConfig';
export type UpdateOverlayConfigInput = {
  workspaceId: string
  overlayId: string
  config: Record<string, any>
};
export type UpdateOverlayConfigOutput = {
  workspaceId: string
  overlayId: string
};

export const resetOverlayPrivateKeyFunctionName = 'resetOverlayPrivateKey';
export type ResetOverlayPrivateKeyInput = {
  workspaceId: string
  overlayId: string
};
export type ResetOverlayPrivateKeyOutput = {
  workspaceId: string
  overlayId: string
};

export const deleteOverlayFunctionName = 'deleteOverlay';
export type DeleteOverlayInput = {
  workspaceId: string
  overlayId: string
};
export type DeleteOverlayOutput = {
  workspaceId: string
  overlayId: string
};

export const getTwitchEmotesFunctionName = 'getTwitchEmotes';
export type GetTwitchEmotesInput = {
  workspaceId: string
};
export type TwitchEmote = {
  id: string
  name: string
  isAnimated: boolean
};
export type GetTwitchEmotesOutput = {
  workspaceId: string
  globalEmotes: TwitchEmote[]
  channelEmotes: TwitchEmote[]
};
