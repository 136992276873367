import { css } from 'styled-components';
import { UIColor } from '../../../theme';

export * from './form';
export * from './input';
export * from './squeleton';

export const shadow10 = css`
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 1));
`;

export const shadow99 = css`
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
`;

export function StateToBorder(
  theme: any,
  color: UIColor,
  state: string,
  isFocus?: boolean,
  disabled?: boolean,
) {
  if (disabled) {
    return theme.gray[700];
  }
  if (isFocus) {
    return theme.getUIColor(color, 500);
  }
  switch (state) {
    case 'error':
      return theme.states.error[500];
    case 'warning':
      return theme.states.warning[500];
    default:
      return 'transparent';
  }
}
