import styled, { keyframes, css } from 'styled-components';

export const tilting = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.5;
  }
`;

export const Skeleton = css`
  animation: ${tilting};
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
  animation-iteration-count: infinite;
`;

export const SkeletonBox = styled.div`
  ${Skeleton}
  background-color: currentColor;
  border-radius: 4px;
`;
