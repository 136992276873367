import { execCallable } from '../utils/callable';
import {
  resetOverlayPrivateKeyFunctionName,
  ResetOverlayPrivateKeyInput,
  ResetOverlayPrivateKeyOutput,
} from '../common/Actions';

const resetOverlayPrivateKey = (
  input: { workspaceId: string, overlayId: string },
) => execCallable<ResetOverlayPrivateKeyInput, ResetOverlayPrivateKeyOutput>(
  resetOverlayPrivateKeyFunctionName,
  {
    workspaceId: input.workspaceId,
    overlayId: input.overlayId,
  },
);

export default resetOverlayPrivateKey;
