import React from 'react';
import styled from 'styled-components';
import { OptionButton as WorkspaceButton } from '../components/workspace/Creator';
import { generateTwitchUrl } from '../actions';
import { ButtonLink, Loader } from '../components/atoms';
import NavBar, { NavBarLink } from '../components/common/NavBar';
import Page from '../components/common/Page';
import Logo from '../components/common/Logo';
import { H1 } from '../components/authentication/style';
import { Gear, Twitch } from '../components/icons';

// COMPONENTS ------------------------------------------------------------
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  overflow: hidden;
  padding: 16px;
  background-color: ${({ theme }) => theme.gray[800]};
  gap: 16px;
  align-items: center;
  width: 100%;
  max-width: 385px;
`;

function NewWorkspace() {
  const [createLoading, setCreateLoading] = React.useState(false);

  const handleCreateWorkspace = async () => {
    setCreateLoading(true);
    const res = await generateTwitchUrl({ state: 'createWorkspace' });
    if (res.ok) {
      window.location.replace(res.data.url);
    } else {
      // TODO toast error
    }
    setCreateLoading(false);
  };

  if (createLoading) {
    return <Page><Loader>Creating your account...</Loader></Page>;
  }

  return (
    <Page>
      <NavBar style={{ position: 'absolute', top: '16px', left: '16px' }}>
        <NavBarLink icon={<Gear size={20} />} label="settings" to="/settings" />
      </NavBar>
      <Logo style={{ maxWidth: 200 }} />
      <H1>Create a new workspace</H1>
      <Container>
        <WorkspaceButton
          icon={<Twitch size={20} />}
          label="Setup with twitch"
          color="#9246FF"
          onClick={handleCreateWorkspace}
        />
        <ButtonLink label="back" variant="text" to="/" />
      </Container>
    </Page>
  );
}

export default NewWorkspace;
