import { Counter } from '../../../common/Overlays';
import Overlay from '../Overlay';
import Config from './Config';
import Display from './Display';
import Action from './Action';

const counter = new Overlay({
  type: Counter.type,
  availability: Counter.availability,
  displayName: 'Counter',
  description: 'A simple counter that increments when you click it.',
  config: Config,
  display: Display,
  action: Action,
});

export default counter;
