import { execCallable } from '../utils/callable';
import {
  createOverlayFunctionName,
  CreateOverlayInput,
  CreateOverlayOutput,
} from '../common/Actions';

const createOverlay = (
  input: { name?: string, workspaceId: string, type: string, color?: CreateOverlayInput['color'] },
) => execCallable<CreateOverlayInput, CreateOverlayOutput>(
  createOverlayFunctionName,
  {
    name: input.name,
    workspaceId: input.workspaceId,
    type: input.type,
    color: input.color,
  },
);

export default createOverlay;
