const getOverlayUrl = (path: string): string => {
  if (window.location.origin === 'http://localhost:3000') {
    return `http://localhost:3001${path}`;
  }
  if (window.location.origin.includes('.web.app')) {
    return `${window.location.origin.replace('.web.app', '-overlay.web.app')}${path}`;
  }
  return `${window.location.origin.replace('dashboard', 'overlay')}${path}`;
};

export default getOverlayUrl;
