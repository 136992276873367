import { CustomRivenziPollEmote } from '../../../common/Overlays';
import Overlay from '../Overlay';
import Config from './Config';
import Display from './Display';
import Action from './Action';

const customRivenziPollEmote = new Overlay({
  type: CustomRivenziPollEmote.type,
  availability: CustomRivenziPollEmote.availability,
  displayName: 'Poll Emote',
  description: 'Poll Emote for Rivenzi',
  config: Config,
  display: Display,
  action: Action,
});

export default customRivenziPollEmote;
