/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Calendar({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C8.10457 2 9 2.89543 9 4C9 4.55228 9.44772 5 10 5H14C14.5523 5 15 4.55228 15 4C15 2.89543 15.8954 2 17 2C18.1046 2 19 2.89543 19 4C19 4.55228 19.4745 4.97928 19.959 5.24447C20.5793 5.58406 21 6.24291 21 7V9C21 9.55228 20.5523 10 20 10H4C3.44772 10 3 9.55228 3 9V7C3 6.24291 3.42067 5.58406 4.04103 5.24447C4.52548 4.97928 5 4.55228 5 4C5 2.89543 5.89543 2 7 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20L19 20L19 13L5 13L5 20ZM19 22C20.1046 22 21 21.1046 21 20L21 12C21 11.4477 20.5523 11 20 11L4 11C3.44771 11 3 11.4477 3 12L3 20C3 21.1046 3.89543 22 5 22L19 22Z"
        fill="currentColor"
      />
      <path
        d="M6 15C6 14.4477 6.44772 14 7 14H10C10.5523 14 11 14.4477 11 15V16C11 16.5523 10.5523 17 10 17H7C6.44772 17 6 16.5523 6 16V15Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Calendar;
