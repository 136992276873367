export type Result<TOut> = {
  ok: true
  data: TOut
} | {
  ok: false
  error: string
};

type SearchMatchApiResult = {
  meta: {
    title: string
    description: string
    field: Record<string, unknown>
  }
  results: Array<{
    away: string
    away_id: number
    away_score: number
    away_tries: number
    comp_id: number
    comp_name: string
    date: string
    game_week: number
    home: string
    home_id: number
    home_score: number
    home_tries: number
    id: number
    season: string
    status: string
    updated: string
    venue: string
  }>
};

export type SearchMatchResult = {
  totalRequests?: number
  remainingRequests?: number
  matches: Array<{
    id: string
    homeName: string
    awayName: string
    date: string
  }>
};

export const searchMatchForDate = async (input: { date: string, rapidApiKey: string }): Promise<Result<SearchMatchResult>> => {
  const url = `https://rugby-live-data.p.rapidapi.com/fixtures-by-date/${input.date}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': input.rapidApiKey,
        'X-RapidAPI-Host': 'rugby-live-data.p.rapidapi.com',
      },
    });
    if (response.status === 403) {
      return { ok: false, error: 'Invalid API key' };
    }
    if (response.status === 429) {
      return { ok: false, error: (await response.json() as { message: string }).message };
    }
    const body = await response.json() as SearchMatchApiResult | { error: string };
    if ('error' in body) {
      return { ok: false, error: body.error };
    }
    if (response.status !== 200) {
      return { ok: false, error: 'Unknown error' };
    }
    return {
      ok: true,
      data: {
        // eslint-disable-next-line max-len
        totalRequests: response.headers.get('X-Ratelimit-Requests-Limit') ? parseInt(response.headers.get('X-Ratelimit-Requests-Limit') as string, 10) : undefined,
        // eslint-disable-next-line max-len
        remainingRequests: response.headers.get('X-Ratelimit-Requests-Remaining') ? parseInt(response.headers.get('X-Ratelimit-Requests-Remaining') as string, 10) : undefined,
        matches: body.results.map((match) => ({
          id: match.id.toString(),
          homeName: match.home,
          awayName: match.away,
          date: match.date,
        })),
      },
    };
  } catch (error) {
    console.error(error);
    return { ok: false, error: 'Error searching match for date' };
  }
};
