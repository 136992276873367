import React, { useState, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { UIColor } from '../../theme';
import { shadow99 } from './common';
import useOnClickOutside from '../../hooks/useClickOutside';

// STYLED ------------------------------------------------------------

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.gray[700]};
  max-width: 250px;
`;

const Cell = css<{ selected? : boolean }>`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  transition: border 150ms;
  border: 2px solid ${({ theme, selected }) => (selected ? theme.gray[400] : theme.gray[600])};
  ::-webkit-color-swatch {
    border: none;
  }
  :focus,
  :hover {
    cursor: 'pointer';
    border: 2px solid ${({ theme, selected }) => (selected ? theme.gray[400] : 'transparent')};
  }
  :disabled {
    cursor: '';
    border: 2px solid ${({ theme }) => theme.gray[600]};
  }
`;

const CellButton = styled.button<{ selected? : boolean }>`
  ${Cell}
`;

const CellInput = styled.input<{ selected? : boolean }>`
  ${Cell}
`;

const Floating = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: -8px;
  width: 250px;
  ${shadow99}
`;

// TYPE ------------------------------------------------------------

type Props = {
  compact?: boolean;
  onChange: (color: Exclude<UIColor, 'default'>) => void;
  value: Exclude<UIColor, 'default'>;
};

type PropsPicker = {
  onChange: (color: Exclude<UIColor, 'default'>) => void;
  value: Exclude<UIColor, 'default'>;
};

type PropsCell = {
  selected? : boolean;
  value: string
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties
};

type PropsRestrictedCell = {
  selected? : boolean;
  value: Exclude<UIColor, 'default'>
  onClick?: () => void;
};

// COMPONENTS ------------------------------------------------------------

export function ColorCell({
  selected = false, value, onClick, disabled, style,
} : PropsCell) {
  return (
    <CellInput
      value={value}
      selected={selected}
      onClick={onClick}
      aria-label={`color ${value}`}
      type="color"
      style={{ backgroundColor: value, ...style }}
      disabled={disabled}
    />
  );
}

function RestrictedColorCell({ selected = false, value, onClick } : PropsRestrictedCell) {
  const theme = useTheme();

  return (
    <CellButton
      color={value}
      selected={selected}
      onClick={onClick}
      aria-label={`color ${value}`}
      type="button"
      style={{ backgroundColor: theme.getUIColor(value, 500) }}
    />
  );
}

function ColorList({ onChange, value } : PropsPicker) {
  return (
    <ListContainer>
      <RestrictedColorCell value="lavender" onClick={() => onChange('lavender')} selected={value === 'lavender'} />
      <RestrictedColorCell value="blue" onClick={() => onChange('blue')} selected={value === 'blue'} />
      <RestrictedColorCell value="sky" onClick={() => onChange('sky')} selected={value === 'sky'} />
      <RestrictedColorCell value="cyan" onClick={() => onChange('cyan')} selected={value === 'cyan'} />
      <RestrictedColorCell value="green" onClick={() => onChange('green')} selected={value === 'green'} />
      <RestrictedColorCell value="lemon" onClick={() => onChange('lemon')} selected={value === 'lemon'} />
      <RestrictedColorCell value="yellow" onClick={() => onChange('yellow')} selected={value === 'yellow'} />
      <RestrictedColorCell value="orange" onClick={() => onChange('orange')} selected={value === 'orange'} />
      <RestrictedColorCell value="spice" onClick={() => onChange('spice')} selected={value === 'spice'} />
      <RestrictedColorCell value="red" onClick={() => onChange('red')} selected={value === 'red'} />
      <RestrictedColorCell value="pink" onClick={() => onChange('pink')} selected={value === 'pink'} />
      <RestrictedColorCell value="purple" onClick={() => onChange('purple')} selected={value === 'purple'} />
    </ListContainer>
  );
}

function Compact({ onChange, value } : PropsPicker) {
  const [pickerVisible, setPickerVisible] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(boxRef, () => setPickerVisible(false));

  return (
    <div style={{ position: 'relative' }}>
      <RestrictedColorCell value={value} onClick={() => setPickerVisible(true)} />
      {pickerVisible && (
        <Floating ref={boxRef}>
          <ColorList
            onChange={(color) => {
              onChange(color);
              setPickerVisible(false);
            }}
            value={value}
          />
        </Floating>
      )}
    </div>
  );
}

export function PageColorPicker({ compact = false, onChange, value } : Props) {
  if (compact) {
    return (
      <Compact onChange={onChange} value={value} />
    );
  }
  return (
    <ColorList onChange={onChange} value={value} />
  );
}
