import React from 'react';
import * as Collections from '../../common/Collections';

export type OverlayConfigProps = {
  overlay: Collections.OverlayDocument
  config: Record<string, any>
};
export type OverlayConfigComponent = React.ComponentType<OverlayConfigProps>;

export type OverlayDisplayProps = {
  overlay: Collections.OverlayDocument
  privateKey: string
};
export type OverlayDisplayComponent = React.ComponentType<OverlayDisplayProps>;

export type OverlayActionProps = {
  overlay: Collections.OverlayDocument
  privateKey: string
};
export type OverlayActionComponent = React.ComponentType<OverlayActionProps>;

export default class Overlay {
  public readonly type: string;

  public readonly availability: 'free' | 'premium' | 'custom';

  private readonly configComponent: OverlayConfigComponent;

  private readonly displayComponent: OverlayDisplayComponent;

  private readonly actionComponent: OverlayActionComponent;

  public readonly displayName: string;

  public readonly description: string;

  constructor(input: {
    type: string,
    availability: 'free' | 'premium' | 'custom',
    displayName: string,
    description: string,
    config: OverlayConfigComponent,
    display: OverlayDisplayComponent,
    action: OverlayActionComponent,
  }) {
    this.type = input.type;
    this.availability = input.availability;
    this.displayName = input.displayName;
    this.description = input.description;
    this.configComponent = input.config;
    this.displayComponent = input.display;
    this.actionComponent = input.action;
  }

  renderConfig({ overlay, config }: OverlayConfigProps) {
    return <this.configComponent overlay={overlay} config={config} />;
  }

  renderDisplay({ overlay, privateKey }: OverlayDisplayProps) {
    return <this.displayComponent overlay={overlay} privateKey={privateKey} />;
  }

  renderAction({ overlay, privateKey }: OverlayActionProps) {
    return <this.actionComponent overlay={overlay} privateKey={privateKey} />;
  }
}
