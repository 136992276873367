import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UIColor } from '../../theme';
import Button from './Button';
import { Minus, Plus } from '../icons';
import {
  InputContainer, InputInfo, InputLabel, StateToBorder, InputBox,
} from './common';

// STYLED ------------------------------------------------------------

const ValueType = styled(InputInfo)`
  padding: 0;
  pointer-events: none;
  user-select: none;
`;

const Box = styled.div<{ isFocus: boolean, state : string, disabled?: boolean, color : UIColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background-color: ${({ theme }) => (theme.gray[900])};
  border-radius: 16px;
  min-width: 100px;
  min-height: 44px;
  transition: all 300ms;
  &:hover {
    cursor:  ${({ disabled }) => (!disabled ? 'text' : 'default')};
  }
  border: 2px solid ${({
    theme, color, state, isFocus, disabled,
  }) => (StateToBorder(theme, color, state, isFocus, disabled))} ;
`;

const TextInputBox = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
`;

const Value = styled.span`
  opacity: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  max-width: 40px;
  font-family: 'Public Sans', sans-serif;
`;

const Input = styled.input`
  color: ${({ disabled, theme }) => (!disabled ? theme.gray[100] : theme.gray[500])};
  background-color: transparent;
  font-weight: 600;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 16px;
  line-height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  max-width: 40px;
  font-family: 'Public Sans', sans-serif;
  -webkit-appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

// TYPE ------------------------------------------------------------

type NumberInputProps = {
  label: string
  valueType?: string;
  style?: React.CSSProperties;
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  increment?: number;
  disabled?: boolean;
  info?: string;
  state?: 'default' | 'warning' | 'error';
  color?: UIColor;
};

// COMPONENTS ------------------------------------------------------------

function NumberInput({
  label,
  valueType,
  onChange,
  value,
  disabled = false,
  info,
  min,
  max,
  increment = 1,
  state = 'default',
  color = 'default',
  style,
} : NumberInputProps) {
  const [focus, setFocus] = useState(false);
  const refInput = useRef<HTMLInputElement>(null);
  const [displayValue, setDisplayValue] = useState(value.toString());

  useEffect(
    () => {
      setDisplayValue(value.toString());
    },
    [value],
  );

  const forceFocus = () => {
    if (refInput.current) {
      refInput.current.focus();
    }
  };

  const handleMaxMin = (newValue : number) => {
    if (max !== undefined && newValue >= max) {
      return max;
    } if (min !== undefined && newValue <= min) {
      return min;
    }
    return newValue;
  };

  const handleUpdate = (newValue: string | number) => {
    const v = typeof newValue === 'string'
      ? handleMaxMin(Number(newValue)) : handleMaxMin(newValue);
    onChange(v);
    setDisplayValue(v.toString());
  };

  const handleChange = (newValue: string) => {
    onChange(Number(newValue));
    setDisplayValue(newValue);
  };

  const minus = () => {
    handleUpdate(value - increment);
  };

  const add = () => {
    handleUpdate(value + increment);
  };

  return (
    <InputContainer style={style}>
      <InputBox>
        <InputLabel>{label}</InputLabel>
        <Button
          variant="tonal"
          color={color}
          icon={<Minus size={20} />}
          onClick={minus}
          disabled={disabled || (min !== undefined && value <= min)}
        />
        <Box isFocus={focus} onClick={forceFocus} state={state} disabled={disabled} color={color}>
          <TextInputBox>
            <Value>{displayValue}</Value>
            <Input
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                handleUpdate(value);
              }}
              ref={refInput}
              min={min}
              max={max}
              type="number"
              value={displayValue}
              onChange={(e) => handleChange(e.target.value)}
              disabled={disabled}
            />
            { valueType && <ValueType>{valueType}</ValueType>}
          </TextInputBox>
        </Box>
        <Button
          variant="tonal"
          color={color}
          icon={<Plus size={20} />}
          onClick={add}
          disabled={disabled || (max !== undefined && value >= max)}
        />
      </InputBox>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

export default NumberInput;
