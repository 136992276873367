import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { UIColor } from '../../theme';
import { InputContainer, InputInfo, StateToBorder } from './common';

// STYLED ------------------------------------------------------------

const Box = styled.div<{ isFocus: boolean, state : string, disabled?: boolean, color : UIColor }>`
  display: flex;
  padding: 14px;
  background-color: ${({ theme }) => (theme.gray[900])};
  border: 2px solid ${({
    theme, color, state, isFocus, disabled,
  }) => (StateToBorder(theme, color, state, isFocus, disabled))} ;
  transition: all 300ms;
  &:hover {
    cursor:  ${({ disabled }) => (!disabled ? 'text' : 'default')};
  }
  border-radius: 16px;
  gap: 8px;
`;

const AreaBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0;
`;

const Area = styled.textarea<{ disabled?: boolean }>`
  color: ${({ theme }) => (theme.gray[100])};
  background-color: transparent;
  font-weight: 600;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 16px;
  line-height: 140%;
  font-family: 'Public Sans', sans-serif;
  resize: none;
  transition: all 300ms;
  &::placeholder {
    color: ${({ theme }) => (theme.gray[500])};
  }
  &:focus {
    color: ${({ theme }) => (theme.gray[100])};
  }
`;

// TYPE ------------------------------------------------------------

type TextFieldProps = {
  style?: React.CSSProperties;
  placeholder?: string;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  info?: string;
  state?: 'default' | 'warning' | 'error';
  color?: UIColor;
  rows?: number;
};

// COMPONENTS ------------------------------------------------------------

function TextArea({
  placeholder = '',
  onChange,
  value,
  disabled = false,
  info,
  state = 'default',
  color = 'default',
  style,
  rows = 4,
} : TextFieldProps) {
  const [focus, setFocus] = useState(false);
  const refInput = useRef<HTMLTextAreaElement>(null);

  const forceFocus = () => {
    if (refInput.current) {
      refInput.current.focus();
    }
  };

  return (
    <InputContainer style={{ width: '100%', ...style }}>
      <Box isFocus={focus} onClick={forceFocus} state={state} disabled={disabled} color={color}>
        <AreaBox>
          <Area
            ref={refInput}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={value}
            placeholder={placeholder}
            onChange={(e) => { onChange(e.target.value); }}
            disabled={disabled}
            rows={rows}
          />
        </AreaBox>
      </Box>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

export default TextArea;
