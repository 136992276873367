/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  Button, P, shadow99,
} from '../../atoms';
import { StateColor, UIColor } from '../../../theme';
import { MiniLoader } from '../../icons';

// STYLED ------------------------------------------------------------

const Container = styled(motion.div)<{ color : UIColor | StateColor }>`
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 8px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.gray[700]};
  border: 2px solid ${({ theme }) => (`${theme.gray[600]}50`)};
  padding: 6px;
  min-height: 60px;
  height: auto;
  ${shadow99}
`;

const PContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: auto;
  ${P} {
    color: ${({ theme }) => theme.gray[100]};
    padding: 8px;
    width: auto;
    height: auto;
  }
`;

const LoadingContainer = styled(motion.div)<{ color : UIColor | StateColor }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) => theme.getColor(color, 500)};
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  border-radius: 22px;
  ${P} {
    color: ${({ theme }) => theme.gray[100]};
    padding: 8px;
    width: auto;
    height: auto;
  }
`;

// TYPE ------------------------------------------------------------

type Props = {
  info : string
  style?: React.CSSProperties;
  confirmLabel?: string;
  cancelLabel?: string;
  onCancel: () => void;
  onConfirm: () => void;
  color?: UIColor | StateColor;
  loading?: boolean;
};

// COMPONENTS ------------------------------------------------------------

export function InlineNotification({
  info,
  style,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onCancel,
  onConfirm,
  color = 'default',
  loading = false,
} : Props) {
  return (
    <Container
      style={style}
      color={color}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ ease: [0.76, 0, 0.24, 1], duration: 0.15 }}
    >
      <PContainer><P>{info}</P></PContainer>
      <Button onClick={onCancel} variant="tonal" label={cancelLabel} style={{ height: 'auto' }} />
      <Button onClick={onConfirm} label={confirmLabel} style={{ height: 'auto' }} />
      {loading && (
      <LoadingContainer
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        exit={{ width: 0 }}
        color={color}
        transition={{ ease: [0.76, 0, 0.24, 1], duration: 0.5 }}
      >
        <MiniLoader size={20} />
      </LoadingContainer>
      )}
    </Container>

  );
}

export default InlineNotification;
