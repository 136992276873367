import React, { useCallback, useState, useMemo } from 'react';
import { Button, TextField } from '../atoms';
import { EmailIsValid } from '../../utils/validation';
import {
  Container,
  H1,
  FormContainer,
  OtherOptionSection,
} from './style';
import Error from './AuthenticationError';
import useAuthentication, { AuthErrorCodes } from '../../hooks/useAuthentication';

// TYPE ------------------------------------------------------------

type ForgottenPasswordFormProps = {
  setLogin: () => void
};

// COMPONENTS ------------------------------------------------------------

function ForgottenPasswordForm({ setLogin }: ForgottenPasswordFormProps) {
  const { sendPasswordResetEmail } = useAuthentication();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const errorEmail: string | undefined = useMemo(() => {
    if (email.length > 0 && !EmailIsValid(email)) {
      return '*bad email format';
    }
    return undefined;
  }, [email]);

  const submit = useCallback(async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    const resetError = await sendPasswordResetEmail({ email });
    if (resetError) {
      if (resetError === AuthErrorCodes.INVALID_AUTH) {
        setError('Wrong email and/or password');
        // TODO manage others errors
      } else {
        setError(resetError);
      }
    } else {
      // TODO popup success
    }
    setLoading(false);
  }, [email, sendPasswordResetEmail]);

  return (
    <Container>
      <H1>Reset Password</H1>
      <FormContainer onSubmit={submit}>
        { error && <Error label={error} />}
        <TextField
          label="email"
          type="email"
          value={email}
          onChange={setEmail}
          disabled={loading}
          state={error ? 'error' : undefined}
          info={errorEmail}
        />
        <Button
          type="submit"
          label="Reset Password"
          disabled={email === '' || loading || errorEmail !== undefined}
          loading={loading}
        />
      </FormContainer>
      <OtherOptionSection>
        <Button
          variant="text"
          label="Back to sign in"
          onClick={setLogin}
        />
      </OtherOptionSection>
    </Container>
  );
}

export default ForgottenPasswordForm;
