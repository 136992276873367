import React from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { StateColor, UIColor } from '../../theme';
import {
  InputBox, InputContainer, InputInfo, InputLabel,
} from './common';
import { Check, Cross } from '../icons';

// STYLED ------------------------------------------------------------

const SwitchButton = styled.button`
justify-content: flex-end;
  background-color: ${({ theme }) => (theme.gray[900])};
  border-radius: 16px;
  height: 33px;
  width: 61px;
  padding: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const SwitchCursor = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({ isChecked, ...props }) => <motion.div {...props} />,
)<{ isChecked?: boolean, color: UIColor | StateColor }>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  color: ${({ theme, isChecked }) => (isChecked ? theme.gray[100] : theme.gray[400])};
  background-color: ${({ theme, color, isChecked }) => (isChecked ? theme.getColor(color, 500) : theme.gray[600])};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s, color 0.2s;
  padding: 4px;
`;

// TYPE ------------------------------------------------------------

type SwitchProps = {
  label: string
  style?: React.CSSProperties;
  onChange: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
  info?: string;
  color?: UIColor | StateColor;
  boxStyle?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Switch({
  label,
  onChange,
  value,
  disabled = false,
  info,
  color = 'default',
  style,
  boxStyle,
} : SwitchProps) {
  const controls = useAnimation();

  const handleCheckboxChange = async () => {
    onChange(!value);
    await controls.start({
      x: value ? 0 : 27,
      width: [24, 35, 24],
    });
  };

  return (
    <InputContainer style={style}>
      <InputBox style={{ paddingRight: '4px', ...boxStyle }}>
        <InputLabel>{label}</InputLabel>
        <SwitchButton
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            handleCheckboxChange();
          }}
        >
          <SwitchCursor
            isChecked={value}
            color={color}
            initial={{ x: value ? 27 : 0 }}
            animate={controls}
            transition={{ ease: [0.65, 0, 0.35, 1], duration: 0.2 }}
          >
            {value ? <Check size={16} /> : <Cross size={16} />}
          </SwitchCursor>
        </SwitchButton>
        <input
          style={{ display: 'none' }}
          type="checkbox"
          checked={value}
          onChange={(e) => {
            e.preventDefault();
            handleCheckboxChange();
          }}
        />
      </InputBox>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

export default Switch;
