/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Bin({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C16.5523 2 17 2.44772 17 3V4C17 4.55228 17.4477 5 18 5H22C22.5523 5 23 5.44772 23 6V8C23 8.55228 22.5523 9 22 9C21.4477 9 21 9.44771 21 10L21 20C21 21.1046 20.1046 22 19 22L5 22C3.89543 22 3 21.1046 3 20L3 10C3 9.44772 2.55228 9 2 9C1.44771 9 1 8.55229 1 8V6C1 5.44772 1.44772 5 2 5H6C6.55228 5 7 4.55228 7 4V3C7 2.44772 7.44772 2 8 2H16ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V12ZM16 11C16.5523 11 17 11.4477 17 12V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V12C15 11.4477 15.4477 11 16 11ZM9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12L7 17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17L9 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Bin;
