import { execCallable } from '../utils/callable';
import {
  deleteOverlayFunctionName,
  DeleteOverlayInput,
  DeleteOverlayOutput,
} from '../common/Actions';

const deleteOverlay = (
  input: { workspaceId: string, overlayId: string },
) => execCallable<DeleteOverlayInput, DeleteOverlayOutput>(
  deleteOverlayFunctionName,
  {
    workspaceId: input.workspaceId,
    overlayId: input.overlayId,
  },
);

export default deleteOverlay;
