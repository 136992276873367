import React from 'react';
import styled from 'styled-components';
import useAuthentication from '../../hooks/useAuthentication';
import { Check, MiniLoader } from '../icons';
import {
  Container,
  H1,
  FormContainer,
  P,
} from './style';
import { Skeleton, ButtonLink } from '../atoms';
import AuthenticationError from './AuthenticationError';

// STYLED ------------------------------------------------------------

const DivSkeleton = styled.div`
  ${Skeleton}
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  min-height: 100px;
`;

const Verified = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  svg {
    height: 40px;
    width: 40px;
    color: ${({ theme }) => theme.gray[800]};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.states.success[400]};
    padding: 8px;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    line-height: 140%;
    color: ${({ theme }) => theme.states.success[400]};
  }
`;

// COMPONENTS ------------------------------------------------------------

function VerifyEmailContent({ oobCode }: { oobCode?: string }) {
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>();
  const { verifyEmail } = useAuthentication();

  const handleVerifyEmail = React.useCallback(async () => {
    if (!oobCode) {
      setError('Missing code in URL');
      return;
    }
    setLoading(true);
    const res = await verifyEmail({ oobCode });
    if (res) setError(res);
    else setSuccess(true);
    setLoading(false);
  }, [oobCode, verifyEmail]);

  if (loading) {
    return (
      <DivSkeleton>
        <MiniLoader size={20} />
        <P>Verifying your email...</P>
      </DivSkeleton>
    );
  } if (error) {
    return (
      <>
        <AuthenticationError label={error} />
        <ButtonLink to="/" label="Go to the hub" />
      </>
    );
  } if (!success) {
    return (
      <>
        <h1> Verify email </h1>
        <button onClick={handleVerifyEmail} type="button"> Verify email </button>
      </>
    );
  }
  return (
    <>
      <Verified>
        <Check />
        <p>Email verified!</p>
      </Verified>
      <ButtonLink to="/" label="Go to the hub" />
    </>
  );
}

function VerifyEmail({ oobCode }: { oobCode?: string }) {
  return (
    <Container>
      <H1> Email verification </H1>
      <FormContainer>
        <VerifyEmailContent oobCode={oobCode} />
      </FormContainer>
    </Container>
  );
}

export default VerifyEmail;
