import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { InlineNotification } from './InlineNotification';

// STYLED ------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: calc(100% + 128px);
  position: sticky;
  bottom: -80px;
  margin-bottom: -32px;
  left: 0;
  padding: 64px 16px 32px 16px;
  z-index: 999;
  pointer-events: none;
  overflow: hidden;
  > * {
    pointer-events: all;
  }
`;

const Background = styled(motion.div)`
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
  height: calc(100% + 32px);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${({ theme }) => theme.ui.default[500]} 100%);
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
`;

// TYPE ------------------------------------------------------------

type Props = {
  show?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

// COMPONENTS ------------------------------------------------------------

function BottomNotification({
  show = true,
  style,
  children,
} : Props) {
  return (
    <Container style={style}>
      <AnimatePresence initial={false}>
        {show && (
        <>
          <Background
            initial={{ y: '100%' }}
            animate={{ y: '0' }}
            exit={{ y: '100%' }}
            transition={{ duration: 1, ease: [0.25, 1, 0.5, 1] }}
          />
          {children}
        </>
        )}
      </AnimatePresence>
    </Container>

  );
}

BottomNotification.Container = BottomNotification;
BottomNotification.InlineNotification = InlineNotification;

export default BottomNotification;
