import { execCallable } from '../utils/callable';
import {
  unlinkTwitchFunctionName,
  UnlinkTwitchInput,
  UnlinkTwitchOutput,
} from '../common/Actions';

const unlinkTwitch = (
  input: { workspaceId: string },
) => execCallable<UnlinkTwitchInput, UnlinkTwitchOutput>(
  unlinkTwitchFunctionName,
  {
    workspaceId: input.workspaceId,
  },
);

export default unlinkTwitch;
