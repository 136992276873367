/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Focus({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM14 12C14 10.896 13.104 10 12 10C10.896 10 10 10.896 10 12C10 13.104 10.896 14 12 14C13.104 14 14 13.104 14 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Focus;
