import { execCallable } from '../utils/callable';
import {
  createWorkspaceFunctionName,
  CreateWorkspaceInput,
  CreateWorkspaceOutput,
} from '../common/Actions';

const createWorkspace = (
  input: { name?: string, code: string },
) => execCallable<CreateWorkspaceInput, CreateWorkspaceOutput>(
  createWorkspaceFunctionName,
  {
    name: input.name,
    code: input.code,
    origin: window.location.origin,
  },
);

export default createWorkspace;
