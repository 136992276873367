/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Twitch({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.04167 2L4.5 5.57143V18.4286H8.75V22L12.2917 18.4286H15.125L21.5 12V2H8.04167ZM20.0833 11.2857L17.25 14.1429H14.4167L11.9375 16.6429V14.1429H8.75V3.42857H20.0833V11.2857Z"
        fill="currentColor"
      />
      <path
        d="M17.9583 5.92853H16.5417V10.2142H17.9583V5.92853Z"
        fill="currentColor"
      />
      <path
        d="M14.0625 5.92853H12.6458V10.2142H14.0625V5.92853Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Twitch;
