/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Error({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1906 13.4569C21.694 12.5509 21.694 11.4491 21.1906 10.5431L17.8573 4.54307C17.3281 3.59068 16.3243 3 15.2348 3H8.7652C7.6757 3 6.67184 3.59068 6.14273 4.54307L2.8094 10.5431C2.30603 11.4491 2.30603 12.5509 2.8094 13.4569L6.14273 19.4569C6.67184 20.4093 7.6757 21 8.7652 21H15.2348C16.3243 21 17.3281 20.4093 17.8573 19.4569L21.1906 13.4569ZM8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14H16C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10H8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Error;
