import React, { useEffect, useState } from 'react';
import {
  collection, onSnapshot, query, doc, updateDoc,
} from 'firebase/firestore';
import useAuthentication from '../hooks/useAuthentication';
import { database, serverTimestamp } from '../utils/firebaseConfig';

function Test() {
  // Authentication
  const { user, login, logout } = useAuthentication();
  const [loginForm, setLoginForm] = React.useState({
    email: '',
    password: '',
  });
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const [loginLoading, setLoginLoading] = React.useState(false);

  // Getter
  const [getterData, setGetterData] = useState<any | undefined>(undefined);
  const [getterError, setGetterError] = useState<string | undefined>(undefined);
  const [getterLoading, setGetterLoading] = useState<boolean>(true);
  useEffect(() => {
    const ref = query(collection(database, 'betaKey'));
    // const ref = doc(collection(database, 'workspace'), '1');
    return onSnapshot(ref, (snapshot) => {
      const res = snapshot.docs.map((document) => ({ ...document.data() }));
      // const res = snapshot.data();
      // console.log(res);
      setGetterData(res);
      setGetterError(undefined);
      setGetterLoading(false);
    }, (error) => {
      setGetterError(error.message);
      setGetterLoading(false);
    });
  }, []);

  // Setter
  const [setterError, setSetterError] = useState<string | undefined>(undefined);
  const [setterLoading, setSetterLoading] = useState<boolean>(false);

  const handleButton = async () => {
    setSetterLoading(true);
    setSetterError(undefined);
    try {
      const ref = doc(collection(database, 'betaKey'), 'AAAAAAAAAAAAAAAA');
      const res = await updateDoc(ref, {
        email: 'new',
        'timestamps.updatedAt': serverTimestamp(),
      });
      console.log(res);
    } catch (e: any) {
      console.error(e);
      setSetterError(e.message);
    }
    setSetterLoading(false);
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1> Test page </h1>
      <h2> Authentication </h2>
      {user ? <button onClick={logout} type="button">Logout</button> : null}
      {!user ? (
        <div>
          <input
            type="text"
            value={loginForm.email}
            onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
          />
          <input
            type="password"
            value={loginForm.password}
            onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
          />
          <button
            disabled={loginLoading}
            onClick={async () => {
              setLoginLoading(true);
              setLoginError(undefined);
              try {
                await login(loginForm);
              } catch (e: any) {
                setLoginError(e.message);
              }
              setLoginLoading(false);
            }}
            type="button"
          >
            Login
          </button>
          {loginError && (
          <p>
            Error:
            {loginError}
          </p>
          )}
          {loginLoading && <p>Loading...</p>}
        </div>
      ) : null}

      <h2> Getter </h2>
      {getterLoading && <p>Loading...</p>}
      {getterError && (
      <p>
        Error:
        {getterError}
      </p>
      )}
      {getterData && (
      <p>
        Data:
        {JSON.stringify(getterData)}
      </p>
      )}

      <h2> Setter </h2>
      {setterLoading && <p>Loading...</p>}
      {setterError && (
      <p>
        Error:
        {setterError}
      </p>
      )}
      <button onClick={handleButton} type="button">Set</button>
    </div>
  );
}

export default Test;
