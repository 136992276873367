import React from 'react';
import styled from 'styled-components';
import { InputContainer, InputInfo, Label } from './common';
import { StateColor, UIColor } from '../../theme';

// STYLED ------------------------------------------------------------

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
`;

const RadioCell = styled.button<{ isChecked? : boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  width: 100%;
  background-color: ${({ theme }) => (theme.gray[900])};
  transition: background-color 150ms;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => (theme.gray[800])};
  }
  ${Label} {
    user-select: none;
    pointer-events: none;
    transition: color 150ms;
    color: ${({ theme, isChecked }) => (isChecked ? theme.gray[100] : theme.gray[500])};
  }
`;

const RadioBoxContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioBox = styled.div<{ isChecked?: boolean, color: UIColor | StateColor }>`
  height: 24px;
  width: 24px;
  border-radius: 8px;
  background-color: ${({ theme, color, isChecked }) => (isChecked ? theme.getColor(color, 500) : 'transparent')};
  transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme, color, isChecked }) => (isChecked ? theme.getColor(color, 500) : theme.gray[600])};
`;

const RadioCheck = styled.div<{ isChecked?: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({ theme }) => (theme.gray[100])};
  transition: transform 150ms cubic-bezier(0.65, 0, 0.35, 1);
  transform: ${({ isChecked }) => (`scale(${isChecked ? '1)' : '0)'}`)};
`;

// TYPE ------------------------------------------------------------

export type RadioChoice<T> = {
  label: string,
  value: T,
};

type RadioProps<T> = {
  style?: React.CSSProperties;
  onChange: (value: T) => void;
  value: T;
  disabled?: boolean;
  info?: string;
  choices: RadioChoice<T>[];
  color?: UIColor | StateColor;
  name: string;
};

type RadioInputProps = {
  onChange: () => void;
  value: string;
  disabled?: boolean;
  color?: UIColor | StateColor;
  name: string;
  checked: boolean;
};

// COMPONENTS ------------------------------------------------------------

function RadioInput({
  value,
  disabled = false,
  color = 'default',
  onChange,
  name,
  checked,
}: RadioInputProps) {
  return (
    <RadioCell
      onClick={(e) => {
        e.preventDefault();
        onChange();
      }}
      isChecked={checked}
    >
      <RadioBoxContainer>
        <RadioBox
          color={color}
          isChecked={checked}
          style={{
            height: checked ? '24px' : '16px',
            width: checked ? '24px' : '16px',
            borderRadius: checked ? '8px' : '4px',
          }}
        >
          <RadioCheck isChecked={checked} />
        </RadioBox>
      </RadioBoxContainer>
      <input
        style={{ display: 'none' }}
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => {
          e.preventDefault();
          onChange();
        }}
        disabled={disabled}
      />
      <Label>{value}</Label>
    </RadioCell>
  );
}

function Radio<T>({
  onChange,
  value,
  disabled = false,
  info,
  color = 'default',
  choices,
  style,
  name,
} : RadioProps<T>) {
  return (
    <InputContainer style={style}>
      <RadioContainer>
        {choices.map((choice) => (
          <RadioInput
            key={choice.label}
            name={name}
            value={choice.label}
            checked={value === choice.value}
            onChange={() => onChange(choice.value)}
            disabled={disabled}
            color={color}
          />
        ))}
      </RadioContainer>
      {info ? <InputInfo>{info}</InputInfo> : null}
    </InputContainer>
  );
}

export default Radio;
