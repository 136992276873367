/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Edit({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2627 3.45157C15.8648 2.84948 16.841 2.84948 17.4431 3.45157L19.6235 5.63195C20.2256 6.23404 20.2256 7.21023 19.6235 7.81232L8.35827 19.0775C8.14304 19.2927 7.86892 19.4395 7.57045 19.4991L4.84498 20.0442C3.76618 20.26 2.81504 19.3089 3.0308 18.2301L3.57589 15.5046C3.63559 15.2061 3.7823 14.932 3.99753 14.7168L15.2627 3.45157Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Edit;
