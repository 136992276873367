import React, { useMemo } from 'react';
import { OverlayDisplayProps } from '../Overlay';
import getOverlayUrl from '../../../utils/getOverlayUrl';
import { CopyField } from '../../atoms';

export default function Display({ privateKey }: OverlayDisplayProps) {
  const defaultUrl = useMemo(() => getOverlayUrl(`/counter/default?key=${privateKey}`), [privateKey]);

  return (
    <div>
      Default:
      <CopyField
        value={defaultUrl}
        style={{ maxWidth: 350 }}
        info="*this URL is private, make sure to not share it"
        secure
      />
    </div>
  );
}
