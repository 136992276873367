/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function Check({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.134 4.13927C21.7139 5.31782 22.0393 7.55401 20.8607 9.13395L13.7228 18.7029C13.1538 19.4657 12.3037 19.9697 11.3614 20.1028C10.4192 20.236 9.46272 19.9873 8.70464 19.412L3.34257 15.3431C1.77239 14.1515 1.46543 11.9127 2.65695 10.3426C3.84846 8.77239 6.08726 8.46542 7.65744 9.65694L10.1529 11.5506L15.1393 4.86605C16.3178 3.28611 18.554 2.96072 20.134 4.13927Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export default Check;
