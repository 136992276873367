import Overlay from './Overlay';
import counter from './counter/index';
import customRivenziRugby from './customRivenziRugby/index';
import customRivenziPollEmote from './customRivenziPollEmote/index';

const overlays: Array<Overlay> = [
  counter,
  customRivenziRugby,
  customRivenziPollEmote,
];

export default overlays;
