import {
  collection,
  doc,
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import * as Collections from '../common/Collections';
import { database } from './firebaseConfig';

const converter = <T>(): FirestoreDataConverter<T> => ({
  toFirestore: (data) => data as DocumentData,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    const data = snap.data();
    if (data.timestamps?.updatedAt) data.timestamps.updatedAt = data.timestamps.updatedAt.toDate();
    if (data.timestamps?.createdAt) data.timestamps.createdAt = data.timestamps.createdAt.toDate();
    return data as T;
  },
});
const dataPoint = <T>(path: string) => collection(database, path).withConverter(converter<T>());

const users = dataPoint<Collections.UserDocument>(Collections.UserName);
const user = (userId: string) => doc(users, userId);

const betaKeys = dataPoint<Collections.BetaKeyDocument>(Collections.BetaKeyName);
const betaKey = (betaKeyId: string) => doc(betaKeys, betaKeyId);

const workspaces = dataPoint<Collections.WorkspaceDocument>(Collections.WorkspaceName);
const workspace = (workspaceId: string) => doc(workspaces, workspaceId);

const overlays = (workspaceId: string) => dataPoint<Collections.OverlayDocument>(
  `${workspace(workspaceId).path}/${Collections.OverlayName}`,
);

const overlay = (workspaceId: string, overlayId: string) => doc(overlays(workspaceId), overlayId);
const overlayConfig = (workspaceId: string, overlayId: string) => doc(dataPoint<Collections.OverlayConfigDocument>(
  `${overlay(workspaceId, overlayId).path}/${Collections.OverlayConfigName}`,
), overlayId);
const overlayPrivateKey = (workspaceId: string, overlayId: string) => doc(dataPoint<Collections.OverlayPrivateKeyDocument>(
  `${overlay(workspaceId, overlayId).path}/${Collections.OverlayPrivateKeyName}`,
), overlayId);

const overlayData = <
  TConfig extends Record<string, any>,
  TData extends Record<string, any>,
  >(privateKey: string) => doc(dataPoint<Collections.OverlayDataDocument<TConfig, TData>>(
    Collections.OverlayDataName,
  ), privateKey);

export default {
  users,
  user,
  betaKeys,
  betaKey,
  workspaces,
  workspace,
  overlays,
  overlay,
  overlayConfig,
  overlayPrivateKey,
  overlayData,
};
