/* eslint-disable max-len */
import React from 'react';

// TYPE ------------------------------------------------------------

type IconProps = {
  size?: number | string;
  style?: React.CSSProperties;
};

// COMPONENTS ------------------------------------------------------------

function EmptyBox({ size = 24, style } : IconProps) {
  return (
    <svg width={size} height={size} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 21.7778C19.1046 21.7778 20 20.8823 20 19.7778V13C20 12.4477 19.5523 12 19 12H5C4.44772 12 4 12.4477 4 13V19.7778C4 20.8823 4.89543 21.7778 6 21.7778L18 21.7778ZM10.5556 13.6296C9.88054 13.6296 9.33334 14.1768 9.33334 14.8518C9.33334 15.5269 9.88055 16.0741 10.5556 16.0741H13.4444C14.1195 16.0741 14.6667 15.5269 14.6667 14.8518C14.6667 14.1768 14.1195 13.6296 13.4444 13.6296H10.5556Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C13.1046 2 14 2.89543 14 4V6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6V4C10 2.89543 10.8954 2 12 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6569 5.41421C21.4379 6.19526 21.4379 7.46159 20.6569 8.24264L19.2426 9.65685C18.4616 10.4379 17.1953 10.4379 16.4142 9.65685C15.6332 8.87581 15.6332 7.60948 16.4142 6.82843L17.8284 5.41421C18.6095 4.63317 19.8758 4.63317 20.6569 5.41421Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41422 5.41422C4.19527 4.63317 5.4616 4.63317 6.24264 5.41422L7.65686 6.82843C8.43791 7.60948 8.43791 8.87581 7.65686 9.65686C6.87581 10.4379 5.60948 10.4379 4.82843 9.65686L3.41422 8.24264C2.63317 7.4616 2.63317 6.19527 3.41422 5.41422Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EmptyBox;
