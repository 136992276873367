import tinycolor from 'tinycolor2';

function cubicEaseInOut(t: number): number {
  return t < 0.5 ? 4 * t * t * t : 1 - (-2 * t + 2) ** 3 / 2;
}

function saturateEasing(t: number, min: number, max: number): number {
  if (t >= min) {
    return Math.min(1, cubicEaseInOut((t - min) / (max - min)));
  }
  return 0;
}

function linearInterpolation(value: number, min: number, max: number): number {
  return min * (1 - value) + max * value;
}

function mapRange(value : number, low1 : number, high1 : number, low2 : number, high2 : number) {
  return low2 + (high2 - low2) * ((value - low1) / (high1 - low1));
}

function normalizeHue(hue: number, min: number, max : number) {
  if (hue >= min && hue <= max) {
    const value = mapRange(hue, min, max, 0, 1);
    return cubicEaseInOut(value);
  } if (hue > max) {
    const value = mapRange(hue, max, 360 + min, 0, 1);
    return cubicEaseInOut(value);
  }
  const value = mapRange(hue, -360 + max, min, 0, 1);
  return cubicEaseInOut(value);
}

export function darkenColor(hexColor: string): string {
  const color = tinycolor(hexColor);
  const hsl = color.toHsl();

  const delta = normalizeHue(hsl.h, 45, 240);
  // Adjust the saturation and lightness based a curve
  const saturationToSub = linearInterpolation(delta, 0.02, 0.25);
  let lightnessToSub = linearInterpolation(1 - delta, 0.1, 0.25);

  if (hsl.l - lightnessToSub < 0.05) {
    lightnessToSub = -0.15;
  }

  hsl.s -= saturationToSub * saturateEasing(hsl.s, 0.25, 0.6);
  hsl.l -= lightnessToSub;

  hsl.l = Math.max(0, Math.min(1, hsl.l));
  hsl.h = (hsl.h + 3) % 360;

  const newColor = tinycolor(hsl);
  return newColor.toHexString();
}

export function contrastColor(hexColor: string): string {
  const color = tinycolor(hexColor);
  const hsl = color.toHsl();

  const delta = normalizeHue(hsl.h, 140, 360);
  const lightnessToAdd = linearInterpolation(delta, 0.4, 0.7);
  hsl.l += lightnessToAdd;
  hsl.s -= 0.02;

  hsl.s = Math.max(0, Math.min(1, hsl.s));
  hsl.l = Math.max(0, Math.min(1, hsl.l));
  hsl.h %= 360;

  const contrastRatio = tinycolor.readability(tinycolor(hsl).toHexString(), color);

  if (contrastRatio < 2) {
    const hslAlt = color.toHsl();
    const saturationToAdd = linearInterpolation(delta, 0.02, 0.25);
    const lightnessToSub = linearInterpolation(delta, 0.2, 0.3);
    hsl.s = hslAlt.s - saturationToAdd * saturateEasing(hslAlt.s, 0.25, 0.6);
    hsl.l = hslAlt.l - lightnessToSub;
    hsl.s = Math.max(0, Math.min(1, hsl.s));
    hsl.l = Math.max(0, Math.min(1, hsl.l));
  }

  const newColor = tinycolor(hsl);
  return newColor.toHexString();
}
