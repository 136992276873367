import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import { AuthenticationContextProvider } from './context/AuthenticationContext';
import Router from './router/Router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <AuthenticationContextProvider>
      <Router />
    </AuthenticationContextProvider>
  </ThemeProvider>,
);
