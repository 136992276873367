import { execCallable } from '../utils/callable';
import {
  updateWorkspaceNameFunctionName,
  UpdateWorkspaceNameInput,
  UpdateWorkspaceNameOutput,
} from '../common/Actions';

const updateWorkspaceName = (
  input: { workspaceId: string, name: string },
) => execCallable<UpdateWorkspaceNameInput, UpdateWorkspaceNameOutput>(
  updateWorkspaceNameFunctionName,
  {
    workspaceId: input.workspaceId,
    name: input.name,
  },
);

export default updateWorkspaceName;
