import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { deleteOverlay } from '../actions';
import { WorkspaceOutletContext } from '../types/OutletTypes';
import { Error as ErrorIcon } from '../components/icons';
import { UIColor } from '../theme';
import {
  HStack, P, Separator, Subtitle, VStack, FocusSection,
  Button, PageColorPicker, Loader, Modal,
} from '../components/atoms';
import useOverlay from '../hooks/useOverlay';
import * as Collections from '../common/Collections';
import updateOverlay from '../actions/updateOverlay';
import resetOverlayPrivateKey from '../actions/resetOverlayPrivateKey';
import overlays from '../components/overlays';
import ErrorBoundary from '../components/common/ErrorBoundary';

// STYLED ------------------------------------------------------------

const ErrorContainer = styled.div`
  border-color: ${({ theme }) => theme.states.error[500]};
  color: ${({ theme }) => theme.states.error[400]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  p {
    line-height: 140%;
    text-align: center;
  }
  svg {
    color: ${({ theme }) => theme.states.error[500]};
  }
`;

const PageName = styled.input<{ color : UIColor }>`
  width: 100%;
  font-size: 32px;
  background-color: transparent;
  color: ${({ theme, color }) => theme.getUIColor(color, 500)};
  font-weight: 700;
  &::placeholder {
    color: ${({ theme, color }) => theme.getUIColor(color, 900)};
  }
`;

const BaseContainer = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  align-items: center;
`;

// COMPONENTS ------------------------------------------------------------

function OverlayHeader({ overlay }: { overlay: Collections.OverlayDocument }) {
  const [color, setColor] = React.useState<Exclude<UIColor, 'default'>>(overlay.color);
  const [name, setName] = React.useState<string>(overlay.name);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setColor(overlay.color);
    setName(overlay.name);
  };
  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (color === overlay.color && name === overlay.name) return;
    setLoading(true);
    const res = await updateOverlay({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
      name,
      color,
    });
    if (!res.ok) {
      await handleCancel();
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <BaseContainer>
        <PageColorPicker value={color} onChange={setColor} compact />
        <PageName
          value={name}
          onChange={(e) => setName(e.target.value)}
          color={color}
          placeholder="Overlay name"
          type="text"
          disabled={loading}
        />
        {
          color !== overlay.color || name !== overlay.name ? (
            <>
              <Button label="Save" type="submit" loading={loading} />
              <Button label="Cancel" type="button" onClick={handleCancel} variant="tonal" disabled={loading} />
            </>
          ) : null
        }
      </BaseContainer>
    </form>
  );
}

function OverlayFooter({ overlay }: { overlay: Collections.OverlayDocument }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState<boolean>(false);
  const [isModalResetOpen, setIsModalResetOpen] = React.useState<boolean>(false);

  const handleDeleteOverlay = async () => {
    setLoading(true);
    const res = await deleteOverlay({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
    });
    if (res.ok) {
      navigate(`/workspace/${overlay.workspaceId}`);
    } else {
      console.log(res.error);
    }
    setIsModalDeleteOpen(false);
    setLoading(false);
  };

  const handleResetPrivateKey = async () => {
    setLoading(true);
    const res = await resetOverlayPrivateKey({
      workspaceId: overlay.workspaceId,
      overlayId: overlay.overlayId,
    });
    if (!res.ok) {
      console.log(res.error);
    }
    setIsModalDeleteOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Modal.Container
        style={{ padding: '8px' }}
        visible={isModalDeleteOpen}
        onClose={() => (!loading ? setIsModalDeleteOpen(false) : null)}
      >
        <HStack style={{ padding: '16px' }}>
          <Subtitle>Say goodbye to your overlay</Subtitle>
          {/* eslint-disable-next-line max-len */}
          <P>{'Are you sure you want to delete this overlay? It can\'t be undone and you\'ll have to recreate it from scratch.'}</P>
        </HStack>
        <VStack>
          <Button
            onClick={() => setIsModalDeleteOpen(false)}
            label="Cancel"
            color="default"
            variant="tonal"
            disabled={loading}
            style={{ flex: 1 }}
          />
          <Button
            onClick={handleDeleteOverlay}
            label="Delete"
            color="error"
            loading={loading}
            style={{ flex: 1 }}
          />
        </VStack>
      </Modal.Container>
      <Modal.Container
        style={{ padding: '8px' }}
        visible={isModalResetOpen}
        onClose={() => (!loading ? setIsModalResetOpen(false) : null)}
      >
        <HStack style={{ padding: '16px' }}>
          <Subtitle>Reset your browser source URL</Subtitle>
          {/* eslint-disable-next-line max-len */}
          <P>{'Please be aware that your current layout will no longer be visible until the new URL is implemented. You\'ll generate a new URL that you\'ll need to update in your streaming software settings.'}</P>
        </HStack>
        <VStack>
          <Button
            onClick={() => setIsModalResetOpen(false)}
            label="Cancel"
            color="default"
            variant="tonal"
            disabled={loading}
            style={{ flex: 1 }}
          />
          <Button
            onClick={handleResetPrivateKey}
            label="Reset URL"
            color="error"
            loading={loading}
            style={{ flex: 1 }}
          />
        </VStack>
      </Modal.Container>
      <HStack>
        <P>Resetting your browser source URL will break the current browser source in your streaming software.</P>
        <Button
          onClick={() => setIsModalResetOpen(true)}
          type="button"
          label="Reset URL"
          color="error"
          variant="tonal"
        />
      </HStack>
      <HStack>
        <P>Deleting a overlay is final, you will have to recreate it to use it again. </P>
        <Button
          onClick={() => setIsModalDeleteOpen(true)}
          type="button"
          label="Delete this overlay"
          color="error"
          variant="tonal"
        />
      </HStack>
    </>
  );
}

function OverlayConfig({ overlay, config }: { overlay: Collections.OverlayDocument, config?: Record<string, any> }) {
  const overlayDisplay = useMemo(() => overlays.find((o) => o.type === overlay.type), [overlay.type]);

  if (!overlayDisplay) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>Oups, an error occured.</p>
        <p>Overlay type not found.</p>
        <p>Try again later.</p>
      </ErrorContainer>
    );
  }

  if (!config) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>You are not allowed to access config.</p>
      </ErrorContainer>
    );
  }

  return overlayDisplay.renderConfig({ overlay, config });
}

function OverlayAction({ overlay, privateKey }: { overlay: Collections.OverlayDocument, privateKey?: string }) {
  const overlayDisplay = useMemo(() => overlays.find((o) => o.type === overlay.type), [overlay.type]);

  if (!overlayDisplay) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>Oups, an error occured.</p>
        <p>Overlay type not found.</p>
        <p>Try again later.</p>
      </ErrorContainer>
    );
  }

  if (!privateKey) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>You are not allowed to access actions.</p>
      </ErrorContainer>
    );
  }

  return overlayDisplay.renderAction({ overlay, privateKey });
}

function OverlayDisplay({ overlay, privateKey }: { overlay: Collections.OverlayDocument, privateKey?: string }) {
  const overlayDisplay = useMemo(() => overlays.find((o) => o.type === overlay.type), [overlay.type]);

  if (!overlayDisplay) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>Oups, an error occured.</p>
        <p>Overlay type not found.</p>
        <p>Try again later.</p>
      </ErrorContainer>
    );
  }

  if (!privateKey) {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>You are not allowed to access display.</p>
      </ErrorContainer>
    );
  }

  return overlayDisplay.renderDisplay({ overlay, privateKey });
}

function Overlay() {
  const { workspaceId, overlayId } = useOutletContext<WorkspaceOutletContext>();
  const overlayInfo = useOverlay(workspaceId, overlayId || '');

  if (overlayInfo.state === 'loading' || !overlayId) {
    return (
      <Loader> Loading Overlay </Loader>
    );
  }

  if (overlayInfo.state === 'error') {
    return (
      <ErrorContainer>
        <ErrorIcon size={64} />
        <p>Oups, an error occured.</p>
        <p>{overlayInfo.error}</p>
        <p>Try again later.</p>
      </ErrorContainer>
    );
  }

  const { overlay, config, privateKey } = overlayInfo;

  return (
    <>
      <OverlayHeader overlay={overlay} />
      <Separator />
      <Subtitle>Setup</Subtitle>
      <HStack>
        <ErrorBoundary>
          <OverlayDisplay overlay={overlay} privateKey={privateKey} />
        </ErrorBoundary>
      </HStack>
      <FocusSection>
        <Subtitle>Config</Subtitle>
        <ErrorBoundary>
          <OverlayConfig overlay={overlay} config={config} />
        </ErrorBoundary>
      </FocusSection>
      <Subtitle>Actions</Subtitle>
      <HStack>
        <ErrorBoundary>
          <OverlayAction overlay={overlay} privateKey={privateKey} />
        </ErrorBoundary>
      </HStack>
      <Separator />
      <OverlayFooter overlay={overlay} />
    </>
  );
}

export default Overlay;
