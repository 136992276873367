import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { MiniLoader } from '../icons';
import { StateColor, UIColor } from '../../theme';

// STYLED ------------------------------------------------------------

export const ButtonBase = css<{ disabled : boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  border: 0px;
  outline: none;
  text-transform: uppercase;
  box-sizing: border-box;
  user-select: none;
  border-radius: 16px;
  padding: 16px;
  height: 44px;
  gap: 8px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:focus,
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  font-family: 'Public Sans', sans-serif;
  line-height: 100%;
  font-size: 15px;
  font-weight: 700;
`;

const Filled = css<{ color: UIColor | StateColor, disabled : boolean }>`
  transition: all 150ms;
  color: ${({ theme, disabled }) => (theme.gray[disabled ? 500 : 100])};
  background-color: ${({ theme, color, disabled }) => theme.getColor(color, disabled ? 900 : 500)};
  &:focus,
  &:hover {
    background-color: ${({ theme, color }) => theme.getColor(color, 700)};
  }
`;

const Tonal = css<{ color: UIColor | StateColor, disabled : boolean }>`
  transition: all 150ms;
  color: ${({ theme, color, disabled }) => (disabled ? theme.gray[500] : theme.getColor(color, 500))};
  background-color: ${({ theme }) => `${theme.gray[900]}80`};
  &:focus,
  &:hover {
    background-color: ${({ theme, color }) => theme.getColor(color, 900)};
    color: ${({ theme, color }) => theme.getColor(color, 400)};
  }
`;

const Text = css<{ color: UIColor | StateColor, disabled : boolean }>`
  width: fit-content;
  color: ${({ theme, color, disabled }) => (disabled ? theme.gray[500] : theme.getColor(color, 500))};
  background-color: transparent;
  transition: all 150ms;
  &:focus,
  &:hover {
    background-color: ${({ theme, color }) => theme.getColor(color, 900)};
    color: ${({ theme, color }) => theme.getColor(color, 400)};
  }
`;

const ButtonFilled = styled.button<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Filled}
`;

const ButtonTonal = styled.button<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Tonal}
`;

const ButtonText = styled.button<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Text}
`;

const LinkFilled = styled(Link)<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Filled}
  text-decoration: none;
`;

const LinkTonal = styled(Link)<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Tonal}
  text-decoration: none;
`;

const LinkText = styled(Link)<{ color: UIColor | StateColor, disabled : boolean }>`
  ${ButtonBase}
  ${Text}
  text-decoration: none;
`;

// TYPE ------------------------------------------------------------

type ButtonProps = {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'filled' | 'tonal' | 'text';
  loading?: boolean;
  color ?: UIColor | StateColor;
  style ?: React.CSSProperties;
  icon ?: React.ReactNode;
  className ?: string;
};

type ButtonLinkProps = {
  label?: string;
  to: string;
  disabled?: boolean;
  variant?: 'filled' | 'tonal' | 'text';
  color ?: UIColor | StateColor;
  style ?: React.CSSProperties;
  icon ?: React.ReactNode;
  className ?: string;
};

// COMPONENTS ------------------------------------------------------------

export function ButtonLink({
  label,
  to,
  disabled = false,
  variant = 'filled',
  color = 'default',
  style,
  icon,
  className,
} : ButtonLinkProps) {
  switch (variant) {
    case 'filled':
      return (
        <LinkFilled
          className={className}
          to={to}
          disabled={disabled}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p>{label}</p>}
        </LinkFilled>
      );
    case 'tonal':
      return (
        <LinkTonal
          className={className}
          to={to}
          disabled={disabled}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p>{label}</p>}
        </LinkTonal>
      );
    case 'text':
      return (
        <LinkText
          className={className}
          to={to}
          disabled={disabled}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p>{label}</p>}
        </LinkText>
      );
    default:
      return null;
  }
}

export default function Button({
  label,
  onClick = () => {},
  disabled = false,
  variant = 'filled',
  type = 'button',
  loading = false,
  color = 'default',
  style,
  icon,
  className,
} : ButtonProps) {
  switch (variant) {
    case 'filled':
      return (
        <ButtonFilled
          className={className}
          onClick={onClick}
          disabled={disabled || loading}
          type={type}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p style={{ opacity: loading ? '0' : '1' }}>{label}</p>}
          {loading && <MiniLoader size={20} style={{ position: 'absolute' }} />}
        </ButtonFilled>
      );
    case 'tonal':
      return (
        <ButtonTonal
          className={className}
          onClick={onClick}
          disabled={disabled || loading}
          type={type}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p style={{ opacity: loading ? '0' : '1' }}>{label}</p>}
          {loading && <MiniLoader size={20} style={{ position: 'absolute' }} />}
        </ButtonTonal>
      );
    case 'text':
      return (
        <ButtonText
          className={className}
          onClick={onClick}
          disabled={disabled || loading}
          type={type}
          color={color}
          style={label ? style : {
            width: '44px',
            padding: 0,
            ...style,
          }}
        >
          {icon}
          {label && <p>{label}</p>}
        </ButtonText>
      );
    default:
      return null;
  }
}
