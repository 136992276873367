/* eslint-disable max-len */
import { DefaultTheme } from 'styled-components';
import { StateColor, UIColor } from '.';

const theme: DefaultTheme = {
  getUIColor: (color: UIColor, shade: number) => theme.ui[color as keyof typeof theme.ui][shade as keyof typeof theme.ui.default],
  getStateColor: (state: StateColor, shade: number) => theme.states[state as keyof typeof theme.states][shade as keyof typeof theme.states.error],
  getColor: (color: UIColor | StateColor, shade: number) => {
    if ((color as UIColor) in theme.ui) {
      return theme.getUIColor(color as UIColor, shade);
    }
    return theme.getStateColor(color as StateColor, shade);
  },

  gray: {
    900: '#0B0B0C',
    800: '#181818',
    700: '#282828',
    600: '#444444',
    500: '#696969',
    400: '#9D9D9E',
    300: '#B5B5B6',
    200: '#CECECE',
    100: '#FFFFFF',
  },
  ui: {
    default: {
      400: '#346AFF',
      500: '#1654FF',
      700: '#21419C',
      900: '#1E2639',
    },
    red: {
      400: '#E57373',
      500: '#EF5350',
      700: '#B82D2D',
      900: '#3C1E1E',
    },
    pink: {
      400: '#F06292',
      500: '#EC407A',
      700: '#C62828',
      900: '#3E1F29',
    },
    purple: {
      400: '#BA68C8',
      500: '#AB47BC',
      700: '#6A1B9A',
      900: '#331D37',
    },
    lavender: {
      400: '#9575CD',
      500: '#7E57C2',
      700: '#4527A0',
      900: '#251E39',
    },
    blue: {
      400: '#448AFF',
      500: '#2979FF',
      700: '#1565C0',
      900: '#192231',
    },
    sky: {
      400: '#4FC3F7',
      500: '#29B6F6',
      700: '#0277BD',
      900: '#192931',
    },
    cyan: {
      400: '#4DD0E1',
      500: '#00BCD4',
      700: '#00838F',
      900: '#192D30',
    },
    green: {
      400: '#66BB6A',
      500: '#4CAF50',
      700: '#2E7D32',
      900: '#162D17',
    },
    lemon: {
      400: '#D4E157',
      500: '#B3C128',
      700: '#9E9D24',
      900: '#2B2D17',
    },
    yellow: {
      400: '#FFD54F',
      500: '#FFCA28',
      700: '#F9A825',
      900: '#282314',
    },
    orange: {
      400: '#FFB74D',
      500: '#FFA726',
      700: '#EF6C00',
      900: '#2D2417',
    },
    spice: {
      400: '#FF8A65',
      500: '#FF7043',
      700: '#D84315',
      900: '#2B1B16',
    },
  },
  states: {
    warning: {
      400: '#F2913C',
      500: '#F57F17',
      700: '#BB671C',
      900: '#2C2015',
    },
    error: {
      400: '#EE5454',
      500: '#EA2323',
      700: '#C02020',
      900: '#251313',
    },
    success: {
      400: '#32BD50',
      500: '#0FA430',
      700: '#228237',
      900: '#0D1C10',
    },
  },
};

export default theme;
